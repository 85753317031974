import emailValidator from 'email-validator';
import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Card, { CardBody, CardHeading, CardTitle } from '../../components/Card';
import Form from '../../components/Form';
import { InputField } from '../../components/InputField';
import Page from '../../components/Page';
import Api from '../../utils/Api';
import User from '../../utils/User';
import './styles.css';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [ username, setUsername ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
  const [ submitted, setSubmitted ] = useState(false);

  useEffect(() => {
    if (User.isAuthenticated()) {
      navigate('/');
    }
  }, [ navigate ]);

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!emailValidator.validate(username)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }

    Api.post('/admin-users/password-resets', { username }, () => {
      navigate('/login', { state: { redirectCause: 'forgotPassword', username } });
    });

    setSubmitted(true);
  };

  const onCancelButtonPress = () => {
    navigate('/login');
  };

  const onUsernameFieldChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
    setUsername(value);
    setErrorMessage(errorMessage);
  };

  return (
    <Page className='ForgotPasswordPage' title='Password reset' noHeading>
      <div className='ForgotPasswordPage_box'>
        <Card>
          <Form onSubmit={onFormSubmit}>
            <CardHeading>
              <CardTitle>You are about to reset your password</CardTitle>
            </CardHeading>

            <CardBody>
              <div>
                This will send a link to your email with further instructions on how to reset your password.
              </div>
              <br />
              <div>
                Please enter your email address, that you use to sign in to Rebel Licenses.
              </div>
              <br />
              <InputField
                type='text'
                name='username'
                label='Email'
                errorMessage={errorMessage}
                disabled={submitted}
                onChange={onUsernameFieldChange}
                autoFocus
              />
              <Button type='default' onClick={onCancelButtonPress}>Cancel</Button>
              <Button type='primary' submit className='pull-right' disabled={submitted}>Send reset email</Button>
            </CardBody>
          </Form>
        </Card>
      </div>
    </Page>);
};

export default ForgotPasswordPage;
