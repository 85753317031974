import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import User from '../../utils/User';

const SupportLoginPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => User.authenticateWithSupportLoginToken(token, () => navigate('/')), [ navigate, token ]);

  return (
    <Page className='SupportLoginPage' title='Sign in on behalf of customer' noHeading>
      <h1>Please wait...</h1>
    </Page>
  );
};
export default SupportLoginPage;
