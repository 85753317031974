import { useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';

export default function UnblockModal({ user, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onOkButtonPress() {
    setSaving(true);
    Api.put('/seat-users/blocked', { email: user.email, blocked: false }, () => {
      onSuccess(user.email);
    });
  }

  return (
    <Modal
      title='Unblock user'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onOkButtonPress}>
            Unblock
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <p>You are about to unblock <strong>{user.email}</strong></p>
      <p>Are you sure?</p>
    </Modal>
  );
}
