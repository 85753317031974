import { useEffect } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { UserCategory } from '../labels';

export default function SeatUsersTeamRedirect() {

  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();

  const modal = searchParams.get('modal');
  const origin = searchParams.get('origin');
  const teamName = searchParams.get('teamName');

  useEffect(() => {
    if (modal === 'inviteForm' && origin === 'EMAIL') {
      const search = createSearchParams({ teamName, modal: 'add', origin }).toString();
      return navigate({ pathname: '/users', search }, { state: { category: UserCategory.TEAM } });
    }
    else {
      return navigate('/users');
    }
  }, [ modal, navigate, origin, teamName ]);

  return <></>;
}
