import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Card from '../../../components/Card';
import InfoTooltip from '../../../components/InfoTooltip';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import Table, { TableCell, TableRow } from '../../../components/Table';
import ValidationTooltip from '../../../components/ValidationTooltip';
import Api from '../../../utils/Api';
import EventEmitter from '../../../utils/EventEmitter';
import { getRedeployTimeValidationMessage } from '../selectors';
import './styles.css';

const TeamRedeployTimeModalSavedEventId = 'TeamRedeployTimeModal.saved';

export default function TeamRedeployTimeModal({ onSave, onCancel, origin }) {

  const inputElements = {};
  const [ teams, setTeams ] = useState([]);
  const [ seatUsers, setSeatUsers ] = useState([]);
  const [ saving, setSaving ] = useState(false);
  const [ fetchingTeams, setFetchingTeams ] = useState(true);
  const [ fetchingSeatUsers, setFetchingSeatUsers ] = useState(true);

  useEffect(() => {
    Api.get('/teams?with_products=true', ({ list }) => {
      setTeams(list);
      setFetchingTeams(false);
    });

    Api.get('/seat-users', ({ list }) => {
      setSeatUsers(list);
      setFetchingSeatUsers(false);
    });

    Api.post('/teams/events/open/team-redeploy-time-modal', { origin });
  }, [ origin ]);

  function getActiveUsersWithJRebelInTeam(users, teamName) {
    return users
      .filter(({ status }) => status === 'ACTIVE')
      .filter(({ seatReservations }) => seatReservations.some(seatReservation => seatReservation.productType === 'JREBEL' && seatReservation.teamName === teamName));
  }

  function getUsersWithCustomRedeployTime(users, teamName) {
    return getActiveUsersWithJRebelInTeam(users, teamName)
      .filter(({ redeployTimeType }) => redeployTimeType === 'CONFIGURED');
  }

  function getAverageCustomRedeployTime(users, teamName) {
    const times = getUsersWithCustomRedeployTime(users, teamName).map(({ redeployTimeSeconds }) => redeployTimeSeconds);
    if (times.length > 5) {
      return times
        .sort((a, b) => a - b)
        .reduce((totalTime, time, i, array) => totalTime + (i > 0 && i + 1 < array.length ? time : 0), 0) / (times.length - 2);
    }
    return times.reduce((totalTime, time) => totalTime + time, 0) / times.length;
  }

  function onTimeChange({ currentTarget }) {
    const redeployTimeSeconds = currentTarget.value;
    const changedToken = currentTarget.getAttribute('data-token');
    setTeams(prevTeams => {
      const team = prevTeams.find(({ token }) => token === changedToken);
      team.changed = true;
      team.redeployTimeSeconds = redeployTimeSeconds;
      return [ ...prevTeams ];
    });
  }

  function onFormSubmit(e) {
    e.preventDefault();
    setSaving(true);

    const list = teams
      .filter(({ changed }) => changed)
      .map(team => ({ token: team.token, redeployTimeSeconds: team.redeployTimeSeconds }));

    if (list.length > 0) {
      Api.put('/teams/redeploy-time-seconds', { origin, list }, () => {
        EventEmitter.emit(TeamRedeployTimeModalSavedEventId);
        onSave();
      });
    }
    else {
      onCancel();
    }
  }

  function onInputRef(element) {
    if (element) {
      inputElements[element.getAttribute('data-token')] = element;
    }
  }

  return (
    <div className='TeamRedeployTimeModal'>
      <Modal
        title='Change default redeploy time for teams'
        className='TeamRedeployTimeModal_modal'
        buttons={<div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
          <ModalButton
            type='primary'
            disabled={saving || !teams.every(({ redeployTimeSeconds }) => !getRedeployTimeValidationMessage(redeployTimeSeconds))}
            submit
          >
            Save changes
          </ModalButton>
        </div>}
        onSubmit={onFormSubmit}
        onClose={onCancel}
      >
        <div className='TeamRedeployTimeModal_modal_description'>
          Here you can override the default redeploy time that is used to calculate time savings. Redeploy times configured by individual developers will not be overridden.
        </div>
        <Card className='TeamRedeployTimeModal_table-panel'>
          <Table
            className='TeamRedeployTimeModal_table'
            header={
              <TableRow>
                <th className='TeamRedeployTimeModal_table_team-name'>Team</th>
                <th className='TeamRedeployTimeModal_table_team-lead'>Team lead</th>
                <th className='TeamRedeployTimeModal_table_users'>
                  Affected users
                  <InfoTooltip size='l' text='Users whose JRebel redeploy time is currently left at the default value.' />
                </th>
                <th className='TeamRedeployTimeModal_table_redeploy-time'>
                  Avg. redeploy time
                  <InfoTooltip size='l' text='The average redeploy time of users who configured JRebel redeploy time (not using default).' />
                </th>
                <th className='TeamRedeployTimeModal_table_redeploy-time'>Default redeploy time</th>
              </TableRow>
            }
            body={teams.length === 0 ?

              <TableRow className='TeamRedeployTimeModal_no-results'>
                <TableCell colSpan='10'>
                  {fetchingTeams && fetchingSeatUsers ? 'Fetching data...' : 'No teams'}
                </TableCell>
              </TableRow> :

              _(teams)
                .sortBy('name')
                .value()
                .filter(({ products }) => products.some(({ productType, enabled }) => enabled && productType === 'JREBEL'))
                .map(({ token, name, teamLead, redeployTimeSeconds, products }) => {
                  return (
                    <TableRow key={token}>
                      <TableCell className='TeamRedeployTimeModal_table_team-name'>{name}</TableCell>
                      <TableCell className='TeamRedeployTimeModal_table_team-lead'>{teamLead}</TableCell>
                      <TableCell>
                        <span>
                          {getActiveUsersWithJRebelInTeam(seatUsers, name).filter(({ redeployTimeType }) => redeployTimeType !== 'CONFIGURED').length}
                        </span>
                        <span> / </span>
                        <span>
                          {products.find(({ productType }) => productType === 'JREBEL').usage}
                        </span>
                      </TableCell>
                      <TableCell>
                        {getUsersWithCustomRedeployTime(seatUsers, name).length > 0 ?

                          getAverageCustomRedeployTime(seatUsers, name).toFixed(0) :

                          ''}
                      </TableCell>
                      <TableCell className='TeamRedeployTimeModal_table_redeploy-time'>
                        <InputField
                          type='text'
                          name='redeployTime'
                          idKey={name}
                          value={redeployTimeSeconds || ''}
                          data-token={token}
                          ref={onInputRef}
                          hasError={getRedeployTimeValidationMessage(redeployTimeSeconds)}
                          onChange={onTimeChange}
                        />
                        seconds
                        <ValidationTooltip target={inputElements[token]} text={getRedeployTimeValidationMessage(redeployTimeSeconds)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
          />
        </Card>
      </Modal>
    </div>
  );
}
