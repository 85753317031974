import React, { Fragment, useState } from 'react';
import Button from '../../../components/Button';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import { productTypes } from '../../../enums/ProductType';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { hasJRebelEnabled } from '../selectors';
import DeleteModal from './DeleteModal';
import ProductsPanel from './ProductsPanel';
import { hasRedeployTimeChanged, hasTeamLeadChanged, isEdit, isFormValid, isFormValidForTeamLead, isTeamLeadEmailValid, toFormState, toRequestParams } from './selectors';
import './styles.css';
import SuccessModal from './SuccessModal';
import TeamLeadAddedModal from './TeamLeadAddedModal';

export default function TeamForm({ editedTeam, onSave, onCancel }) {

  const [ adminUsers, setAdminUsers ] = useState([]);
  const [ activeModal, setActiveModal ] = useState('form');
  const [ saving, setSaving ] = useState(false);
  const originalForm = editedTeam ? toFormState(editedTeam) : null;
  const [ form, setForm ] = useState(editedTeam ? toFormState(editedTeam) : {
    token: undefined,
    name: {
      value: '',
      errorMessage: null
    },
    teamLead: {
      value: '',
      disabled: false,
      errorMessage: null
    },
    products: productTypes.map(id => ({
      productType: id,
      enabled: true,
      limit: '',
      usage: 0,
      lastTouched: false
    })),
    redeployTimeSeconds: ''
  });

  function onFormSubmit(e) {
    e.preventDefault();

    if (!isTeamLeadEmailValid(form)) {
      setTeamLeadErrorMessage('Please enter a valid email address.');
      return;
    }

    setSaving(true);

    if (isEdit(form)) {
      saveEdit();
    }
    else {
      saveCreate();
    }
  }

  function saveCreate() {
    Api.post('/teams', toRequestParams(form), ({ error, token }) => {
      if (error === 'TEAM_LEAD_EXISTS_IN_ANOTHER_ACCOUNT') {
        setTeamLeadErrorMessage('Email address already in use, try another one.');
      }
      else if (error === 'TEAM_NAME_EXISTS') {
        setTeamNameErrorMessage('A team with such a name already exists.');
      }
      else if (error === 'TEAM_LEAD_IS_VIEW_ONLY_ADMIN') {
        setTeamLeadErrorMessage('View only administrators can not be team lead. Please change the role of the administrator or choose someone else.');
      }
      else if (error === 'TEAM_LEAD_IS_BLOCKED') {
        setTeamLeadErrorMessage(
          `Administrator ${form.teamLead.value} is currently blocked. Blocked administrators cannot be set as a team lead. Please unblock him or choose someone else.`
        );
      }
      else if (form.redeployTimeSeconds && hasJRebelEnabled(form.products)) {
        Api.put(
          '/teams/redeploy-time-seconds',
          { origin: 'CREATE_TEAM', list: [ { token, redeployTimeSeconds: form.redeployTimeSeconds } ] },
          () => onCreateSaved(token)
        );
      }
      else {
        onCreateSaved(token);
      }
    });
  }

  function onCreateSaved(token) {
    Api.get('/admin-users', ({ list }) => {
      setActiveModal(form.teamLead.value && form.teamLead.value !== User.getUsername() ? 'teamLeadAdded' : 'success');
      setForm(prevForm => {
        return { ...prevForm, token };
      });
      setAdminUsers(list);
    });
  }

  function onEditSaved() {
    if (hasTeamLeadChanged(form, originalForm)) {
      setActiveModal('teamLeadAdded');
    }
    else {
      onSave();
    }
  }

  function saveEdit() {
    Api.put('/teams', toRequestParams(form), ({ error }) => {
      if (error === 'TEAM_LEAD_EXISTS_IN_ANOTHER_ACCOUNT') {
        setTeamLeadErrorMessage('Email address already in use, try another one.');
      }
      else if (error === 'TEAM_NAME_EXISTS') {
        setTeamNameErrorMessage('A team with such a name already exists.');
      }
      else if (error === 'TEAM_LEAD_IS_VIEW_ONLY_ADMIN') {
        setTeamLeadErrorMessage('View only administrators can not be team lead. Please change the role of the administrator or choose someone else.');
      }
      else if (error === 'TEAM_LEAD_IS_BLOCKED') {
        setTeamLeadErrorMessage(
          `Administrator ${form.teamLead.value} is currently blocked. Blocked administrators cannot be set as a team lead. Please unblock him or choose someone else.`
        );
      }
      else if (hasRedeployTimeChanged(form, originalForm) && hasJRebelEnabled(form.products)) {
        Api.put(
          '/teams/redeploy-time-seconds',
          { origin: 'EDIT_TEAM', list: [ { token: form.token, redeployTimeSeconds: form.redeployTimeSeconds } ] },
          onEditSaved
        );
      }
      else {
        onEditSaved();
      }
    });
  }

  function setTeamLeadErrorMessage(message) {
    setSaving(false);
    setForm(prevForm => {
      return { ...prevForm, teamLead: { ...prevForm.teamLead, errorMessage: message } };
    });
  }

  function setTeamNameErrorMessage(message) {
    setSaving(false);
    setForm(prevForm => {
      return { ...prevForm, name: { ...prevForm.name, errorMessage: message } };
    });
  }

  function onNameChange({ currentTarget: { value } }) {
    setForm(prevForm => {
      return { ...prevForm, name: { value, errorMessage: null } };
    });
  }

  function onTeamLeadChange({ currentTarget: { value } }) {
    setForm(prevForm => {
      return { ...prevForm, teamLead: { disabled: false, value, errorMessage: null } };
    });
  }

  function onTeamLeadDisabledChange({ currentTarget: { checked } }) {
    setForm(prevForm => {
      return { ...prevForm, teamLead: { disabled: checked, value: '', errorMessage: null } };
    });
  }

  function onProductsChange(products) {
    setForm(prevForm => {
      return { ...prevForm, products };
    });
  }

  function onRedeployTimeChange({ currentTarget: { value } }) {
    setForm(prevForm => {
      return { ...prevForm, redeployTimeSeconds: value };
    });
  }

  function onDeleteButtonPress() {
    setActiveModal('delete');
  }

  function onDeleteModalCancel() {
    setActiveModal('form');
  }

  return (
    <div className='TeamForm'>
      {activeModal === 'delete' && <DeleteModal team={originalForm} onSuccess={onSave} onCancel={onDeleteModalCancel} />}
      {activeModal === 'success' && <SuccessModal team={form} onClose={onSave} />}
      {activeModal === 'teamLeadAdded' && (<TeamLeadAddedModal teamLead={form.teamLead.value} adminUsers={adminUsers} onClose={onSave} />)}

      {activeModal === 'form' && (
        <Modal
          title={`${isEdit(form) ? 'Edit' : 'Add'} team`}
          className='TeamForm_modal'
          size='l'
          buttons={
            <Fragment>
              {!User.isTeamLead() && isEdit(form) && (
                <Button type='link' className='TeamForm_delete-team-button' onClick={onDeleteButtonPress}>Delete team</Button>
              )}
              <div>
                <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
                <ModalButton
                  type='primary'
                  disabled={saving || (User.isTeamLead() ? !isFormValidForTeamLead(form) : !isFormValid(form))}
                  submit
                >
                  {isEdit(form) ? 'Save changes' : 'Add team'}
                </ModalButton>
              </div>
            </Fragment>
          }
          onSubmit={onFormSubmit}
          onClose={onCancel}
        >
          <InputField
            type='text'
            name='name'
            label='Team name'
            tooltip={{
              size: 'l',
              text: 'Think what developer teams do you have and name the teams accordingly.'
            }}
            value={form.name.value}
            errorMessage={form.name.errorMessage}
            onChange={onNameChange}
            autoFocus
          />

          {!User.isTeamLead() && (
            <Fragment>
              <InputField
                type='text'
                name='teamLead'
                label='Team lead email'
                tooltip={{
                  size: 'l',
                  text: 'The team lead will receive an email invite to Rebel Licenses. The team lead can manage team members and view team statistics.'
                }}
                value={form.teamLead.value || ''}
                disabled={form.teamLead.disabled}
                errorMessage={form.teamLead.errorMessage}
                onChange={onTeamLeadChange}
              />

              <div className='TeamForm_team-lead-disabled-checkbox'>
                <input
                  type='checkbox'
                  name='teamLead.disabled'
                  id='teamLead.disabled'
                  checked={form.teamLead.disabled}
                  onChange={onTeamLeadDisabledChange}
                />

                <label htmlFor='teamLead.disabled'>I want to manage the team myself</label>
              </div>
            </Fragment>
          )}
          {(!User.isTeamLead() || hasJRebelEnabled(form.products)) && (
            <ProductsPanel
              products={form.products}
              redeployTimeSeconds={form.redeployTimeSeconds}
              onProductsChange={onProductsChange}
              onRedeployTimeChange={onRedeployTimeChange}
            />
          )}
        </Modal>
      )}
    </div>
  );
}
