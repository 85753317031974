import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert, { AlertEmphasis } from '../../../../components/Alert';
import Bubble from '../../../../components/Bubble';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import { InputField } from '../../../../components/InputField';
import LicenseTypeLabel from '../../../../components/LicenseTypeLabel';
import Modal, { ModalButton } from '../../../../components/Modal';
import UnitLabel from '../../../../components/UnitLabel';
import Api from '../../../../utils/Api';
import { isDomainBlacklisted } from '../../selectors';
import { hasExpired, isInTheFuture } from '../selectors';
import { getAvailableSeatCount, getEmailValidationMessage, getHostedSeatReservation } from './selectors';
import './styles.css';

export default function ExportDedicatedForm({ license, onCancel }) {

  const [ domainWhitelistingEnabled, setDomainWhitelistingEnabled ] = useState({ value: null, fetching: true });
  const [ seatUsage, setSeatUsage ] = useState({ items: [], fetching: true });
  const [ seatUsers, setSeatUsers ] = useState({ list: [], fetching: true });
  const [ whitelistedDomains, setWhitelistedDomains ] = useState({ list: [], fetching: true });
  const [ dedicatedLicenses, setDedicatedLicenses ] = useState({ list: [], fetching: true });
  const [ user, setUser ] = useState({ firstName: '', lastName: '', email: '' });
  const [ users, setUsers ] = useState([]);
  const [ emailErrorMessage, setEmailErrorMessage ] = useState(null);
  const [ showDomainWarning, setShowDomainWarning ] = useState(false);
  const [ saving, setSaving ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Api.get('/account', ({ domainWhitelistingEnabled }) => {
      setDomainWhitelistingEnabled({ value: domainWhitelistingEnabled, fetching: false });
    });

    Api.get('/whitelisted-domains', ({ list }) => {
      setWhitelistedDomains({ list, fetching: false });
    });

    Api.get('/seat-users', ({ list }) => {
      setSeatUsers({ list, fetching: false });
    });

    Api.get('/statistics', ({ seatUsage }) => {
      setSeatUsage({ items: seatUsage, fetching: false });
    });

    Api.get('/licenses/exported/dedicated', ({ list }) => {
      setDedicatedLicenses({ list, fetching: false });
    });
  }, []);

  function onFieldChange({ currentTarget: { name, value } }) {
    if (name === 'email') {
      setEmailErrorMessage(null);
      setUser(prevUser => {
        return { ...prevUser, [name]: value.toLowerCase() };
      });
    }
    else {
      setUser(prevUser => {
        return { ...prevUser, [name]: value };
      });
    }
  }

  function onSubmitButtonPress() {
    setSaving(true);

    Api.post(
      '/licenses/exported/dedicated',
      { users, orderItemId: license.orderItemId },
      () => navigate('/users', { state: { alert: 'exportDedicated', category: 'INDIVIDUAL' } })
    );
  }

  function onFormSubmit(e) {
    e.preventDefault();

    if (getEmailValidationMessage(user.email, domainWhitelistingEnabled.value, whitelistedDomains.list)) {
      setEmailErrorMessage(getEmailValidationMessage(user.email, domainWhitelistingEnabled.value, whitelistedDomains.list));

      if (domainWhitelistingEnabled.value && isDomainBlacklisted(user.email, whitelistedDomains.list)) {
        setShowDomainWarning(true);
      }

      return;
    }

    const submittedUsers = [ ...users ];

    if (!submittedUsers.some(({ email }) => email === user.email)) {
      if (getAvailableSeatCount(submittedUsers.concat([ user ]), seatUsage.items, seatUsers.list, license) < 0) {
        setEmailErrorMessage('There are no more available seats to export from this license');

        return;
      }

      submittedUsers.push(user);
    }

    setUsers(submittedUsers);
    setUser({ firstName: '', lastName: '', email: '' });
  }

  function onDeleteUser({ currentTarget }) {
    const deletedUsers = [ ...users ];
    deletedUsers.splice(deletedUsers.findIndex(({ email }) => email === currentTarget.getAttribute('data-email')), 1);

    setUsers(deletedUsers);
    setEmailErrorMessage(null);
  }

  function getOverlappingDedicatedLicense(email) {
    const overlappingLicense = license;

    return dedicatedLicenses.list
      .filter(({ licenseUser }) => licenseUser.email === email)
      .filter(({ type }) => type === overlappingLicense.type)
      .filter(({ endDate }) => !hasExpired(endDate))
      .find(dedicatedLicense => hasOverlap(dedicatedLicense, overlappingLicense));
  }

  function hasOverlap(license1, license2) {
    return _([ license1.startDate, license2.startDate ]).max() < _([ license1.endDate, license2.endDate ]).min();
  }

  function isAddButtonDisabled() {
    return !user.firstName || !user.lastName || !user.email ||
      domainWhitelistingEnabled.fetching || whitelistedDomains.fetching || seatUsers.fetching || dedicatedLicenses.fetching;
  }

  function renderBubbleWarning(email) {
    if (getHostedSeatReservation(email, seatUsers.list, license)) {
      return 'This user has already taken a regular seat. Exporting a seat for individual use will block him from taking a regular seat.';
    }

    if (getOverlappingDedicatedLicense(email)) {
      const { startDate, endDate } = getOverlappingDedicatedLicense(email);
      return (
        <Fragment>
          <span>This user already has a seat for individual use from </span>
          <span><DateTimeLabel timestamp={startDate} /> until <DateTimeLabel timestamp={endDate} /></span>
          <span>, which overlaps with the current seat.</span>
        </Fragment>
      );
    }

    return null;
  }

  return (
    <div className='ExportDedicatedForm'>
      {!seatUsage.fetching && (
        <Modal
          title={
            <span>
              <span className='ExportDedicatedForm_light-title'>Export individual </span>
              <LicenseTypeLabel id={license.type} />
              <span className='ExportDedicatedForm_light-title'> licenses</span>
            </span>
          }
          size='l'
          buttons={
            /* TODO CLS-789 Add cancel button in issue CLS-831 */
            <ModalButton type='primary' disabled={saving || users.length === 0} onClick={onSubmitButtonPress}>Export</ModalButton>
          }
          onSubmit={onFormSubmit}
          onClose={onCancel}
          confirmClose={true}
        >
          <p>
            You are about to export individual license files.
            A file will be sent to entered email addresses which can be used to enable <LicenseTypeLabel id={license.type} /> offline.
            The seat will be tied to a single user for the entire validity period and can only be reassigned
            by contacting <a href='mailto:sales@perforce.com'>sales@perforce.com</a>.
            Time savings data is not gathered for that seat.
            It is not possible to block or automatically renew individual licenses.
          </p>
          <Alert type='info'>
            <span>If you are having trouble activating JRebel or XRebel, please read through our </span>
            <a href='https://manuals.jrebel.com/zt-license-management/troubleshooter/#proxy-troubleshooter' target='_blank' rel='noopener noreferrer'>
              Proxy Configuration Guide
            </a>.
          </Alert>
          <div className='ExportDedicatedForm_available-seats'>
            <UnitLabel
              valueClass='ExportDedicatedForm_available-seats_number'
              value={getAvailableSeatCount(users, seatUsage.items, seatUsers.list, license)}
              unit='seat'
            />
            <span> available for </span>
            <LicenseTypeLabel id={license.type} />
            {isInTheFuture(license.startDate) && <span> from <DateTimeLabel timestamp={license.startDate} /></span>}
            <span> until </span><DateTimeLabel timestamp={license.endDate} />
          </div>

          {showDomainWarning && (
            <Alert type='warn'>
              <AlertEmphasis>Only following email domains are allowed: </AlertEmphasis>
              {whitelistedDomains.list.map(({ domain }) => domain).join(', ')}
            </Alert>
          )}

          <div className='ExportDedicatedForm_fields'>
            <InputField
              type='text'
              label='First name'
              name='firstName'
              value={user.firstName}
              onChange={onFieldChange}
              autoFocus
            />
            <InputField
              type='text'
              label='Last name'
              name='lastName'
              value={user.lastName}
              onChange={onFieldChange}
            />
            <InputField
              type='text'
              label='Email'
              placeholder='Email address'
              name='email'
              value={user.email}
              onChange={onFieldChange}
              errorMessage={emailErrorMessage}
            />
          </div>

          <ModalButton type='primary' disabled={isAddButtonDisabled()} submit>Add</ModalButton>

          <div className='ExportDedicatedForm_bubbles'>
            {users.map(({ firstName, lastName, email }) => (
              <Bubble key={email} data-email={email} text={`${firstName} ${lastName} (${email})`} onRemove={onDeleteUser} warningTooltip={renderBubbleWarning(email)} />
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}
