import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ActionsButton } from '../../../../components/ActionsButton';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import { DropdownMenuButton } from '../../../../components/Dropdown';
import NewSelectField from '../../../../components/NewSelectField';
import Page from '../../../../components/Page';
import { SearchField } from '../../../../components/SearchField';
import TabBar from '../../../../components/TabBar';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import BulkAction from '../../BulkAction';
import BulkActionItem from '../../BulkAction/BulkActionItem';
import BulkBlockModal from '../../BulkAction/BulkBlockModal';
import BulkChangeRoleModal from '../../BulkAction/BulkChangeRoleModal';
import BulkResendInviteModal from '../../BulkAction/BulkResendInviteModal';
import BulkUnblockModal from '../../BulkAction/BulkUnblockModal';
import { AdminBulkAction } from '../../BulkAction/labels';
import { AdminRole, AdminRoleLabels, AdminStatus, AdminStatusLabels } from '../labels';
import AdminUserForm from './AdminUserForm';
import Alerts from './Alerts';
import BlockModal from './BlockModal';
import ResendModal from './ResendModal';
import ResetModal from '../../SettingsPage/UserSettingsTab/ResetModal';
import UnblockModal from './UnblockModal';
import '../styles.css';
import './styles.css';

export default function AdminsTab() {

  const [ adminUsers, setAdminUsers ] = useState([]);
  const [ alert, setAlert ] = useState({ active: null, username: null });
  const [ bulkModal, setBulkModal ] = useState('');
  const [ fetchingAdminUsers, setFetchingAdminUsers ] = useState(true);
  const [ filterRole, setFilterRole ] = useState(null);
  const [ filterStatus, setFilterStatus ] = useState(null);
  const [ modal, setModal ] = useState({ active: null, username: null, role: null });
  const [ searchEmail, setSearchEmail ] = useState(null);
  const [ selectedUsers, setSelectedUsers ] = useState(null);
  const [ users, setUsers ] = useState([]);

  const sortUsers = useCallback(list => _(list).sortBy('lastLogin').reverse()
    .sortBy([
      user => getStatusSort(user.status),
      user => !isCurrentUser(user)
    ]).value(), []);

  const filterUsers = useCallback(() => {
    let filtered = adminUsers;
    if (filterRole) {
      filtered = filtered.filter(item => item.role === filterRole);
    }
    if (filterStatus) {
      filtered = filtered.filter(item => item.status === filterStatus);
    }
    if (searchEmail) {
      filtered = filtered.filter(item => item.username.includes(searchEmail));
    }
    setUsers(sortUsers(filtered));
    setSelectedUsers([]);
  }, [ adminUsers, filterRole, filterStatus, searchEmail, sortUsers ]);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [ adminUsers, filterRole, filterStatus, filterUsers, searchEmail ]);

  function canActionUsers(action) {
    if (!isSelectedUsers()) {
      return false;
    }
    for (const user of selectedUsers) {
      if (!canActionUser(user, action)) {
        return false;
      }
    }
    return true;
  }

  function canActionUser(user, action) {
    if (isCurrentUser(user)) {
      return false;
    }
    switch (action) {
    case AdminBulkAction.BLOCK:
      return user.status === AdminStatus.ACTIVE;
    case AdminBulkAction.CHANGE_ROLE:
      return user.status !== AdminStatus.BLOCKED;
    case AdminBulkAction.RESEND_INVITE:
      return user.status === AdminStatus.PENDING;
    case AdminBulkAction.UNBLOCK:
      return user.status === AdminStatus.BLOCKED;
    default:
      return false;
    }
  }

  function fetch() {
    Api.get('/admin-users', ({ list }) => {
      setAdminUsers(list);
      setFetchingAdminUsers(false);
    });
  }

  function getBulkActions() {
    if (!User.canEdit()) {
      return [];
    }
    if (filterStatus === AdminStatus.ACTIVE) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(AdminBulkAction.CHANGE_ROLE)} enabled={canActionUsers(AdminBulkAction.CHANGE_ROLE)} value='Change role' />,
        <BulkActionItem key='2' onClick={() => openBulkModal(AdminBulkAction.BLOCK)} enabled={canActionUsers(AdminBulkAction.BLOCK)} value='Block' />
      ];
    }
    else if (filterStatus === AdminStatus.PENDING) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(AdminBulkAction.CHANGE_ROLE)} enabled={canActionUsers(AdminBulkAction.CHANGE_ROLE)} value='Change role' />,
        <BulkActionItem key='2' onClick={() => openBulkModal(AdminBulkAction.RESEND_INVITE)} enabled={canActionUsers(AdminBulkAction.RESEND_INVITE)} value='Resend invite' />
      ];
    }
    else if (filterStatus === AdminStatus.BLOCKED) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(AdminBulkAction.UNBLOCK)} enabled={canActionUsers(AdminBulkAction.UNBLOCK)} value='Unblock' />
      ];
    }
    return [
      <BulkActionItem key='1' onClick={() => openBulkModal(AdminBulkAction.CHANGE_ROLE)} enabled={canActionUsers(AdminBulkAction.CHANGE_ROLE)} value='Change role' />,
      <BulkActionItem key='2' onClick={() => openBulkModal(AdminBulkAction.RESEND_INVITE)} enabled={canActionUsers(AdminBulkAction.RESEND_INVITE)} value='Resend invite' />,
      <BulkActionItem key='3' onClick={() => openBulkModal(AdminBulkAction.BLOCK)} enabled={canActionUsers(AdminBulkAction.BLOCK)} value='Block' />,
      <BulkActionItem key='4' onClick={() => openBulkModal(AdminBulkAction.UNBLOCK)} enabled={canActionUsers(AdminBulkAction.UNBLOCK)} value='Unblock' />
    ];
  }

  function getFilterRole() {
    const value = filterRole || '';
    return getRoles().find(option => option.value === value);
  }

  function getFilterStatus() {
    const value = filterStatus || '';
    return getStatuses().find(option => option.value === value);
  }

  function getRoles() {
    return [
      { value: '', label: 'All roles' },
      { value: AdminRole.ACCOUNT_ADMIN, label: AdminRoleLabels.ACCOUNT_ADMIN },
      { value: AdminRole.VIEW_ONLY_ADMIN, label: AdminRoleLabels.VIEW_ONLY_ADMIN },
      { value: AdminRole.TEAM_LEAD, label: AdminRoleLabels.TEAM_LEAD }
    ];
  }

  function getSearchEmail() {
    return searchEmail || '';
  }

  function getStatusSort(status) {
    switch (status) {
    case AdminStatus.ACTIVE:
      return 0;
    case AdminStatus.PENDING:
      return 1;
    case AdminStatus.BLOCKED:
      return 2;
    default:
      return 3;
    }
  }

  function getStatuses() {
    return [
      { value: '', label: 'All statuses' },
      { value: AdminStatus.ACTIVE, label: AdminStatusLabels.ACTIVE },
      { value: AdminStatus.PENDING, label: AdminStatusLabels.PENDING },
      { value: AdminStatus.BLOCKED, label: AdminStatusLabels.BLOCKED }
    ];
  }

  function isAllSelectedUsers() {
    return isSelectedUsers() && selectedUsers.length === users.length - (isCurrentUserInAllUsers() ? 1 : 0);
  }

  function isCurrentUser(user) {
    return user.username === User.getUsername();
  }

  function isCurrentUserInAllUsers() {
    return !!users.find(isCurrentUser);
  }

  function isFetching() {
    return fetchingAdminUsers;
  }

  function isSelectedUser(user) {
    return isSelectedUsers() && selectedUsers.includes(user);
  }

  function isSelectedUsers() {
    return selectedUsers !== null && selectedUsers.length > 0;
  }

  function onCancelBulkAction() {
    setSelectedUsers([]);
  }

  function onCancelBulkModal() {
    setBulkModal('');
  }

  function onCancelModal() {
    setModal({});
  }

  function onChangeCheckboxHeader(event) {
    if (selectedUsers === null) {
      return;
    }
    if (event.target.checked) {
      setSelectedUsers(users.filter(item => !isCurrentUser(item)));
    }
    else {
      setSelectedUsers([]);
    }
  }

  function onChangeCheckboxRow(event, user) {
    if (selectedUsers === null || isCurrentUser(user)) {
      return;
    }
    if (event.target.checked) {
      setSelectedUsers(prevSelectedUsers => {
        return [ ...prevSelectedUsers, user ];
      });
    }
    else {
      setSelectedUsers(prevSelectedUsers => {
        return prevSelectedUsers.filter(item => item.username !== user.username);
      });
    }
  }

  function onChangeFilterRole(newValue) {
    if (newValue.value === '') {
      setFilterRole(null);
    }
    else {
      setFilterRole(newValue.value);
    }
  }

  function onChangeFilterStatus(newValue) {
    if (newValue.value === '') {
      setFilterStatus(null);
    }
    else {
      setFilterStatus(newValue.value);
    }
  }

  function onChangeSearchEmail(event) {
    if (event.target.value === '') {
      setSearchEmail(null);
    }
    else {
      setSearchEmail(event.target.value);
    }
  }

  function onConfirmBulkBlock() {
    if (!isSelectedUsers()) {
      return;
    }
    const usernames = selectedUsers.map(item => item.username);
    Api.put('/admin-users/status/bulk', { usernames, status: AdminStatus.BLOCKED }, () => {
      onCancelBulkModal();
      fetch();
    });
  }

  function onConfirmBulkChangeRole(role) {
    if (!isSelectedUsers()) {
      return;
    }
    const usernames = selectedUsers.filter(item => role !== AdminRole.TEAM_LEAD && item.role !== role).map(item => item.username);
    Api.put('/admin-users/role/bulk', { usernames, role }, () => {
      onCancelBulkModal();
      fetch();
    });
  }

  function onConfirmBulkResendInvite() {
    if (!isSelectedUsers()) {
      return;
    }
    const usernames = selectedUsers.map(item => item.username);
    Api.post('/admin-users/invites/bulk', { usernames }, () => {
      onCancelBulkModal();
      fetch();
    });
  }

  function onConfirmBulkUnblock() {
    if (!isSelectedUsers()) {
      return;
    }
    const usernames = selectedUsers.map(item => item.username);
    Api.put('/admin-users/status/bulk', { usernames, status: AdminStatus.ACTIVE }, () => {
      onCancelBulkModal();
      fetch();
    });
  }

  function onHideAlert() {
    setAlert({});
  }

  function onPressActionsMenu(target) {
    onHideAlert();
    setModal({
      active: target.getAttribute('data-modal'),
      username: target.getAttribute('data-username'),
      role: target.getAttribute('data-role')
    });
  }

  function onShowAlert(name, username) {
    setAlert({ active: name, username });
  }

  function onSuccessBlock(username) {
    onCancelModal();
    fetch();
    onShowAlert('block', username);
  }

  function onSuccessCreate(username) {
    onCancelModal();
    fetch();
    onShowAlert('create', username);
  }

  function onSuccessEdit(username) {
    onCancelModal();
    fetch();
    onShowAlert('edit', username);
  }

  function onSuccessResend(username) {
    onCancelModal();
    onShowAlert('resend', username);
  }

  function onSuccessReset(username) {
    onCancelModal();
    onShowAlert('reset', username);
  }

  function onSuccessUnblock(username) {
    onCancelModal();
    fetch();
    onShowAlert('unblock', username);
  }

  function openBulkModal(action) {
    if (isSelectedUsers()) {
      setBulkModal(action);
    }
  }

  function renderCellActions(user) {
    if (!User.canEdit() && !isCurrentUser(user)) {
      return <TableCell />;
    }
    return (
      <TableCell>
        <ActionsButton className='pull-right'>
          {isCurrentUser(user) && (
            <DropdownMenuButton data-modal='reset' data-username={user.username} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Reset password
            </DropdownMenuButton>
          )}
          {canActionUser(user, AdminBulkAction.CHANGE_ROLE) && (
            <DropdownMenuButton data-modal='edit' data-username={user.username} data-role={user.role} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Change role
            </DropdownMenuButton>
          )}
          {canActionUser(user, AdminBulkAction.RESEND_INVITE) && (
            <DropdownMenuButton data-modal='resend' data-username={user.username} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Resend invite
            </DropdownMenuButton>
          )}
          {canActionUser(user, AdminBulkAction.BLOCK) && (
            <DropdownMenuButton data-modal='block' data-username={user.username} data-role={user.role} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Block
            </DropdownMenuButton>
          )}
          {canActionUser(user, AdminBulkAction.UNBLOCK) && (
            <DropdownMenuButton data-modal='unblock' data-username={user.username} data-role={user.role} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Unblock
            </DropdownMenuButton>
          )}
        </ActionsButton>
      </TableCell>
    );
  }

  function renderCellLastActive(user) {
    return (
      <TableCell>
        <DateTimeLabel timestamp={user.lastLogin} />
      </TableCell>
    );
  }

  function renderCellCheckbox(user) {
    if (selectedUsers === null) {
      return <></>;
    }
    if (isCurrentUser(user)) {
      return <TableCell className='AdminsTable_checkbox-cell' />;
    }
    return (
      <TableCell className='AdminsTable_checkbox-cell BulkAction_checkbox-row'>
        <input type='checkbox' checked={isSelectedUser(user)} onChange={e => onChangeCheckboxRow(e, user)} />
      </TableCell>
    );
  }

  function renderCellRole(user) {
    return (
      <TableCell>
        {AdminRoleLabels[user.role]}
      </TableCell>
    );
  }

  function renderCellStatus(user) {
    return (
      <TableCell>
        {AdminStatusLabels[user.status]}
      </TableCell>
    );
  }

  function renderCellUserEmail(user) {
    if (isCurrentUser(user)) {
      return (
        <TableCell>
          <b>{user.username}</b> <span>(You)</span>
        </TableCell>
      );
    }
    return (
      <TableCell>
        {user.username}
      </TableCell>
    );
  }

  function renderHeaderCheckbox() {
    if (selectedUsers === null) {
      return <></>;
    }
    return (
      <th className='AdminsTable_checkbox-column'>
        <input type='checkbox' checked={isAllSelectedUsers()} onChange={e => onChangeCheckboxHeader(e)} />
      </th>
    );
  }

  function renderNoData() {
    return (
      <div className='AdminsTable_no-results_message'>
        <a className='AdminsTable_no-results_message_image' />
        <div className='AdminsTable_no-results_message_text'>
          <h2>No results found</h2>
          <p>Search again or add a new administrator</p>
        </div>
      </div>
    );
  }

  function renderTableBody() {
    if (users.length === 0) {
      return (
        <TableRow className='AdminsTable_no-results'>
          <TableCell colSpan={5}>
            {isFetching() ? 'Fetching data...' : renderNoData()}
          </TableCell>
        </TableRow>
      );
    }
    return users.map((user, i) => {
      return (
        <TableRow key={i}>
          {renderCellCheckbox(user)}
          {renderCellUserEmail(user)}
          {renderCellRole(user)}
          {renderCellLastActive(user)}
          {renderCellStatus(user)}
          {renderCellActions(user)}
        </TableRow>
      );
    });
  }

  function renderTableHeader() {
    return (
      <TableRow>
        {renderHeaderCheckbox()}
        <th className='AdminsTable_email-column'>User email</th>
        <th className='AdminsTable_column'>Role</th>
        <th className='AdminsTable_column'>Last active</th>
        <th className='AdminsTable_column'>Status</th>
        <th className='AdminsTable_actions-column' />
      </TableRow>
    );
  }

  return (
    <Page title='Users' className='UsersPage' alert={<Alerts alert={alert} />}>
      {modal.active === 'block' && (
        <BlockModal username={modal.username} role={modal.role} onSuccess={onSuccessBlock} onCancel={onCancelModal} />
      )}
      {modal.active === 'create' && (
        <AdminUserForm onSuccess={onSuccessCreate} onCancel={onCancelModal} />
      )}
      {modal.active === 'edit' && (
        <AdminUserForm username={modal.username} role={modal.role} onSuccess={onSuccessEdit} onCancel={onCancelModal} />
      )}
      {modal.active === 'resend' && (
        <ResendModal username={modal.username} onSuccess={onSuccessResend} onCancel={onCancelModal} />
      )}
      {modal.active === 'reset' && (
        <ResetModal username={modal.username} onSuccess={onSuccessReset} onCancel={onCancelModal} />
      )}
      {modal.active === 'unblock' && (
        <UnblockModal username={modal.username} role={modal.role} onSuccess={onSuccessUnblock} onCancel={onCancelModal} />
      )}
      {bulkModal === AdminBulkAction.BLOCK && selectedUsers !== null && (
        <BulkBlockModal item='administrator' selected={selectedUsers.length} onConfirm={onConfirmBulkBlock} onCancel={onCancelBulkModal} />
      )}
      {bulkModal === AdminBulkAction.CHANGE_ROLE && selectedUsers !== null && (
        <BulkChangeRoleModal item='administrator' selected={selectedUsers.length} onConfirm={onConfirmBulkChangeRole} onCancel={onCancelBulkModal} />
      )}
      {bulkModal === AdminBulkAction.RESEND_INVITE && selectedUsers !== null && (
        <BulkResendInviteModal item='administrator' selected={selectedUsers.length} onConfirm={onConfirmBulkResendInvite} onCancel={onCancelBulkModal} />
      )}
      {bulkModal === AdminBulkAction.UNBLOCK && selectedUsers !== null && (
        <BulkUnblockModal item='administrator' selected={selectedUsers.length} onConfirm={onConfirmBulkUnblock} onCancel={onCancelBulkModal} />
      )}
      <TabBar>
        <NavLink end to='/users'>Users</NavLink>
        {!User.isTeamLead() && (
          <NavLink to='/users/admins'>Administrators</NavLink>
        )}
      </TabBar>
      <div className='UsersPage_details'>
        <div className='UsersPage_filters'>
          <div className='UsersPage_filters_selects'>
            <div className='UsersPage_filters_selects_select'>
              <NewSelectField label='Role' name='filterRole' onChange={onChangeFilterRole} options={getRoles()} value={getFilterRole()} />
            </div>
            <div className='UsersPage_filters_selects_select'>
              <NewSelectField label='Status' name='filterStatus' onChange={onChangeFilterStatus} options={getStatuses()} value={getFilterStatus()} />
            </div>
          </div>
          <div className='UsersPage_filters_actions'>
            <div className='UsersPage_filters_actions_search'>
              <SearchField name='searchEmail' onChange={onChangeSearchEmail} placeholder='Search admins by email' value={getSearchEmail()} />
            </div>
            {User.canEdit() && (
              <Button type='primary' data-modal='create' onClick={e => onPressActionsMenu(e.currentTarget)}>
                Add administrator
              </Button>
            )}
          </div>
        </div>
        <Card>
          <Table header={renderTableHeader()} body={renderTableBody()} className='AdminsTable' />
        </Card>
      </div>
      {selectedUsers !== null && (
        <BulkAction selected={selectedUsers.length} actions={getBulkActions()} onCancel={onCancelBulkAction} />
      )}
    </Page>
  );
}
