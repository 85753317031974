import { useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { AdminRole, AdminStatus } from '../labels';

export default function UnblockModal({ username, role, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onUnblockButtonPress() {
    setSaving(true);
    Api.put('/admin-users/status', { username, status: AdminStatus.ACTIVE }, () => {
      onSuccess(username);
    });
  }

  return (
    <Modal
      title={`Unblock ${role === AdminRole.TEAM_LEAD ? 'team lead' : 'administrator'}`}
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onUnblockButtonPress}>
            Unblock
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <p>You are about to unblock <strong>{username}</strong>.</p>
      <p>Are you sure?</p>
    </Modal>
  );
}
