export const AdminBulkAction = {
  __proto__: null,
  BLOCK: 'BLOCK',
  CHANGE_ROLE: 'CHANGE_ROLE',
  RESEND_INVITE: 'RESEND_INVITE',
  UNBLOCK: 'UNBLOCK'
};

export const TeamBulkAction = {
  __proto__: null,
  ADD_FAVOURITE: 'ADD_FAVOURITE',
  DELETE: 'DELETE',
  REMOVE_FAVOURITE: 'REMOVE_FAVOURITE'
};

export const UserBulkAction = {
  __proto__: null,
  BLOCK: 'BLOCK',
  DELETE: 'DELETE',
  RESEND_INVITE: 'RESEND_INVITE',
  UNBLOCK: 'UNBLOCK'
};
