import { useState } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';

type BulkDeleteModalProps = {
  item: string,
  selected: number,
  onCancel: any
  onConfirm: any
};

export default function BulkDeleteModal({ item, selected, onCancel, onConfirm }: BulkDeleteModalProps) {

  const [ saving, setSaving ] = useState(false);

  function onClickDelete() {
    setSaving(true);
    onConfirm();
  }

  return (
    <Modal
      title='Bulk delete'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='danger' disabled={saving} onClick={onClickDelete}>
            Delete
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <div>
        <p>You are about to delete <b>{selected}</b> {item}{selected > 1 ? 's' : ''}.</p>
        <p>This action cannot be reversed!</p>
        <p>Are you sure?</p>
      </div>
    </Modal>
  );
}
