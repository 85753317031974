import Alert, { AlertEmphasis } from '../../../../components/Alert';

type AlertProps = { alert: any, email?: any }
export default function Alerts({ alert: { active, email } }: AlertProps) {

  function render(message: any) {
    return (
      <Alert type='ok' onIconClick={() => {}}>
        {message}
      </Alert>
    );
  }

  switch (active) {
  case 'resend':
    return render(
      <span>Invitation email sent successfully to <AlertEmphasis>{email}</AlertEmphasis>.</span>
    );
  case 'block':
    return render(
      <span><AlertEmphasis>{email}</AlertEmphasis> has been blocked successfully.</span>
    );
  case 'unblock':
    return render(
      <span><AlertEmphasis>{email}</AlertEmphasis> has been unblocked successfully.</span>
    );
  case 'delete':
    return render(
      <span><AlertEmphasis>{email}</AlertEmphasis> has been deleted successfully.</span>
    );
  case 'exportDedicated':
    return render(
      <span><AlertEmphasis>Success!</AlertEmphasis> Users will receive emails with licenses attached for individual offline use.</span>
    );
  default:
    return <></>;
  }
}
