import React, { useEffect, useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { getTeamActiveSeatUsers } from '../../selectors';
import './styles.css';
import Card, { CardBody } from '../../../../components/Card';

export default function DeleteModal({ team, onSuccess, onCancel }) {

  const [ seatUsers, setSeatUsers ] = useState([]);
  const [ saving, setSaving ] = useState(false);

  useEffect(() => {
    Api.get('/seat-users', ({ list }) => {
      setSeatUsers(list);
    });
  }, []);

  function getTeamSeatUsers() {
    return getTeamActiveSeatUsers(seatUsers, team.name.value);
  }

  function onOkButtonPress() {
    setSaving(true);

    Api.del('/teams', { token: team.token }, onSuccess);
  }

  return (
    <Modal
      title='Delete team'
      size='l'
      buttons={<div>
        <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
        <ModalButton type='danger' disabled={saving} onClick={onOkButtonPress}>Delete</ModalButton>
      </div>}
      onClose={onCancel}
    >
      <p>You are about to delete the team <strong>{team.name.value}</strong>.</p>
      {getTeamSeatUsers().length > 0 ?

        <div>
          <p>This action cannot be reversed. Are you sure you want to continue?</p>
          <p>
            <span>Deleting this team will automatically revoke access for the following </span>
            <span>{getTeamSeatUsers().length} {getTeamSeatUsers().length === 1 ? 'user' : 'users'}:</span>
          </p>
          <Card>
            <CardBody className='DeleteModal_seat-user-list pre-scrollable'>
              {getTeamSeatUsers().map(({ email }) => <div key={email}>{email}</div>)}
            </CardBody>
          </Card>
        </div> :

        <p>Are you sure you want to delete this team?</p>
      }
    </Modal>
  );
}
