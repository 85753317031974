import React, { useEffect, useState } from 'react';
import Toggle from '../../../../components/Toggle';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import DisableTwoStepAuthenticationModal from './Disable2FAModal';
import './styles.css';
import EmailDomainWhitelistPanel from './EmailDomainWhitelistPanel';
import SsoSettingsPanel from './SsoSettingsPanel';

const OrganizationSettingsTab = ({ setPageAlert }) => {
  const [ accountStatus, setAccountStatus ] = useState();
  const [ fetchingAccountStatus, setFetchingAccountStatus ] = useState(true);
  const [ twoStepAuthentication, setTwoStepAuthentication ] = useState();
  const [ fetchingTwoStepAuthentication, setFetchingTwoStepAuthentication ] = useState(true);
  const [ alert, setAlert ] = useState('');
  const [ modal, setModal ] = useState('');

  function fetch() {
    Api.get('/account', ({ status }) => {
      setAccountStatus(status);
      setFetchingAccountStatus(false);
    });
    if (User.isAccountAdmin()) {
      Api.get('/account', ({ twoStepAuthenticationEnabled }) => {
        setTwoStepAuthentication(twoStepAuthenticationEnabled);
        setFetchingTwoStepAuthentication(false);
      });
    }
  }

  function getTwoStepAuthAlertText() {
    return `2-step verification ${twoStepAuthentication ? 'disabled' : 'enabled'} successfully.`;
  }

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setPageAlert(alert);
  }, [ alert, setPageAlert ]);

  const toggleTwoStepAuthentication = () => {
    setAlert('');

    if (twoStepAuthentication) {
      setModal('disableTwoStepAuthentication');
    }
    else {
      Api.put('/account/two-step-authentication-enabled', { twoStepAuthenticationEnabled: true }, () => {
        fetch();
        setAlert(getTwoStepAuthAlertText());
      });
    }
  };

  const closeModal = () => {
    setModal('');
  };

  const onDisableTwoStepAuthenticationSuccess = () => {
    closeModal();
    fetch();
    setAlert(getTwoStepAuthAlertText());
  };

  return (
    <>
      {User.isAccountAdmin() && modal === 'disableTwoStepAuthentication' && (
        <DisableTwoStepAuthenticationModal onSuccess={onDisableTwoStepAuthenticationSuccess} onCancel={closeModal} />
      )}
      <div className='OrganizationSettingsTab'>
        {!User.isTeamLead() && !fetchingAccountStatus && accountStatus !== 'EVALUATION_ONGOING' && accountStatus !== 'EVALUATION_ENDED' && (
          <div className='EmailDomainWhitelistSettings'>
            <EmailDomainWhitelistPanel />
          </div>
        )}
        {User.isAccountAdmin() && !fetchingTwoStepAuthentication && (
          <div className='TwoStepAuthentication'>
            <div className='TwoStepAuthentication_details'>
              <b>Enable 2-step verification</b>
              <p>Apply additional security to your account</p>
            </div>
            <Toggle
              enabled={twoStepAuthentication}
              onChange={toggleTwoStepAuthentication}
              className='TwoStepAuthentication_toggle'
            />
          </div>
        )}
        {(User.isAccountAdmin() || User.isAccountViewAdmin()) && (
          <div className='SsoSettings'>
            <SsoSettingsPanel setPageAlert={setAlert} />
          </div>
        )}
      </div>
    </>
  );
};

export default OrganizationSettingsTab;
