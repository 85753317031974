import React, { Fragment, useState } from 'react';
import InviteOrCopyButtons from '../../../../components/InviteOrCopyButtons';
import Modal, { ModalButton } from '../../../../components/Modal';
import TeamPanel, { TeamPanelTeamUrlLabel } from '../../../../components/TeamPanel';
import TokenUrlLabel from '../../../../components/TokenUrlLabel';
import SeatUserInviteForm from '../../SeatUserInviteForm';
import './styles.css';

export default function SuccessModal({ team, onClose }) {

  const [ inviteFormOpen, setInviteFormOpen ] = useState(false);

  function onInviteButtonPress() {
    setInviteFormOpen(true);
  }

  return inviteFormOpen ?

    <SeatUserInviteForm team={{ token: team.token, name: team.name.value }} onSave={onClose} onCancel={onClose} /> :

    (
      <Modal
        title='Your team is now ready'
        size='m'
        buttons={
          <Fragment>
            <a
              className='TeamForm_link'
              href='https://manuals.jrebel.com/zt-license-management/activation/'
              target='_blank'
              rel='noopener noreferrer'
            >
              How to activate JRebel & XRebel
            </a>
            <ModalButton type='secondary' className='TeamFormSuccessModal_ok-button pull-right' onClick={onClose}>Got it!</ModalButton>
          </Fragment>
        }
        onClose={onClose}
      >
        <div className='TeamFormSuccessModal_text'>
          Continue by <strong>inviting users</strong> below.
        </div>

        <TeamPanel name={team.name.value} bodyAlwaysWithHoverStyle>
          <div className='TeamFormSuccessModal_team-details'>
            <div>
              <TeamPanelTeamUrlLabel />
            </div>
            <div>
              <TokenUrlLabel token={team.token} forPanel />
            </div>
          </div>
          <div className='TeamFormSuccessModal_team-action'>
            <InviteOrCopyButtons
              className='pull-right'
              origin='TEAM_CREATED_PAGE'
              token={team.token}
              onInviteButtonPress={onInviteButtonPress}
              active
            />
          </div>
        </TeamPanel>
      </Modal>
    );
}
