// @ts-ignore
import request from 'superagent/lib/client';
import EventEmitter from './EventEmitter';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AccountData, LicenseDates, Statistics, TeamsWithProducts } from '../sharedTypes';

// Eslint is treating a type construct as if it was a function.
// eslint-disable-next-line no-unused-vars
type ResponseConsumer = (response: request.Response) => void;

// eslint-disable-next-line no-unused-vars
type ParsedResponseConsumer = (response: any) => void;

const LOCAL_STORAGE_KEY = {
  accessToken: 'api.accessToken'
};

function getAccessToken() {
  return localStorage.getItem(LOCAL_STORAGE_KEY.accessToken);
}

function rememberAccessToken(token: string) {
  localStorage.setItem(LOCAL_STORAGE_KEY.accessToken, token);
}

function forgetAccessToken() {
  localStorage.removeItem(LOCAL_STORAGE_KEY.accessToken);
}

function injectAccessToken(preparedRequest: request.Request): request.Request {
  if (getAccessToken()) {
    preparedRequest = preparedRequest.set('Authorization', `Bearer ${getAccessToken()}`);
  }
  return preparedRequest;
}

function executeWithCallback(preparedRequest: request.Request, callback: ParsedResponseConsumer) {
  preparedRequest = injectAccessToken(preparedRequest);

  preparedRequest.end((error: any, response: request.Response) => {
    if (error) {
      handleError(response);
    }
    else {
      if (callback) {
        callback(response.text ? JSON.parse(response.text) : {});
      }
    }
  });
}

/**
 * See execute() but does not process the response. For use with binary data and files.
 * @param preparedRequest A superagent request object.
 * @param callback  On success
 */
function executeRaw(preparedRequest: request.Request, callback: ResponseConsumer) {
  if (getAccessToken()) {
    preparedRequest = preparedRequest.set('Authorization', `Bearer ${getAccessToken()}`);
  }

  preparedRequest.end((error: any, response: request.Response) => {
    if (error) {
      handleError(response);
    }
    else {
      if (callback) {
        callback(response);
      }
    }
  });
}

function handleError(response: request.Response) {
  if (!response || !response.statusCode) {
    alert('Server connection failed. Please check you internet connection.');
  }
  else if (response.statusCode === 401 || response.statusCode === 403) {
    EventEmitter.emit('api.unauthorized');
  }
  else {
    alert('This should not have happened. Please contact support-rebel@perforce.com');
  }
}

function addContextPath(url: string) {
  return `/api${url}`;
}

/**
 *
 * @param contentDisposition {string} The value of the http Content-Disposition header
 * @return {string|null}  The filename if set, null otherwise. It may be an empty string if set explicitly.
 */
function filenameFromContentDisposition(contentDisposition: string) {
  const filenameField = contentDisposition.split(';')
    .map(v => v.trimStart())
    .find(field => field.startsWith('filename'));

  if (!filenameField) {
    return null;
  }

  const firstEquals = filenameField.indexOf('=');
  if (firstEquals === -1 || firstEquals >= filenameField.length) {
    return null;
  }
  let filename = filenameField.substring(firstEquals + 1, filenameField.length);

  // This allows for reliably locating the trailing quote of a quoted filename value, but may lead to loss of trailing or leading whitespace if the value is not quoted.
  filename = filename.trim();
  // if name is quoted remove quotes.
  if (filename.startsWith('"') && filename.endsWith('"')) {
    filename = filename.substring(1, filename.length - 1);
  }
  return filename;
}

export default class Api {

  static forgetAccessToken = forgetAccessToken;

  static auth(params: any, authMethod: string, successCallback: ParsedResponseConsumer, failedCallback: ResponseConsumer) {
    request.post(addContextPath(`/auth/${authMethod}`))
      .send(params)
      .accept('json')
      .type('form')
      .end((error: any, response: request.Response) => {
        if (error) {
          if (response && response.statusCode === 400) {
            if (response.text) {
              const parsedResponse = JSON.parse(response.text);
              failedCallback(parsedResponse);
            }
            else {
              failedCallback({});
            }
          }
          else {
            handleError(response);
          }
        }
        else {
          const parsedResponse = JSON.parse(response.text);
          rememberAccessToken(parsedResponse.accessToken);
          successCallback(parsedResponse);
        }
      });
  }

  static post(url: string, params: any, callback: ParsedResponseConsumer) {
    executeWithCallback(request.post(addContextPath(url)).accept('json').type('json').send(params), callback);
  }

  static postPromise(url: string, params: any): request.Request {
    let preparedRequest = request.post(addContextPath(url)).accept('json').type('json').send(params);
    preparedRequest = injectAccessToken(preparedRequest);
    return preparedRequest.then((response: { body: any; }) => {
      return response.body;
    });
  }

  static put(url: string, params: any, callback: ParsedResponseConsumer) {
    executeWithCallback(request.put(addContextPath(url)).accept('json').type('json').send(params), callback);
  }

  static putPromise(url: string, params: any): request.Request {
    let preparedRequest = request.put(addContextPath(url)).accept('json').type('json').send(params);
    preparedRequest = injectAccessToken(preparedRequest);
    return preparedRequest.then((response: { body: any; }) => {
      return response.body;
    });
  }

  static del(url: string, params: any, callback: ParsedResponseConsumer) {
    executeWithCallback(request.delete(addContextPath(url)).accept('json').type('json').send(params), callback);
  }

  static get(url: string, callback: ParsedResponseConsumer) {
    executeWithCallback(request.get(addContextPath(url)).accept('json'), callback);
  }

  static getPromise(url: string): request.Request {
    let preparedRequest = request.get(addContextPath(url)).accept('json').type('json');
    preparedRequest = injectAccessToken(preparedRequest);
    return preparedRequest.then((response: { body: any; }) => {
      return response.body;
    });
  }

  static initiateDownload(url: string) {
    window.open(addContextPath(url));
  }

  static initiateAuthenticatedDownload(url: string) {
    executeRaw(request.get(addContextPath(url)).responseType('blob'), response => {
      const contentDisposition = response.headers['content-disposition'];
      const filename = filenameFromContentDisposition(contentDisposition);

      const objectUrl = URL.createObjectURL(response.body);
      const link = window.document.createElement('a');
      if (filename) {
        link.download = filename;
      }

      link.href = objectUrl;
      link.style.display = 'none';
      window.document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        window.document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      }, 1000);
    });
  }
}

export function useAccountData(enabled: boolean): UseQueryResult<AccountData> {
  return useQuery({
    queryKey: [ 'account' ],
    queryFn: () => Api.getPromise('/account'),
    enabled
  });
}

export function useLicensesDates(enabled: boolean): UseQueryResult<LicenseDates> {
  return useQuery({
    queryKey: [ 'licensesDates' ],
    queryFn: () => Api.getPromise('/licenses/dates'),
    enabled
  });
}

export function useStatistics(): UseQueryResult<Statistics> {
  return useQuery({
    queryKey: [ 'statistics' ],
    queryFn: () => Api.getPromise('/statistics')
  });
}

export function useTeamsWithProducts(): UseQueryResult<TeamsWithProducts> {
  return useQuery({
    queryKey: [ 'teams', 'with_products' ],
    queryFn: () => Api.getPromise('/teams?with_products=true')
  });
}
