import _ from 'lodash';
import { UsersTabSeatUser } from './UsersTab';

export function getClosestLastSeen(user: UsersTabSeatUser) {
  return _(user.seats).map(({ lastSeen }) => lastSeen).max();
}

export function getFarthestOfflineUntil(user: UsersTabSeatUser) {
  return _(user.seats).map(({ offlineUntil }) => offlineUntil).max();
}

export function getOfflineProductCount(user: UsersTabSeatUser) {
  return user.seats.map(({ offline }) => offline).filter(offline => offline).length;
}

export function hasOfflineProducts(user: UsersTabSeatUser) {
  return getOfflineProductCount(user) > 0;
}
