import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionsButton } from '../../../../components/ActionsButton';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import { DropdownMenuButton } from '../../../../components/Dropdown';
import NewSelectField from '../../../../components/NewSelectField';
import Page from '../../../../components/Page';
import ProductTypeLabel from '../../../../components/ProductTypeLabel';
import { SearchField } from '../../../../components/SearchField';
import TabBar from '../../../../components/TabBar';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import WarningTooltip from '../../../../components/WarningTooltip';
import { productTypeOfStandalone } from '../../../../enums/LicenseType';
import { sortByProductType, sortProductTypes } from '../../../../enums/ProductType';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import BulkAction from '../../BulkAction';
import BulkActionItem from '../../BulkAction/BulkActionItem';
import BulkBlockModal from '../../BulkAction/BulkBlockModal';
import BulkDeleteModal from '../../BulkAction/BulkDeleteModal';
import BulkResendInviteModal from '../../BulkAction/BulkResendInviteModal';
import BulkUnblockModal from '../../BulkAction/BulkUnblockModal';
import { UserBulkAction } from '../../BulkAction/labels';
import { isDomainBlacklisted } from '../../selectors';
import { ProductTypeLabels, UserCategory, UserCategoryLabels, UserStatus } from '../labels';
import { getClosestLastSeen, getFarthestOfflineUntil, getOfflineProductCount, hasOfflineProducts } from '../selectors';
import AddUsersModal from './AddUsersModal';
import Alerts from './Alerts';
import BlockModal from './BlockModal';
import DeleteModal from './DeleteModal';
import ResendModal from './ResendModal';
import UnblockModal from './UnblockModal';
import '../styles.css';
import './styles.css';
import {
  AccountData,
  FixedPeriodStatistics,
  InvitedSeatUser,
  InvitedSeatUsers,
  LicenseDedicated,
  LicensedProducts,
  LicensesDedicated,
  ProductTypeName,
  RedeployTimeType,
  SeatReservation,
  SeatUser,
  SeatUsers,
  SeatUserStatus,
  Team,
  Teams
} from '../../../../sharedTypes';

export type UsersTabSeatUser = {
  email: string,
  status: SeatUserStatus,
  statusChangedAt: string,
  redeployCountTotal: number,
  timeSavedSeconds: FixedPeriodStatistics,
  redeployCount: FixedPeriodStatistics,
  redeployTimeSeconds: number,
  redeployTimeType: RedeployTimeType,
  seats: SeatReservation [],
  domainBlacklisted?: boolean
  statusChangedBy?: string,
  teamToken?: string
};
type ModalType = { active: any, user?: any, email?: any };
type AlertType = { active: any; username?: string | null, email?: any };

export default function UsersTab() {

  const location = useLocation();
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const [ alert, setAlert ] = useState<AlertType>({ active: location.state?.alert, username: null });
  const [ allUsers, setAllUsers ] = useState<UsersTabSeatUser[]>([]);
  const [ bulkModal, setBulkModal ] = useState('');
  const [ dedicatedLicenses, setDedicatedLicenses ] = useState<LicenseDedicated[]>([]);
  const [ enabledDomainWhitelisting, setEnabledDomainWhitelisting ] = useState(false);
  const [ enabledForgottenUsers, setEnabledForgottenUsers ] = useState(false);
  const [ fetchingAccount, setFetchingAccount ] = useState(true);
  const [ fetchingDedicatedLicenses, setFetchingDedicatedLicenses ] = useState(true);
  const [ fetchingInvitedSeatUsers, setFetchingInvitedSeatUsers ] = useState(true);
  const [ fetchingLicensedProducts, setFetchingLicensedProducts ] = useState(true);
  const [ fetchingSeatUsers, setFetchingSeatUsers ] = useState(true);
  const [ fetchingTeams, setFetchingTeams ] = useState(true);
  const [ fetchingWhitelistedDomains, setFetchingWhitelistedDomains ] = useState(true);
  const [ filterCategory, setFilterCategory ] = useState<string>(location.state?.category ? location.state.category : UserCategory.SEATS);
  const [ filterProduct, setFilterProduct ] = useState<string | null>(null);
  const [ filterTeam, setFilterTeam ] = useState(null);
  const [ invitedSeatUsers, setInvitedSeatUsers ] = useState<InvitedSeatUser[]>([]);
  const [ licensedProducts, setLicensedProducts ] = useState<ProductTypeName[]>([]);
  const [ modal, setModal ] = useState<ModalType>({ active: null, user: null });
  const [ searchEmail, setSearchEmail ] = useState<string | null>(null);
  const [ seatUsers, setSeatUsers ] = useState<SeatUser[]>([]);
  const [ selectedUsers, setSelectedUsers ] = useState<UsersTabSeatUser[] | null>(null);
  const [ teams, setTeams ] = useState<Team[]>([]);
  const [ users, setUsers ] = useState<UsersTabSeatUser[]>([]);
  const [ whitelistedDomains, setWhitelistedDomains ] = useState([]);

  const hasFilters = useCallback(() => searchParams.size > 0 || filterTeam || filterProduct, [ filterProduct, filterTeam, searchParams.size ]);

  const isFetching = useCallback(() => fetchingAccount || fetchingSeatUsers || fetchingInvitedSeatUsers || fetchingDedicatedLicenses ||
      fetchingTeams || fetchingWhitelistedDomains || fetchingLicensedProducts,
  [ fetchingAccount, fetchingDedicatedLicenses, fetchingInvitedSeatUsers, fetchingLicensedProducts, fetchingSeatUsers, fetchingTeams, fetchingWhitelistedDomains ]);

  const sortUsers = useCallback((list: UsersTabSeatUser[]) => {
    let sorted = _(list).map(user => Object.assign({}, user));
    if (filterCategory === UserCategory.BLOCKED) {
      sorted = sorted.sortBy(user => user.statusChangedAt);
    }
    else if (filterCategory === UserCategory.SEATS) {
      sorted = sorted.sortBy([
        user => getFarthestOfflineUntil(user) || '',
        user => getClosestLastSeen(user) || ''
      ]);
    }
    else if (filterCategory === UserCategory.TEAM) {
      sorted = sorted.sortBy([
        user => user.status === UserStatus.INVITED || '',
        user => getFarthestOfflineUntil(user) || '',
        user => getClosestLastSeen(user) || ''
      ]);
    }
    return sorted.reverse()
      .map(user => {
        user.seats = sortByProductType(user.seats);
        return user;
      }).value();
  }, [ filterCategory ]);

  const filterAllUsers = useCallback(() => {
    // @ts-ignore
    setAllUsers([
      ...seatUsers
        .map(user => ({
          ...user,
          seats: user.seatReservations
        })),
      ...invitedSeatUsers
        .map(({ email, teamToken }) => ({
          email,
          status: UserStatus.INVITED,
          teamToken,
          domainBlacklisted: enabledDomainWhitelisting && isDomainBlacklisted(email, whitelistedDomains),
          seats: [
            {
              // @ts-ignore
              teamName: teams.find(({ token }) => token === teamToken).name
            }
          ]
        })),
      ..._(dedicatedLicenses).groupBy(({ licenseUser: { email } }) => email)
        .map((licenses, email) => ({
          email,
          status: UserStatus.INDIVIDUAL,
          seats: licenses.map(license => ({
            ...license,
            productType: productTypeOfStandalone(license.type)
          }))
        })).value()
    ]);
  }, [ dedicatedLicenses, enabledDomainWhitelisting, invitedSeatUsers, seatUsers, teams, whitelistedDomains ]);

  const filterUsers = useCallback(() => {
    let filtered = allUsers.map(user => Object.assign({}, user));
    if (filterCategory === UserCategory.SEATS) {
      filtered = filtered.filter(({ status }) => [ UserStatus.ACTIVE, UserStatus.BLOCKED ].includes(status));
    }
    else if (filterCategory === UserCategory.TEAM) {
      filtered = filtered.filter(({ status }) => [ UserStatus.ACTIVE, UserStatus.BLOCKED, UserStatus.INVITED ].includes(status));
    }
    else {
      filtered = filtered.filter(({ status }) => status === filterCategory);
    }
    filtered = filtered.map(user => {
      let seats = user.seats;
      if (filterCategory === UserCategory.SEATS) {
        seats = seats.filter(seat => seat.offline || user.status !== UserStatus.BLOCKED);
      }
      if (searchParams.has('teamName')) {
        seats = seats.filter(seat => !seat.teamName || seat.teamName === searchParams.get('teamName'));
      }
      else if (filterTeam) {
        seats = seats.filter(seat => !seat.teamName || seat.teamName === filterTeam);
      }
      if (searchParams.has('productType')) {
        seats = seats.filter(seat => !seat.productType || seat.productType === searchParams.get('productType'));
      }
      else if (filterProduct) {
        seats = seats.filter(seat => !seat.productType || seat.productType === filterProduct);
      }
      if (searchParams.has('orderId')) {
        seats = seats.filter(seat => !seat.orderId || seat.orderId === searchParams.get('orderId'));
      }
      user.seats = seats;
      return user;
    });
    if (!(filterCategory === UserCategory.BLOCKED && !hasFilters())) {
      filtered = filtered.filter(({ seats }) => seats.length > 0);
    }
    if (searchEmail) {
      filtered = filtered.filter(({ email }) => email && email.includes(searchEmail));
    }
    setUsers(sortUsers(filtered));
    setSelectedUsers([]);
  }, [ allUsers, filterCategory, filterProduct, filterTeam, hasFilters, searchEmail, searchParams, sortUsers ]);

  const fetch = useCallback(() => {
    Api.get('/account', ({ domainWhitelistingEnabled, forgetUsersEnabled }: AccountData) => {
      setEnabledDomainWhitelisting(domainWhitelistingEnabled);
      setEnabledForgottenUsers(forgetUsersEnabled);
      setFetchingAccount(false);
    });
    Api.get('/seat-users', ({ list }: SeatUsers) => {
      setSeatUsers(list);
      setFetchingSeatUsers(false);
    });
    Api.get('/seat-users/invited', ({ list }: InvitedSeatUsers) => {
      setInvitedSeatUsers(list);
      setFetchingInvitedSeatUsers(false);
    });
    if (!User.isTeamLead()) {
      Api.get('/licenses/exported/dedicated', ({ list }: LicensesDedicated) => {
        setDedicatedLicenses(list);
        setFetchingDedicatedLicenses(false);
      });
    }
    else {
      setFetchingDedicatedLicenses(false);
    }
    Api.get('/teams', ({ list }: Teams) => {
      const teamName = searchParams.get('teamName');
      if (teamName && !list.find(({ name }) => name === teamName)) {
        navigate('/teams', { state: { redirectCause: 'teamNotFound' } });
      }
      else {
        setTeams(list);
        setFetchingTeams(false);
      }
    });
    Api.get('/account/licensed-products', ({ list }: LicensedProducts) => {
      setLicensedProducts(list);
      setFetchingLicensedProducts(false);
    });
    Api.get('/whitelisted-domains', ({ list }) => {
      setWhitelistedDomains(list);
      setFetchingWhitelistedDomains(false);
    });
  }, [ navigate, searchParams ]);

  useEffect(() => {
    fetch();
  }, [ fetch ]);

  useEffect(() => {
    if (!isFetching() && searchParams.get('teamName') && searchParams.get('origin') === 'EMAIL') {
      // @ts-ignore
      const token = teams.find(({ name }) => name === searchParams.get('teamName')).token;
      // @ts-ignore
      Api.post(`/teams/${token}/events/press/users-invite`, { eventUiOrigin: 'TEAM_USERS_PAGE', userActionOrigin: 'EMAIL' });
    }
    if (!isFetching()) {
      filterAllUsers();
    }
  }, [ fetchingAccount, fetchingDedicatedLicenses, fetchingInvitedSeatUsers, fetchingLicensedProducts, fetchingSeatUsers, fetchingTeams,
    fetchingWhitelistedDomains, filterAllUsers, isFetching, searchParams, teams ]);

  useEffect(() => {
    if (!isFetching()) {
      filterUsers();
    }
  }, [ allUsers, filterCategory, filterProduct, filterTeam, filterUsers, isFetching, searchEmail ]);

  function canActionUsers(action: string): boolean {
    if (!isSelectedUsers()) {
      return false;
    }
    // @ts-ignore
    for (const user of selectedUsers) {
      if (!canActionUser(user, action)) {
        return false;
      }
    }
    return true;
  }

  function canActionUser(user: UsersTabSeatUser, action: string): boolean {
    switch (action) {
    case UserBulkAction.BLOCK:
      return [ UserStatus.ACTIVE, UserStatus.INVITED ].includes(user.status);
    case UserBulkAction.DELETE:
      return [ UserStatus.ACTIVE, UserStatus.BLOCKED ].includes(user.status);
    case UserBulkAction.RESEND_INVITE:
      return user.status === UserStatus.INVITED;
    case UserBulkAction.UNBLOCK:
      return user.status === UserStatus.BLOCKED;
    default:
      return false;
    }
  }

  function getBulkActions() {
    if (!User.canEdit()) {
      return [];
    }
    if (filterCategory === UserCategory.TEAM) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(UserBulkAction.RESEND_INVITE)} enabled={canActionUsers(UserBulkAction.RESEND_INVITE)} value='Resend invite' />,
        <BulkActionItem key='2' onClick={() => openBulkModal(UserBulkAction.DELETE)} enabled={canActionUsers(UserBulkAction.DELETE)} value='Delete' />,
        <BulkActionItem key='3' onClick={() => openBulkModal(UserBulkAction.BLOCK)} enabled={canActionUsers(UserBulkAction.BLOCK)} value='Block' />
      ];
    }
    else if (filterCategory === UserCategory.SEATS) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(UserBulkAction.DELETE)} enabled={canActionUsers(UserBulkAction.DELETE)} value='Delete' />,
        <BulkActionItem key='2' onClick={() => openBulkModal(UserBulkAction.BLOCK)} enabled={canActionUsers(UserBulkAction.BLOCK)} value='Block' />
      ];
    }
    else if (filterCategory === UserCategory.INVITED) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(UserBulkAction.RESEND_INVITE)} enabled={canActionUsers(UserBulkAction.RESEND_INVITE)} value='Resend invite' />,
        <BulkActionItem key='2' onClick={() => openBulkModal(UserBulkAction.BLOCK)} enabled={canActionUsers(UserBulkAction.BLOCK)} value='Block' />
      ];
    }
    else if (filterCategory === UserCategory.BLOCKED) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(UserBulkAction.DELETE)} enabled={canActionUsers(UserBulkAction.DELETE)} value='Delete' />,
        <BulkActionItem key='2' onClick={() => openBulkModal(UserBulkAction.UNBLOCK)} enabled={canActionUsers(UserBulkAction.UNBLOCK)} value='Unblock' />
      ];
    }
    return [];
  }

  function getCategories() {
    if (enabledForgottenUsers) {
      if (User.isTeamLead()) {
        return [
          { value: UserCategory.SEATS, label: UserCategoryLabels.SEATS },
          { value: UserCategory.INVITED, label: UserCategoryLabels.INVITED },
          { value: UserCategory.BLOCKED, label: UserCategoryLabels.BLOCKED },
          { value: UserCategory.FORGOTTEN, label: UserCategoryLabels.FORGOTTEN }
        ];
      }
      return [
        { value: UserCategory.SEATS, label: UserCategoryLabels.SEATS },
        { value: UserCategory.INVITED, label: UserCategoryLabels.INVITED },
        { value: UserCategory.BLOCKED, label: UserCategoryLabels.BLOCKED },
        { value: UserCategory.FORGOTTEN, label: UserCategoryLabels.FORGOTTEN },
        { value: UserCategory.INDIVIDUAL, label: UserCategoryLabels.INDIVIDUAL }
      ];
    }
    if (User.isTeamLead()) {
      return [
        { value: UserCategory.SEATS, label: UserCategoryLabels.SEATS },
        { value: UserCategory.INVITED, label: UserCategoryLabels.INVITED },
        { value: UserCategory.BLOCKED, label: UserCategoryLabels.BLOCKED }
      ];
    }
    return [
      { value: UserCategory.SEATS, label: UserCategoryLabels.SEATS },
      { value: UserCategory.INVITED, label: UserCategoryLabels.INVITED },
      { value: UserCategory.BLOCKED, label: UserCategoryLabels.BLOCKED },
      { value: UserCategory.INDIVIDUAL, label: UserCategoryLabels.INDIVIDUAL }
    ];
  }

  function getDomainBlacklistedText() {
    return 'This user cannot activate due to email domain restrictions. Please check domain restrictions or invite the user with their company email.';
  }

  function getFilterCategory() {
    const value = filterCategory === UserCategory.TEAM ? UserCategory.SEATS : filterCategory;
    return getCategories().find(option => option.value === value);
  }

  function getFilterProduct() {
    const value = searchParams.get('productType') || filterProduct || '';
    return getProducts().find(option => option.value === value);
  }

  function getFilterTeam() {
    const value = searchParams.get('teamName') || filterTeam || '';
    return getTeams().find(option => option.value === value);
  }

  function getLastActives(user: UsersTabSeatUser) {
    if (getOfflineProductCount(user) === 0) {
      return [ getClosestLastSeen(user) ];
    }
    if (getOfflineProductCount(user) === user.seats.length) {
      return [];
    }
    return user.seats.filter(({ offline }) => !offline)
      .map(({ lastSeen }) => lastSeen);
  }

  function getProducts() {
    return [
      { value: '', label: 'All products' },
      ...sortProductTypes(licensedProducts).map(name => ({ value: name, label: ProductTypeLabels[name] }))
    ];
  }

  function getSearchEmail() {
    return searchEmail || '';
  }

  function getStatusLabel(user: UsersTabSeatUser) {
    if (user.status === UserStatus.INVITED) {
      return 'Invited';
    }
    if (hasOfflineProducts(user)) {
      return 'Offline';
    }
    return 'Active';
  }

  function getTeams() {
    return [
      { value: '', label: 'All teams' },
      ..._(teams).sortBy('name')
        .map(({ name }) => ({ value: name, label: name })).value()
    ];
  }

  function isActionableUser(user: UsersTabSeatUser): boolean {
    return [ UserStatus.ACTIVE, UserStatus.BLOCKED, UserStatus.INVITED ].includes(user.status);
  }

  function isAllSelectedUsers(): boolean {
    // @ts-ignore
    return isSelectedUsers() && selectedUsers.length === users.length;
  }

  function isSelectedUser(user: UsersTabSeatUser): boolean {
    // @ts-ignore
    return isSelectedUsers() && selectedUsers.includes(user);
  }

  function isSelectedUsers(): boolean {
    return selectedUsers !== null && selectedUsers.length > 0;
  }

  function onCancelBulkAction() {
    setSelectedUsers([]);
  }

  function onCancelBulkModal() {
    setBulkModal('');
  }

  function onCancelModal() {
    setModal({ active: null, user: null });
    searchParams.delete('modal');
    searchParams.delete('origin');
    setSearchParams(searchParams);
  }

  function onChangeCheckboxHeader(event: { target: { checked: any } }) {
    if (selectedUsers === null) {
      return;
    }
    if (event.target.checked) {
      setSelectedUsers(users);
    }
    else {
      setSelectedUsers([]);
    }
  }

  function onChangeCheckboxRow(event: { target: { checked: any } }, user: UsersTabSeatUser) {
    if (selectedUsers === null) {
      return;
    }
    if (event.target.checked) {
      setSelectedUsers(prevSelectedUsers => {
        // @ts-ignore
        return [ ...prevSelectedUsers, user ];
      });
    }
    else {
      setSelectedUsers(prevSelectedUsers => {
        // @ts-ignore
        return prevSelectedUsers.filter(item => item.email !== user.email);
      });
    }
  }

  function onChangeFilterCategory(newValue: { value: any }) {
    searchParams.delete('orderId');
    setSearchParams(searchParams);
    setFilterCategory(newValue.value);
  }

  function onChangeFilterProduct(newValue: { value: any }) {
    searchParams.delete('productType');
    setSearchParams(searchParams);
    if (newValue.value === '') {
      setFilterProduct(null);
    }
    else {
      setFilterProduct(newValue.value);
    }
  }

  function onChangeFilterTeam(newValue: { value: any }) {
    searchParams.delete('teamName');
    setSearchParams(searchParams);
    if (newValue.value === '') {
      setFilterTeam(null);
    }
    else {
      setFilterTeam(newValue.value);
    }
  }

  function onChangeSearchEmail(event: { target: { value: any } }) {
    if (event.target.value === '') {
      setSearchEmail(null);
    }
    else {
      setSearchEmail(event.target.value);
    }
  }

  function onConfirmBulkBlock() {
    if (!isSelectedUsers()) {
      return;
    }
    const promises = [];
    // @ts-ignore
    const emails = selectedUsers.filter(item => item.status !== UserStatus.INVITED).map(item => item.email);
    if (emails.length > 0) {
      promises.push(Api.putPromise('/seat-users/blocked/bulk', { emails, blocked: true }));
    }
    // @ts-ignore
    const list = selectedUsers.filter(item => item.status === UserStatus.INVITED).map(item => ({ email: item.email, token: item.teamToken }));
    if (list.length > 0) {
      promises.push(Api.postPromise('/teams/seat-users/invited/blocked/bulk', { list }));
    }
    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        onCancelBulkModal();
        fetch();
      });
    }
    else {
      onCancelBulkModal();
    }
  }

  function onConfirmBulkDelete() {
    if (!isSelectedUsers()) {
      return;
    }
    // @ts-ignore
    const emails = selectedUsers.map(item => item.email);
    Api.del('/seat-users/bulk', { emails }, () => {
      onCancelBulkModal();
      fetch();
    });
  }

  function onConfirmBulkResendInvite() {
    if (!isSelectedUsers()) {
      return;
    }
    // @ts-ignore
    const list = selectedUsers.map(item => ({ email: item.email, token: item.teamToken }));
    Api.post('/teams/seat-users/invites/bulk', { list }, () => {
      onCancelBulkModal();
    });
  }

  function onConfirmBulkUnblock() {
    if (!isSelectedUsers()) {
      return;
    }
    // @ts-ignore
    const emails = selectedUsers.map(item => item.email);
    Api.put('/seat-users/blocked/bulk', { emails, blocked: false }, () => {
      onCancelBulkModal();
      fetch();
    });
  }

  function onHideAlert() {
    setAlert({ active: null, username: null });
  }

  function onPressActionsMenu(target: Element) {
    onHideAlert();
    setModal({
      active: target.getAttribute('data-modal-id'),
      user: users.find(({ email, status }) => email === target.getAttribute('data-email') && status === target.getAttribute('data-status'))
    });
  }

  function onPressInviteUsers() {
    onHideAlert();
    if (searchParams.has('teamName')) {
      // @ts-ignore
      const token = teams.find(({ name }) => name === searchParams.get('teamName')).token;
      // @ts-ignore
      Api.post(`/teams/${token}/events/press/users-invite`, { eventUiOrigin: 'TEAM_USERS_PAGE', userActionOrigin: 'GUI' });
    }
    setModal({ active: 'add', email: null });
  }

  function onSaveInvite() {
    onCancelModal();
    fetch();
  }

  function onShowAlert(name: string, email: string) {
    setAlert({ active: name, email });
  }

  function onSuccessBlock(email: string) {
    onCancelModal();
    fetch();
    onShowAlert('block', email);
  }

  function onSuccessDelete(email: string) {
    onCancelModal();
    fetch();
    onShowAlert('delete', email);
  }

  function onSuccessResend(email: string) {
    onCancelModal();
    onShowAlert('resend', email);
  }

  function onSuccessUnblock(email: string) {
    onCancelModal();
    fetch();
    onShowAlert('unblock', email);
  }

  function openBulkModal(action: string) {
    if (isSelectedUsers()) {
      setBulkModal(action);
    }
  }

  function renderCellActions(user: UsersTabSeatUser) {
    if (!User.canEdit() || !isActionableUser(user)) {
      // @ts-ignore
      return <TableCell />;
    }
    return (
      // @ts-ignore
      <TableCell>
        <ActionsButton className='pull-right'>
          {canActionUser(user, UserBulkAction.RESEND_INVITE) && (
            <DropdownMenuButton data-modal-id='resend' data-email={user.email} data-status={user.status} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Resend invite
            </DropdownMenuButton>
          )}
          {canActionUser(user, UserBulkAction.DELETE) && (
            <DropdownMenuButton data-modal-id='delete' data-email={user.email} data-status={user.status} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Delete
            </DropdownMenuButton>
          )}
          {canActionUser(user, UserBulkAction.BLOCK) && (
            <DropdownMenuButton data-modal-id='block' data-email={user.email} data-status={user.status} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Block
            </DropdownMenuButton>
          )}
          {canActionUser(user, UserBulkAction.UNBLOCK) && (
            <DropdownMenuButton data-modal-id='unblock' data-email={user.email} data-status={user.status} onPress={e => onPressActionsMenu(e.currentTarget)}>
              Unblock
            </DropdownMenuButton>
          )}
        </ActionsButton>
      </TableCell>
    );
  }

  function renderCellBlockedSince(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        <DateTimeLabel timestamp={user.statusChangedAt} />
        <div className='UsersTable_blocked-by-label'>by {user.statusChangedBy}</div>
      </TableCell>
    );
  }

  function renderCellCheckbox(user: UsersTabSeatUser) {
    if (selectedUsers === null) {
      return <></>;
    }
    if ([ UserCategory.FORGOTTEN, UserCategory.INDIVIDUAL ].includes(filterCategory)) {
      // @ts-ignore
      return <TableCell className='UsersTable_checkbox-cell' />;
    }
    return (
      <TableCell className='UsersTable_checkbox-cell BulkAction_checkbox-row'>
        <input type='checkbox' checked={isSelectedUser(user)} onChange={e => onChangeCheckboxRow(e, user)} />
      </TableCell>
    );
  }

  function renderCellForgottenOn(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        <DateTimeLabel timestamp={user.statusChangedAt} />
      </TableCell>
    );
  }

  function renderCellLastActive(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        {getLastActives(user).map((lastActive, i) => <div key={i}><DateTimeLabel timestamp={lastActive} /></div>)}
      </TableCell>
    );
  }

  function renderCellProduct(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        {user.seats.map(({ productType, offlineUntil }, i) => {
          return (
            <div key={i}>
              {productType && (
                <ProductTypeLabel id={productType} />
              )}
              {offlineUntil && (
                <span className='UsersTable_offline-label'>Offline until <DateTimeLabel timestamp={offlineUntil} /></span>
              )}
            </div>
          );
        })}
      </TableCell>
    );
  }

  function renderCellStatus(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        {getStatusLabel(user)}
      </TableCell>
    );
  }

  function renderCellTeam(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        {_(user.seats).map(({ teamName }) => teamName).sort().uniq().map((teamName, i) => <div key={i}>{teamName}</div>).value()}
      </TableCell>
    );
  }

  function renderCellUser(user: UsersTabSeatUser) {
    return (
      <TableCell className='UsersTable_user-label'>
        {User.canEdit() && user.domainBlacklisted && (
          // @ts-ignore
          <WarningTooltip iconClassName='UsersTable_domain-warning' size='l' text={getDomainBlacklistedText()} />
        )}
        {user.email}
      </TableCell>
    );
  }

  function renderCellValidFrom(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        {user.seats.map(({ startDate }, i) => <div key={i}><DateTimeLabel timestamp={startDate} /></div>)}
      </TableCell>
    );
  }

  function renderCellValidUntil(user: UsersTabSeatUser) {
    return (
      // @ts-ignore
      <TableCell>
        {user.seats.map(({ endDate }, i) => <div key={i}><DateTimeLabel timestamp={endDate} /></div>)}
      </TableCell>
    );
  }

  function renderHeaderCheckbox() {
    if (selectedUsers === null) {
      return <></>;
    }
    if ([ UserCategory.FORGOTTEN, UserCategory.INDIVIDUAL ].includes(filterCategory)) {
      return <th className='UsersTable_checkbox-column' />;
    }
    return (
      <th className='UsersTable_checkbox-column'>
        <input type='checkbox' checked={isAllSelectedUsers()} onChange={e => onChangeCheckboxHeader(e)} />
      </th>
    );
  }

  function renderNoData() {
    return (
      <div className='UsersTable_no-results_message'>
        <a className='UsersTable_no-results_message_image' />
        <div className='UsersTable_no-results_message_text'>
          <h2>No results found</h2>
          <p>Search again or add a new user</p>
        </div>
      </div>
    );
  }

  function renderTableBody() {
    if (users.length === 0) {
      return (
        <TableRow className='UsersTable_no-results'>
          {/* @ts-ignore*/}
          <TableCell colSpan={6}>
            {isFetching() ? 'Fetching data...' : renderNoData()}
          </TableCell>
        </TableRow>
      );
    }
    return users.map(user => renderTableRow(user));
  }

  function renderTableHeader() {
    switch (filterCategory) {
    case UserCategory.BLOCKED:
      return (
        // @ts-ignore
        <TableRow>
          {renderHeaderCheckbox()}
          <th className='UsersTable_user-column'>User email</th>
          <th className='UsersTable_team-column'>Team</th>
          <th className='UsersTable_product-column'>Products</th>
          <th className='UsersTable_blocked-column'>Blocked since</th>
          <th className='UsersTable_actions-column' />
        </TableRow>
      );
    case UserCategory.FORGOTTEN:
      return (
        // @ts-ignore
        <TableRow>
          {renderHeaderCheckbox()}
          <th className='UsersTable_user-column'>User email</th>
          <th className='UsersTable_team-column'>Team</th>
          <th className='UsersTable_product-column'>Products</th>
          <th className='UsersTable_date-column'>Forgotten on</th>
          <th className='UsersTable_actions-column' />
        </TableRow>
      );
    case UserCategory.INDIVIDUAL:
      return (
        // @ts-ignore
        <TableRow>
          {renderHeaderCheckbox()}
          <th className='UsersTable_user-column'>User email</th>
          <th className='UsersTable_product-column'>Products</th>
          <th className='UsersTable_date-column'>Valid from</th>
          <th className='UsersTable_date-column'>Valid until</th>
          <th className='UsersTable_actions-column' />
        </TableRow>
      );
    case UserCategory.INVITED:
      return (
        // @ts-ignore
        <TableRow>
          {renderHeaderCheckbox()}
          <th className='UsersTable_user-column'>User email</th>
          <th className='UsersTable_team-column'>Team</th>
          <th className='UsersTable_actions-column' />
        </TableRow>
      );
    case UserCategory.TEAM:
      return (
        // @ts-ignore
        <TableRow>
          {renderHeaderCheckbox()}
          <th className='UsersTable_user-column'>User email</th>
          <th className='UsersTable_column'>Status</th>
          <th className='UsersTable_product-column'>Products</th>
          <th className='UsersTable_last-active-column'>Last active</th>
          <th className='UsersTable_actions-column' />
        </TableRow>
      );
    default:
      return (
        // @ts-ignore
        <TableRow>
          {renderHeaderCheckbox()}
          <th className='UsersTable_user-column'>User email</th>
          <th className='UsersTable_team-column'>Team</th>
          <th className='UsersTable_product-column'>Products</th>
          <th className='UsersTable_last-active-column'>Last active</th>
          <th className='UsersTable_actions-column' />
        </TableRow>
      );
    }
  }

  function renderTableRow(user: UsersTabSeatUser) {
    switch (filterCategory) {
    case UserCategory.BLOCKED:
      return (
        // @ts-ignore
        <TableRow>
          {renderCellCheckbox(user)}
          {renderCellUser(user)}
          {renderCellTeam(user)}
          {renderCellProduct(user)}
          {renderCellBlockedSince(user)}
          {renderCellActions(user)}
        </TableRow>
      );
    case UserCategory.FORGOTTEN:
      return (
        // @ts-ignore
        <TableRow>
          {renderCellCheckbox(user)}
          {renderCellUser(user)}
          {renderCellTeam(user)}
          {renderCellProduct(user)}
          {renderCellForgottenOn(user)}
          {renderCellActions(user)}
        </TableRow>
      );
    case UserCategory.INDIVIDUAL:
      return (
        // @ts-ignore
        <TableRow>
          {renderCellCheckbox(user)}
          {renderCellUser(user)}
          {renderCellProduct(user)}
          {renderCellValidFrom(user)}
          {renderCellValidUntil(user)}
          {renderCellActions(user)}
        </TableRow>
      );
    case UserCategory.INVITED:
      return (
        // @ts-ignore
        <TableRow>
          {renderCellCheckbox(user)}
          {renderCellUser(user)}
          {renderCellTeam(user)}
          {renderCellActions(user)}
        </TableRow>
      );
    case UserCategory.TEAM:
      return (
        // @ts-ignore
        <TableRow>
          {renderCellCheckbox(user)}
          {renderCellUser(user)}
          {renderCellStatus(user)}
          {renderCellProduct(user)}
          {renderCellLastActive(user)}
          {renderCellActions(user)}
        </TableRow>
      );
    default:
      return (
        // @ts-ignore
        <TableRow>
          {renderCellCheckbox(user)}
          {renderCellUser(user)}
          {renderCellTeam(user)}
          {renderCellProduct(user)}
          {renderCellLastActive(user)}
          {renderCellActions(user)}
        </TableRow>
      );
    }
  }

  return (
    <Page title='Users' className='UsersPage' alert={<Alerts alert={alert} />}>
      {(modal.active === 'add' || (searchParams.get('modal') === 'add' && searchParams.has('teamName') && !isFetching())) && (
        <AddUsersModal
          seatUsers={seatUsers}
          teams={teams}
          enableDomainWhitelisting={enabledDomainWhitelisting}
          whitelistedDomains={whitelistedDomains}
          // Bad type inference for plain js child. remove supression when child typed.
          // @ts-ignore
          teamName={searchParams.get('teamName')}
          onSuccess={onSaveInvite}
          onCancel={onCancelModal}
        />
      )}
      {modal.active === 'block' && (
        <BlockModal user={modal.user} onSuccess={onSuccessBlock} onCancel={onCancelModal} />
      )}
      {modal.active === 'delete' && (
        <DeleteModal user={modal.user} onSuccess={onSuccessDelete} onCancel={onCancelModal} />
      )}
      {modal.active === 'resend' && (
        <ResendModal user={modal.user} onSuccess={onSuccessResend} onCancel={onCancelModal} />
      )}
      {modal.active === 'unblock' && (
        <UnblockModal user={modal.user} onSuccess={onSuccessUnblock} onCancel={onCancelModal} />
      )}
      {bulkModal === UserBulkAction.BLOCK && selectedUsers !== null && (
        <BulkBlockModal item='user' selected={selectedUsers.length} onConfirm={onConfirmBulkBlock} onCancel={onCancelBulkModal} />
      )}
      {bulkModal === UserBulkAction.DELETE && selectedUsers !== null && (
        <BulkDeleteModal item='user' selected={selectedUsers.length} onConfirm={onConfirmBulkDelete} onCancel={onCancelBulkModal} />
      )}
      {bulkModal === UserBulkAction.RESEND_INVITE && selectedUsers !== null && (
        <BulkResendInviteModal item='user' selected={selectedUsers.length} onConfirm={onConfirmBulkResendInvite} onCancel={onCancelBulkModal} />
      )}
      {bulkModal === UserBulkAction.UNBLOCK && selectedUsers !== null && (
        <BulkUnblockModal item='user' selected={selectedUsers.length} onConfirm={onConfirmBulkUnblock} onCancel={onCancelBulkModal} />
      )}
      <TabBar>
        <NavLink end to='/users'>Users</NavLink>
        {!User.isTeamLead() && (
          <NavLink to='/users/admins'>Administrators</NavLink>
        )}
      </TabBar>
      <div className='UsersPage_details'>
        <div className='UsersPage_filters'>
          <div className='UsersPage_filters_selects'>
            <div className='UsersPage_filters_selects_select'>
              {/* @ts-ignore */}
              <NewSelectField label='Team' name='filterTeam' onChange={onChangeFilterTeam} options={getTeams()} value={getFilterTeam()} />
            </div>
            <div className='UsersPage_filters_selects_select'>
              {/* @ts-ignore */}
              <NewSelectField label='Product' name='filterProduct' onChange={onChangeFilterProduct} options={getProducts()} value={getFilterProduct()} />
            </div>
            <div className='UsersPage_filters_selects_select'>
              {/* @ts-ignore */}
              <NewSelectField label='Category' name='filterCategory' onChange={onChangeFilterCategory} options={getCategories()} value={getFilterCategory()} />
            </div>
          </div>
          <div className='UsersPage_filters_actions'>
            <div className='UsersPage_filters_actions_search'>
              <SearchField name='searchEmail' onChange={onChangeSearchEmail} placeholder='Search users by email' value={getSearchEmail()} />
            </div>
            {User.canEdit() && (
              <Button type='primary' onClick={onPressInviteUsers}>
                Add users
              </Button>
            )}
          </div>
        </div>
        <Card>
          <Table header={renderTableHeader()} body={renderTableBody()} className='UsersTable' />
        </Card>
      </div>
      {/* @ts-ignore */}
      {selectedUsers !== null && (
        <BulkAction selected={selectedUsers.length} actions={getBulkActions()} onCancel={onCancelBulkAction} />
      )}
    </Page>
  );
}
