import { useState } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';

type BulkUnblockModalProps = {
  item: string,
  selected: number,
  onCancel: any,
  onConfirm: any
};

export default function BulkUnblockModal({ item, selected, onCancel, onConfirm }: BulkUnblockModalProps) {

  const [ saving, setSaving ] = useState(false);

  function onClickUnblock() {
    setSaving(true);
    onConfirm();
  }

  return (
    <Modal
      title='Bulk unblock'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onClickUnblock}>
            Unblock
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <div>
        <p>You are about to unblock <b>{selected}</b> {item}{selected > 1 ? 's' : ''}.</p>
        <p>Are you sure?</p>
      </div>
    </Modal>
  );
}
