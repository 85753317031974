import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../../components/Page';
import { useAccountData, useLicensesDates, useStatistics, useTeamsWithProducts } from '../../../utils/Api';
import User from '../../../utils/User';
import { sendViewportPropertiesMetrics } from '../../../utils/ViewportHelper';
import Alerts from './Alerts';
import { hasFutureLicenses } from './Alerts/selectors';
import Onboarding from './Onboarding';
import TeamsOverview from './TeamsOverview';
import './styles.css';

type OnboardingType = 'noTeams' | 'newTeamLead' | 'newAdmin' | null;

const calculateOnboardingType = (teams: any[]): OnboardingType => {
  if (teams.length === 0) {
    if (User.isAccountAdmin()) {
      return 'noTeams';
    }
  }
  else if (User.shouldBeOnboarded()) {
    return (User.isTeamLead() ? 'newTeamLead' : 'newAdmin');
  }
  return null;
};

export default function Dashboard() {
  const queryClient = useQueryClient();
  const [ renderedAlerts, setRenderedAlerts ] = useState(false);
  const [ teamsElement, setTeamsElement ] = useState<Element | null>(null);
  const { isLoading: fetchingStatistics, data: statistics } = useStatistics(); // TODO JR-12689 seatUserCount and timeSavedSeconds from here.
  const { isLoading: fetchingAccount, data: account } = useAccountData(true /* User.isTeamLead() */); // TODO CLS-854 Dashboard UI fix
  const { isLoading: fetchingLicenseDates, data: { list: licenseDates } = { list: [] } } = useLicensesDates(true /* User.isTeamLead() */); // TODO CLS-854 Dashboard UI fix
  const { isLoading: fetchingTeams, data: { list: teams } = { list: [] } } = useTeamsWithProducts();

  const onboardingType = calculateOnboardingType(teams);

  // TODO: JR-12711 It should be possible to be more targeted with cache invalidation here.
  const invalidateCache = useCallback(() => {
    queryClient.clear();
  }, [ queryClient ]);

  useEffect(() => {
    sendViewportPropertiesMetrics('DASHBOARD');
  }, []);

  function exitOnboarding() {
    User.setOnboardedIfNeeded();
    invalidateCache();
  }

  function isFetching() {
    return (User.isTeamLead() && (fetchingAccount || fetchingLicenseDates)) || fetchingStatistics || fetchingTeams;
  }

  function onRefTeams(element: Element) {
    setTeamsElement(element);
  }

  function isLicenseExpired() {
    return account && ((account.status === 'UNLICENSED' && !hasFutureLicenses(licenseDates)) || account.status === 'EVALUATION_ENDED');
  }

  function isEnterprisePlan() {
    return account && account.status === 'COMMERCIAL';
  }

  function isOnboarding() {
    return !isFetching() && renderedAlerts && onboardingType && teamsElement;
  }

  return (
    <Page
      className='Dashboard'
      title='Dashboard'
      alert={!isFetching() && statistics && (
        <Alerts onChangeRedeployTime={invalidateCache} seatUsage={statistics.seatUsage} teams={teams} onRenderedAlerts={setRenderedAlerts} />
      )}
      noHeading
    >
      {isFetching() ?
        <h1 className='Dashboard_fetching'>Fetching data...</h1> :
        <>
          <div className='Dashboard_top'>
            <div className='Dashboard_top_details'>
              <div className='Dashboard_top_company'>
                <h2>{User.getOrganization()}</h2>
                <div className={`Dashboard_top_license ${isLicenseExpired() ? 'Dashboard_top_license-expired' : 'Dashboard_top_license-active'}`}>
                  <h6>{isLicenseExpired() ? 'Expired' : 'Active'}</h6>
                </div>
              </div>
              <h4>{isEnterprisePlan() ? 'Enterprise Plan' : 'Trial Evaluation'}</h4>
            </div>
            <div className='Dashboard_top_buy-link'>
              <b><a href='mailto:sales@perforce.com?subject=JRebel+Sales+Inquiry'>Buy more licenses</a></b>
            </div>
          </div>
          <div className='Dashboard_details'>
            <TeamsOverview teams={teams} onPressAnyButton={exitOnboarding} onRef={onRefTeams} onUpdateTeams={invalidateCache} />
            {isOnboarding() && (
              <Onboarding type={onboardingType} highlightedElement={teamsElement} onExit={exitOnboarding} />
            )}
          </div>
        </>
      }
    </Page>
  );
}
