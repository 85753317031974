import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Page from '../../components/Page';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import User from '../../utils/User';
import PropTypes from 'prop-types';

const SsoRedirectPage = ({ isLogin, isVerifyCallback }) => {
  const navigate = useNavigate();

  const [ errorDTO ] = useState(JSON.parse(sessionStorage.getItem('errorDTO')));
  const [ errorMessage ] = useState(errorDTO?.message);
  const [ errorDetails ] = useState(errorDTO?.exception);
  const [ stacktrace ] = useState(errorDTO?.stacktrace);

  const isErrorPresent = useCallback(() => errorMessage, [ errorMessage ]);
  const isErrorDetailsPresent = () => errorDetails;

  useEffect(() => {
    sessionStorage.removeItem('errorDTO');
  }, []);

  useEffect(() => {
    if (isLogin && !isErrorPresent()) {
      User.authenticateWithSsoRedirect(() => navigate('/'));
    }
  }, [ isErrorPresent, isLogin, navigate ]);

  return (
    <Page className='SsoRedirectPage' title={isVerifyCallback ? '' : 'SSO error'} noHeading>
      {!isErrorPresent() &&
        <Fragment>
          {isLogin &&
            <Fragment>
              <h1>Please wait... logging you in</h1>
            </Fragment>
          }

          {isVerifyCallback &&
            <Fragment>
              <h1>SSO verification success</h1>
              <span>We have successfully managed to authenticate You with the configured Identity Provider. Please close this tab and review Your SSO configuration.</span>
            </Fragment>
          }
        </Fragment>
      }

      {(isErrorPresent() || isErrorDetailsPresent()) &&
        <Fragment>
          <h1>Oops, something went wrong</h1>
          <span>If the problem persists please contact support at <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a></span>

          <div>
            <div>
              <h3>Technical details</h3>
              <span className='error-message'>{errorMessage}</span>
            </div>

            {isErrorDetailsPresent() &&
              <Fragment>
                <div className='technical'>
                  <p>
                    <span className='error-details'>Error details:</span> {errorDetails}
                    {stacktrace &&
                      <Fragment>
                        <br />

                        <span className='error-stacktrace'>Stacktrace:</span>
                        <pre>
                          {stacktrace}
                        </pre>
                      </Fragment>
                    }
                  </p>
                </div>
              </Fragment>
            }
          </div>
        </Fragment>
      }
    </Page>
  );
};

SsoRedirectPage.propTypes = {
  isLogin: PropTypes.bool,
  isVerifyCallback: PropTypes.bool
};

SsoRedirectPage.defaultProps = {
  isLogin: false,
  isVerifyCallback: false
};
export default SsoRedirectPage;
