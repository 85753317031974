import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import './styles.css';
import Button from '../../../../../components/Button';
import Api from '../../../../../utils/Api';
import User from '../../../../../utils/User';
import { SsoNotification } from './labels';
import SsoForm from './SsoForm';
import SsoList from './SsoList';

const SsoSettingsPanel = ({ setPageAlert }) => {
  const [ list, setList ] = useState([]);
  const [ fetching, setFetching ] = useState(true);
  const [ addForm, setAddForm ] = useState(false);
  const [ uuid, setUuid ] = useState(null);
  const ssoChannel = new BroadcastChannel('sso');

  const fetch = () => {
    Api.get('/sso', ({ list }) => {
      setList(list);
      setFetching(false);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const listener = message => {
    if (message.data && message.data.uuid && message.data.uuid === uuid) {
      const ssoStatus = message.data.status;
      if (ssoStatus === 'VERIFIED') {
        setPageAlert(SsoNotification.VEFRIFIED);
        Api.post(`/sso/${message.data.uuid}/activate`, null, ({ error }) => {
          if (!error) {
            setPageAlert(SsoNotification.ACTIVATED);
          }
          fetch();
        });
      }
      else {
        fetch();
      }
    }
  };

  const initSsoChannelListener = () => {
    ssoChannel.addEventListener('message', listener);
  };

  useEffect(() => {
    initSsoChannelListener();
    return () => {
      ssoChannel.removeEventListener('message', listener);
    };
  });

  const onAddButtonPress = () => {
    setPageAlert(null);
    setAddForm(true);
  };

  const closeModal = () => {
    setAddForm(false);
  };

  const onModalSave = () => {
    closeModal();
    fetch();
  };

  const sortedSsos = _(list)
    .orderBy([ 'updatedAt' ], [ 'desc' ])
    .value();

  const verify = ((uuid, callback) => {
    Api.post(`/sso/${uuid}/verifying`, null, () => {
      window.open(`/sso/verify/${uuid}`, '_blank');
      setUuid(uuid);
      if (callback) {
        callback();
      }
      setPageAlert(SsoNotification.VERIFYING);
    });
  });

  return (
    <>
      <div className='SsoSettingsPanel_add_sso'>
        <div className='SsoSettingsPanel_description'>
          <b>SSO Configuration</b>
          <p>Allow users to log in using Single Sign-On
            <a className='SsoSettingsPanel_manual-link-text' href='https://manuals.jrebel.com/zt-license-management/settings/' target='_blank'>Learn More</a>
          </p>
        </div>
        {User.isAccountAdmin() && (<Button type='primary' onClick={onAddButtonPress}>Add SSO</Button>)}
      </div>
      {addForm && (
        <SsoForm
          sso={{}}
          onSave={onModalSave}
          onCancel={closeModal}
          onVerify={verify}
          onShowNotification={setPageAlert}
        />
      )}

      <SsoList list={sortedSsos} onListUpdate={fetch} fetching={fetching} onVerify={verify} onShowNotification={setPageAlert} />
    </>
  );
};

export default SsoSettingsPanel;
