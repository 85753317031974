import React, { Component } from 'react';
import Container from '../components/Container';

const ErrorPage = () => {
  return (
    <div className='App'>
      <Container className='App_container'>
        <h1>This should not have happened. Please contact <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a></h1>
      </Container>
    </div>
  );
};

type ErrorBoundaryProps = {
  children: React.ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps> {

  state = {
    didCatch: false
  };

  componentDidCatch() {
    this.setState({ didCatch: true });
  }

  render() {
    if (this.state.didCatch) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
