import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import './styles.css';

const HOLE_MARGIN = 45;
const HOLE_OFFSET = 25;
const KEY_ESC = 27;

export default function Onboarding({ type, highlightedElement, onExit }) {

  const [ overlayHoleRect, setOverlayHoleRect ] = useState({ height: 0, left: 0, top: 0, width: 0 });

  const getOverlayHoleRect = useCallback(() => {
    const rect = highlightedElement.getBoundingClientRect();
    return {
      height: rect.height + 2 * HOLE_MARGIN,
      left: rect.left - HOLE_MARGIN,
      top: rect.top + window.scrollY - HOLE_MARGIN + HOLE_OFFSET,
      width: rect.width + 2 * HOLE_MARGIN
    };
  }, [ highlightedElement ]);

  const onKeyDown = useCallback(({ keyCode }) => {
    if (keyCode === KEY_ESC) {
      onExit();
    }
  }, [ onExit ]);

  const updateOverlayHoleRect = useCallback(() => {
    setOverlayHoleRect(getOverlayHoleRect());
  }, [ getOverlayHoleRect ]);

  useEffect(() => {
    window.addEventListener('resize', updateOverlayHoleRect);
    window.addEventListener('keydown', onKeyDown);
    updateOverlayHoleRect();
    return () => {
      window.removeEventListener('resize', updateOverlayHoleRect);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [ onKeyDown, updateOverlayHoleRect ]);

  function renderNoTeams() {
    return type === 'noTeams' && (
      <div className='DashboardOnboarding_content'>
        <p><b>Welcome to the Dashboard!</b></p>
        <p>Add a team and invite users to activate products.</p>
        <br />
        <p className='DashboardOnboarding_content_action'>Add a team now</p>
        <br />
        <i className='icon icon-onboarding-arrow-3' aria-hidden='true' />
      </div>
    );
  }

  function renderNewAdmin() {
    return type === 'newAdmin' && (
      <div className='DashboardOnboarding_content'>
        <p><b>Welcome to the Dashboard!</b></p>
        <p>Invite users to activate products or add a new team.</p>
        <br />
        <div className='DashboardOnboarding_content_action'>
          <p>Invite users</p>
          <p className='DashboardOnboarding_content_action_or'>or</p>
          <p>Add a team</p>
        </div>
        <br />
        <i className='icon icon-onboarding-arrow-2' aria-hidden='true' />
        <i className='icon icon-onboarding-arrow-3' aria-hidden='true' />
      </div>
    );
  }

  function renderNewTeamLead() {
    return type === 'newTeamLead' && (
      <div className='DashboardOnboarding_content'>
        <p><b>Welcome to the Dashboard!</b></p>
        <p>Invite users to your team to activate products.</p>
        <br />
        <p className='DashboardOnboarding_content_action'>Invite users now</p>
        <br />
        <i className='icon icon-onboarding-arrow-2' aria-hidden='true' />
      </div>
    );
  }

  return (
    <div className='DashboardOnboarding'>
      <div className='DashboardOnboarding_overlay' style={overlayHoleRect}>
        <div className='DashboardOnboarding_container'>
          {renderNoTeams()}
          {renderNewAdmin()}
          {renderNewTeamLead()}
        </div>
      </div>
      <Button className='DashboardOnboarding_close-button' onClick={onExit}>
        Close<i className='icon icon-close' aria-hidden='true' />
      </Button>
    </div>
  );
}
