import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, ReactNode } from 'react';
import Button, { ButtonProps } from '../Button';
import Form from '../Form';
import './styles.css';

ModalButton.propTypes = {
  className: PropTypes.string,
  submit: PropTypes.bool,
  type: PropTypes.string
};

export function ModalButton({ children, className, disabled = false, submit = false, type = 'default', ...otherProps }: ButtonProps) {
  return (

    <Button type={type} submit={submit} disabled={disabled} className={classNames('ModalButton', className)} {...otherProps}>
      {children}
    </Button>
  );
}

class ModalConfirmDanger extends Component<{
  isActive: boolean,
  // eslint-disable-next-line no-unused-vars
  onConfirm: (_: any) => void,

  onAbort: () => void,
  children: ReactNode
}> {

  render() {
    return this.props.isActive && (
      // This is a cyclic use. Needs refactoring to address.
      // eslint-disable-next-line no-use-before-define
      <Modal
        title=''
        className='ModalConfirmDanger_modal'
        size='m'
        buttons={
          <div>
            <ModalButton key='1' type='secondary' className='ModalConfirmDanger_abort-button' onClick={this.props.onAbort}>Keep editing</ModalButton>
            <ModalButton key='2' type='danger' onClick={this.props.onConfirm}>Close</ModalButton>
          </div>
        }
        onClose={this.props.onAbort}
      >
        {this.props.children}
      </Modal>
    );
  }
}

const ESC = 27;

type ModalProps = {
  title: ReactNode,
  header?: ReactNode,
  size?: string,
  buttons: ReactNode,
  className?: string,
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (_: any) => any,
  onClose: () => any,
  confirmClose: boolean,
  children: ReactNode
}
type ModalState = {
  showCloseConfirm: boolean
}
export default class Modal extends Component<ModalProps, ModalState> {

  static propTypes = {
    title: PropTypes.node.isRequired,
    header: PropTypes.node,
    size: PropTypes.oneOf([ 's', 'm', 'l' ]),
    buttons: PropTypes.node.isRequired,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    confirmClose: PropTypes.bool.isRequired
  };

  static defaultProps = {
    confirmClose: false
  };

  state = {
    showCloseConfirm: false
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onCloseForm = () => {
    if (this.props.confirmClose) {
      this.setState({ showCloseConfirm: true });
    }
    else {
      this.onConfirmCloseForm();
    }
  };

  onConfirmCloseForm = () => {
    this.props.onClose();
  };

  onCancelCloseForm = () => {
    this.setState({ showCloseConfirm: false });
  };

  onKeyDown = ({ keyCode }: { keyCode: number }) => {
    if (keyCode === ESC) {
      this.onCloseForm();
    }
  };

  renderConfirmClose() {
    return this.props.confirmClose && (
      <ModalConfirmDanger
        isActive={this.state.showCloseConfirm}
        onConfirm={this.onConfirmCloseForm}
        onAbort={this.onCancelCloseForm}
      >
        The data entered in the modal will be lost on closing!
      </ModalConfirmDanger>
    );
  }

  renderContent() {
    const { children, title, size, buttons, header, className } = this.props;

    return (
      <div className='Modal_show' role='dialog'>
        {this.renderConfirmClose()}
        <div className={classNames('Modal_dialog', className, size)} role='document'>

          <div className='Modal_content'>
            <div className='Modal_header'>
              <h2 className='Modal_title'>{title}</h2>
              {header}
              <Button className='Modal_close-button' onClick={this.onCloseForm}>
                <i className='icon icon-close' aria-hidden='true' />
              </Button>
            </div>

            <div className='Modal_body'>
              {children}
            </div>

            <div className='Modal_footer'>
              {buttons}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { onSubmit } = this.props;

    return (
      <div className='Modal'>
        {onSubmit ?

          <Form onSubmit={onSubmit}>{this.renderContent()}</Form> :

          <div>{this.renderContent()}</div>}

        {!this.state.showCloseConfirm && (<div className='Modal_backdrop' />)}
      </div>
    );
  }
}
