import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ComponentPropsWithoutRef } from 'react';
import InfoTooltip from '../InfoTooltip';
import './styles.css';

interface InputFieldProps extends ComponentPropsWithoutRef<'input'> {
  label?: string,
  tooltip?: any,
  idKey?: string | number
}

// eslint-disable-next-line react/display-name
export const SearchField = React.forwardRef<HTMLInputElement, InputFieldProps>((props: InputFieldProps, ref) => {
  const {
    label,
    name,
    placeholder,
    className,
    tooltip,
    idKey,
    ...otherProps
  } = props;
  const inputFieldId = `${name}${idKey ? `-${idKey}` : ''}`;
  return (
    <div className='SearchField'>
      <div>
        {label && <label className='SearchField_label' htmlFor={inputFieldId}>{label}</label>}
        {tooltip && <InfoTooltip {...tooltip} />}
      </div>
      <div className='SearchField_wrapper'>
        <i className='icon icon-search' />
        <input
          className={classNames('SearchField_input', className)}
          id={inputFieldId}
          maxLength={255}
          name={name}
          placeholder={placeholder || label}
          ref={ref}
          type='text'
          {...otherProps}
        />
      </div>
    </div>
  );
});

SearchField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.object
};
