import _ from 'lodash';
import { ProductTypeName, SeatReservation } from '../sharedTypes';

// defines also the sorting order in UI
export const productTypes: ProductTypeName[] = [
  'JREBEL',
  'XREBEL'
];

export function sortProductTypes(list: ProductTypeName[]): ProductTypeName[] {
  return _(list)
    .sortBy(productType => productTypes.indexOf(productType))
    .value();
}

export function sortByProductType(items: SeatReservation[]): SeatReservation[] {
  return _(items)
    .sortBy(({ productType }) => productTypes.indexOf(productType))
    .value();
}
