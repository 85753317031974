import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalButton } from '../../../../../../components/Modal';
import { InputField } from '../../../../../../components/InputField';
import Api from '../../../../../../utils/Api';
import { SsoNotification, translateErrorCode } from '../labels';
import { v4 as uuidv4 } from 'uuid';
import Alert from '../../../../../../components/Alert';
import './styles.css';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import CopyTextButton from '../../../../../TeamTokenPage/CopyTextButton';

const SsoForm = ({ sso, onSave, onCancel, onVerify, onShowNotification }) => {

  const isEdit = () => {
    return sso && sso.uuid;
  };

  const [ notification, setNotification ] = useState(null);
  const [ newClientName ] = useState(uuidv4());
  const [ form, setForm ] = useState({
    clientName: { value: isEdit() ? sso.clientName : newClientName },
    clientId: { value: sso.clientId },
    clientSecret: { value: sso.clientSecret },
    callbackUrl: sso.callbackUrl ?? `${window.location.origin}/sso/callback/${isEdit() ? sso.clientName : sso.clientName || newClientName }`,
    discoveryUrl: { value: sso.discoveryUrl }
  });

  const validateDiscoveryUrl = url => {
    if (url === '') {
      return 'Value must not be null';
    }
    else if (!url || !url.startsWith('https://') && !url.startsWith('http://')) {
      return 'Value must be an URL';
    }
    return null;
  };

  const validateClientName = url => {
    if (url === '') {
      return 'Value must not be null';
    }
    else if (!url || !url.match(/^[0-9a-z-]+$/)) {
      return 'Value must contain only numbers, letters and hyphens';
    }
    return null;
  };

  const validateValue = value => {
    return value === '' ? 'Value must not be null' : null;
  };

  const isFormValid = () => {
    return validateDiscoveryUrl(form.discoveryUrl.value) === null &&
      validateClientName(form.clientName.value) === null &&
      validateValue(form.clientId.value) === null &&
      validateValue(form.clientSecret.value) === null;
  };

  const onClientIdChange = ({ currentTarget: { value } }) => {
    setForm(({ ...form, clientId: { 'value': value, errorMessage: validateValue(value) } }));
  };

  const onClientNameChange = ({ currentTarget: { value } }) => {
    setForm(({ ...form, clientName: { 'value': value, errorMessage: validateClientName(value) }, callbackUrl: `${window.location.origin}/sso/callback/${value}` }));
  };

  const onClientSecretChange = ({ currentTarget: { value } }) => {
    setForm(({ ...form, clientSecret: { 'value': value, errorMessage: validateValue(value) } }));
  };

  const onDiscoveryUrlChange = ({ currentTarget: { value } }) => {
    setForm(({ ...form, discoveryUrl: { 'value': value, 'errorMessage': validateDiscoveryUrl(value) } }));
  };

  const onFormSubmit = (e, isDraft) => {
    setNotification(null);
    e.preventDefault();

    if (!isDraft && !isFormValid()) {
      return;
    }

    const verifyAndSave = (error, token) => {
      if (error) {
        setNotification(translateErrorCode(error));
      }
      else {
        if (isDraft) {
          onShowNotification(SsoNotification.SAVE_DRAFT);
          onSave();
        }
        else {
          onVerify(token, onSave);
        }
      }
    };

    const save = () => {
      if (isEdit()) {
        Api.put(
          `/sso/${sso.uuid}`,
          {
            clientName: form.clientName.value,
            clientId: form.clientId.value,
            clientSecret: form.clientSecret.value,
            discoveryUrl: form.discoveryUrl.value
          },
          ({ error }) => {
            verifyAndSave(error, sso.uuid);
          });
      }
      else {
        Api.post(
          '/sso',
          {
            clientName: form.clientName.value,
            clientId: form.clientId.value,
            clientSecret: form.clientSecret.value,
            discoveryUrl: form.discoveryUrl.value
          },
          ({ uuid, error }) => {
            verifyAndSave(error, uuid);
          });
      }
    };

    if (isDraft) {
      save();
    }
    else {
      Api.get(`/sso/validate-metadata?url=${form.discoveryUrl.value}`, ({ error }) => {
        if (error) {
          setNotification(`Loading Discovery URL failed with "${error}"`);
        }
        else {
          save();
        }
      });
    }
  };

  return (
    <div className='SsoForm'>
      <Modal
        title={isEdit() ? 'Edit OpenID Connect configuration' : 'Add OpenID Connect configuration'}
        className='SsoForm_modal'
        onClose={onCancel}
        confirmClose={false}
        onSubmit={e => onFormSubmit(e, false)}
        buttons={
          <Fragment>
            <div className='buttons'>
              <ModalButton type='secondary' onClick={e => onFormSubmit(e, true)}>Save as Draft</ModalButton>

              <div>
                <ModalButton type='secondary' onClick={onCancel}>Cancel</ModalButton>
                <ModalButton type='primary' disabled={!isFormValid()} submit={true}>Verify</ModalButton>
              </div>
            </div>

          </Fragment>
        }
      >
        {notification && <Alert type='error'>{notification}</Alert>}
        <InputField
          type='text'
          name='clientName'
          label='Client name'
          tooltip={{
            size: 'l',
            text: 'Unique name for this OIDC client applicatione.'
          }}
          value={form.clientName.value}
          errorMessage={form.clientName.errorMessage}
          onChange={onClientNameChange}
          required
          autoFocus
        />

        <InputField
          type='text'
          name='clientId'
          label='Client ID'
          tooltip={{
            size: 'l',
            text: 'Unique ID generated for this OIDC client application.'
          }}
          value={form.clientId.value}
          errorMessage={form.clientId.errorMessage}
          onChange={onClientIdChange}
          required
          autoFocus
        />

        <InputField
          type='text'
          name='clientSecret'
          label='Client Secret'
          tooltip={{
            size: 'l',
            text: 'A secret value matched to the Client ID value.'
          }}
          value={form.clientSecret.value}
          errorMessage={form.clientSecret.errorMessage}
          onChange={onClientSecretChange}
          required
        />

        <div className='InputField'>
          <div>
            <label className='InputField_label' htmlFor='callbackUrl'>Callback URL</label>
            <InfoTooltip text='URL that is used by identity provider to redirect browser back to this application.' size='l' />
          </div>
          <div className='InputField_input-wrapper'>
            <CopyTextButton
              component='button'
              text={form.callbackUrl}
              copiedTooltip={{ offset: [ 10, 10 ] }}
              hoverTooltip={{ text: 'Click to copy Callback URL', offset: [ 10, -5 ] }}
              className='SsoDetails_value-tooltip'
            >
              {form.callbackUrl}
            </CopyTextButton>

          </div>
        </div>

        <InputField
          type='text'
          name='discoveryUrl'
          label='Discovery URL'
          tooltip={{
            size: 'l',
            text: 'URL to the identity provider public endpoint hosting OIDC configuration metadata. URL usually ends with ".well-known/openid-configuration".'
          }}
          value={form.discoveryUrl.value}
          errorMessage={form.discoveryUrl.errorMessage}
          onChange={onDiscoveryUrlChange}
          required
        />
      </Modal>
    </div>
  );
};

SsoForm.prototype = {
  sso: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  onShowNotification: PropTypes.func.isRequired
};

export default SsoForm;
