import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import { getHavingOtherDomainThanListOrForm } from '../selectors';
import './styles.css';
import Card, { CardBody } from '../../../components/Card';

const EnableDomainWhitelistingModal = ({ onCancel, onSave, domains, addedDomain, invitedSeatUsers }) => {
  const [ saving, setSaving ] = useState(false);

  const saveFormAndCloseModal = () => {
    setSaving(true);

    onSave();
  };

  function formatDomainsAsHumanReadableList() {
    const list = domains.map(({ domain }) => domain).concat(addedDomain ? [ addedDomain ] : []);

    return (
      <>
        {list.map((domain, i) => (
          <Fragment key={domain}>
            {i > 0 && list.length > i + 1 && ', '}
            {i > 0 && list.length === i + 1 && ' and '}
            <strong>{domain}</strong>
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <Modal
      title='Activation restrictions'
      size='m'
      buttons={<div>
        <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
        <ModalButton type='primary' disabled={saving} onClick={saveFormAndCloseModal}>Confirm</ModalButton>
      </div>}
      onClose={onCancel}
    >
      <p>
        Activation will only be allowed with {formatDomainsAsHumanReadableList()}.<br />
        Users who have already activated will not be affected, use{' '}
        <a href='https://manuals.jrebel.com/zt-license-management/users/#blocking-users' rel='noopener noreferrer' target='_blank'>Blocking</a>{' '}
        if needed.<br />
        However the following pending users will be prevented from acquiring a seat:
      </p>

      <Card className='scroll-grey'>
        <CardBody className='EnableDomainWhitelistingModal_seat-users-list pre-scrollable'>
          {getHavingOtherDomainThanListOrForm(invitedSeatUsers, domains, addedDomain).map(({ email }) => <h6 key={email}>{email}</h6>)}
        </CardBody>
      </Card>

      <p className='EnableDomainWhitelistingModal_confirmation-text'>Do you want to continue with the domain whitelist?</p>
    </Modal>
  );
};

EnableDomainWhitelistingModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string.isRequired
    })
  ).isRequired,
  addedDomain: PropTypes.string,
  invitedSeatUsers: PropTypes.array.isRequired
};

export default EnableDomainWhitelistingModal;
