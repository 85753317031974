import emailValidator from 'email-validator';
import React, { useEffect, useState } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';
import Bubble from '../../../components/Bubble';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import { getTeamActiveSeatUsers, isDomainBlacklisted } from '../selectors';
import './styles.css';

export default function SeatUserInviteForm({ team, onSave, onCancel }) {

  const [ domainWhitelistingEnabled, setDomainWhitelistingEnabled ] = useState({ value: null, fetching: true });
  const [ whitelistedDomains, setWhitelistedDomains ] = useState({ list: [], fetching: true });
  const [ seatUsers, setSeatUsers ] = useState({ list: [], fetching: true });
  const [ email, setEmail ] = useState('');
  const [ emails, setEmails ] = useState([]);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ showDomainWarning, setShowDomainWarning ] = useState(false);
  const [ activeModal, setActiveModal ] = useState('form');
  const [ saving, setSaving ] = useState(false);

  useEffect(() => {
    Api.get('/account', ({ domainWhitelistingEnabled }) => {
      setDomainWhitelistingEnabled({ value: domainWhitelistingEnabled, fetching: false });
    });

    Api.get('/whitelisted-domains', ({ list }) => {
      setWhitelistedDomains({ list, fetching: false });
    });

    Api.get('/seat-users', ({ list }) => {
      setSeatUsers({ list, fetching: false });
    });
  }, []);

  function onFormSubmit(e) {
    e.preventDefault();

    const inputEmails = email.trim().split(/[ ,]+/g);
    const validEmails = [];
    const invalidEmails = [];
    const blacklistEmails = [];
    const duplicateEmails = [];
    const errorMessages = [];

    for (let i = 0; i < inputEmails.length; i++) {
      const inputEmail = inputEmails[i];
      let isInvalid = false;

      if (!emailValidator.validate(inputEmail)) {
        invalidEmails.push(inputEmail);
        isInvalid = true;
      }
      else if (domainWhitelistingEnabled.value && isDomainBlacklisted(inputEmail, whitelistedDomains.list)) {
        blacklistEmails.push(inputEmail);
        isInvalid = true;
      }
      else if (getTeamActiveSeatUsers(seatUsers.list, team.name).some(({ email }) => email === inputEmail)) {
        duplicateEmails.push(inputEmail);
        isInvalid = true;
      }
      if (isInvalid && domainWhitelistingEnabled.value && isDomainBlacklisted(inputEmail, whitelistedDomains.list)) {
        setShowDomainWarning(true);
      }
      if (!isInvalid) {
        validEmails.push(inputEmail);
      }
    }

    if (invalidEmails.length > 0) {
      errorMessages.push(`Please enter a valid email address: ${invalidEmails.join(', ')}.`);
    }
    if (blacklistEmails.length > 0) {
      errorMessages.push(`This email domain is not allowed by your licensing administrator: ${blacklistEmails.join(', ')}.`);
    }
    if (duplicateEmails.length > 0) {
      errorMessages.push(`A user with this email has already activated a product in this team: ${duplicateEmails.join(', ')}.`);
    }
    if (errorMessages.length > 0) {
      setErrorMessage(errorMessages.join(' '));
    }

    if (validEmails.length > 0) {
      setEmails(prevEmails => {
        for (let j = 0; j < validEmails.length; j++) {
          const validEmail = validEmails[j];
          if (!prevEmails.includes(validEmail)) {
            prevEmails.push(validEmail);
          }
        }
        return [ ...prevEmails ];
      });
    }

    setEmail('');
  }

  function onEmailChange({ currentTarget: { value } }) {
    setEmail(value);
    setErrorMessage(null);
  }

  function onDeleteEmail({ currentTarget }) {
    setEmails(prevEmails => {
      prevEmails.splice(prevEmails.indexOf(currentTarget.getAttribute('data-email')), 1);

      return [ ...prevEmails ];
    });
  }

  function onSubmitButtonPress() {
    setSaving(true);

    Api.post(`/teams/${team.token}/seat-users/invited`, { emails }, () => {
      setActiveModal('success');
    });
  }

  return (
    <div className='SeatUserInviteForm'>
      {activeModal === 'success' && (
        <Modal
          title='Invites sent'
          size='m'
          buttons={<ModalButton type='primary' className='pull-right' onClick={onSave}>Got it!</ModalButton>}
          onClose={onSave}
        >
          <div className='SeatUserInviteForm_text'>
            <span>Invitation emails with installation and activation instructions have been sent to your team members. </span>
            <span>You can see their usage statistics once they activate. </span>
            <span>You can go back to being awesome now.</span>
          </div>
        </Modal>
      )}

      {activeModal === 'form' && (
        <Modal
          title={
            <span>
              <span className='SeatUserInviteForm_light-title'>Invite users to </span>
              <span>{team.name}</span>
            </span>
          }
          size='m'
          buttons={<div>
            <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
            <ModalButton type='primary' disabled={saving || emails.length === 0} onClick={onSubmitButtonPress}>Send invite</ModalButton>
          </div>}
          onSubmit={onFormSubmit}
          onClose={onCancel}
        >
          <p className='SeatUserInviteForm_text'>
            <span>Add emails for the license users that you wish to invite to this team. </span>
            <span>They can use the team URL to activate JRebel & XRebel.</span>
            <br /><br />
          </p>

          {showDomainWarning && (
            <Alert type='warn'>
              <AlertEmphasis>Only following email domains are allowed: </AlertEmphasis>
              {whitelistedDomains.list.map(({ domain }) => domain).join(', ')}
            </Alert>
          )}

          <div className='SeatUserInviteForm_email-input'>
            <div className='SeatUserInviteForm_email-field'>
              <InputField
                type='text'
                label='User emails'
                errorMessage={errorMessage}
                placeholder='Add emails, separated by comma or space'
                name='email'
                value={email}
                onChange={onEmailChange}
                autoFocus
                tooltip={{ text: 'Add emails, separated by comma or space', offset: [ 0, -10 ] }}
              />
            </div>
            <ModalButton
              type='primary'
              className='SeatUserInviteForm_email-add-button'
              disabled={whitelistedDomains.fetching || seatUsers.fetching}
              submit
            >
              Add
            </ModalButton>
          </div>

          <div className='SeatUserInviteForm_email-bubbles'>
            {emails.map(email => <Bubble key={email} data-email={email} text={email} onRemove={onDeleteEmail} />)}
          </div>
        </Modal>
      )}
    </div>
  );
}
