import emailValidator from 'email-validator';
import _ from 'lodash';
import { getRedeployTimeValidationMessage, hasJRebelEnabled } from '../selectors';
import { getProductEnabledValidationMessage, getSeatLimitValidationMessage } from './ProductsPanel/selectors';

export function toFormState(team) {
  const form = _.cloneDeep(team);

  form.name = {
    value: team.name,
    errorMessage: null
  };

  form.teamLead = {
    value: team.teamLead,
    disabled: !team.teamLead,
    errorMessage: null
  };

  form.redeployTimeSeconds = team.redeployTimeSeconds;

  return form;
}

export function toRequestParams(form) {
  return {
    token: form.token || null,
    name: form.name.value || null,
    products: form.products.map(product => ({
      productType: product.productType,
      enabled: product.enabled,
      limit: product.enabled ? product.limit : null
    })),
    teamLead: form.teamLead.value || null
  };
}

export function isEdit({ token }) {
  return token;
}

export function hasTeamLeadChanged(form, originalForm) {
  const teamLead = form.teamLead.value;

  return teamLead && originalForm.teamLead.value !== teamLead;
}

export function isTeamLeadEmailValid({ teamLead }) {
  return teamLead.disabled || emailValidator.validate(teamLead.value);
}

export function isTeamLeadAccountAdmin({ teamLead }, adminUsers) {
  return adminUsers
    .filter(({ username }) => username === teamLead.value)
    .some(({ role }) => role === 'ACCOUNT_ADMIN');
}

export function hasRedeployTimeChanged(form, originalForm) {
  return originalForm.redeployTimeSeconds !== form.redeployTimeSeconds;
}

export function isFormValid({ name, teamLead, products, redeployTimeSeconds }) {
  return name.value &&
    (teamLead.disabled || teamLead.value) &&
    (!hasJRebelEnabled(products) || !getRedeployTimeValidationMessage(redeployTimeSeconds)) &&
    products.every(product => !getSeatLimitValidationMessage(products, product)) &&
    products.every(product => !getProductEnabledValidationMessage(products, product));
}

export function isFormValidForTeamLead({ name, redeployTimeSeconds }) {
  return name.value && !getRedeployTimeValidationMessage(redeployTimeSeconds);
}
