import React, { useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';

export default function ResetModal({ username, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onResetButtonPress() {
    setSaving(true);
    Api.post('/admin-users/password-resets', { username }, () => {
      onSuccess(username);
    });
  }

  return (
    <Modal
      title='Reset password'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onResetButtonPress}>
            Reset
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <p>
        <span>We will send an email to <strong>{username}</strong></span>
        <span> with instructions on how to reset your password.</span>
      </p>
    </Modal>
  );
}
