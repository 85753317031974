import { useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';

export default function ResendModal({ user, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onOkButtonPress() {
    setSaving(true);
    Api.post(`/teams/${user.teamToken}/seat-users/invites`, { email: user.email }, () => {
      onSuccess(user.email);
    });
  }

  return (
    <Modal
      title='Resend invite'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onOkButtonPress}>
            Resend
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <p>You are about to resend the invite to <strong>{user.email}</strong>.</p>
      <p>Are you sure?</p>
    </Modal>
  );
}
