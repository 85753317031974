import emailValidator from 'email-validator';
import React, { useEffect, useState } from 'react';
import Alert, { AlertEmphasis } from '../../../components/Alert';
import { InputField } from '../../../components/InputField';
import Modal, { ModalButton } from '../../../components/Modal';
import Api from '../../../utils/Api';
import EnableDomainWhitelistingModal from '../EnableDomainWhitelistingModal';
import { getHavingOtherDomainThanListOrForm } from '../selectors';

const AddWhitelistedDomainForm = ({ onCancel, onSave, domains, addedDomain }) => {
  const [ whitelistedDomains, setWhitelistedDomains ] = useState([]);
  const [ invitedSeatUsers, setInvitedSeatUsers ] = useState([]);
  const [ saving, setSaving ] = useState(false);
  const [ modal, setModal ] = useState('form'); // TODO centralize modal state handling.
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ storedDomain, setStoredDomain ] = useState('');

  useEffect(() => {
    setWhitelistedDomains(domains);
    if (addedDomain) {
      setStoredDomain(addedDomain);
    }
  }, [ addedDomain, domains ]);

  const saveFormAndCloseModal = () => {
    setSaving(true);

    Api.post('/whitelisted-domains', { domain: storedDomain }, onSave);
  };

  const onFormSubmit = e => {
    e.preventDefault();

    if (!emailValidator.validate(`dummy@${storedDomain.replace(/\*/g, 'aa')}`)) {
      setErrorMessage('Please enter a valid email domain.');
      return;
    }

    if (!whitelistedDomains.every(({ domain }) => domain !== storedDomain)) {
      setErrorMessage('This email domain already exists, try another one.');
      return;
    }

    setSaving(true);

    if (whitelistedDomains.length === 0) {
      Api.get('/seat-users/invited', ({ list }) => {
        if (getHavingOtherDomainThanListOrForm(list, whitelistedDomains, storedDomain).length > 0) {
          setModal('invitedSeatUserWarning');
          setInvitedSeatUsers(list);
          setSaving(false);
        }
        else {
          saveFormAndCloseModal();
        }
      });
    }
    else {
      saveFormAndCloseModal();
    }
  };

  const onDomainChange = ({ currentTarget: { value } }) => {
    setStoredDomain(value.toLowerCase());
    setErrorMessage(null);
  };

  return (
    <>
      {modal === 'form' && (
        <Modal
          title='Add domain to whitelist'
          size='m'
          buttons={<div>
            <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
            <ModalButton type='primary' disabled={saving} submit>Add</ModalButton>
          </div>}
          onSubmit={onFormSubmit}
          onClose={onCancel}
        >
          <Alert type='info'>
            <div><AlertEmphasis>Domain names can include asterisks for wildcard matching.</AlertEmphasis></div>
            <div>For example <AlertEmphasis>*yourdomain.com</AlertEmphasis> or <AlertEmphasis>yourdomain.*</AlertEmphasis>.</div>
          </Alert>

          <InputField
            type='text'
            name='domain'
            label='Email domain'
            value={storedDomain}
            errorMessage={errorMessage}
            onChange={onDomainChange}
            autoFocus
          />
        </Modal>
      )}
      {modal === 'invitedSeatUserWarning' && (
        <EnableDomainWhitelistingModal
          onCancel={onCancel}
          onSave={saveFormAndCloseModal}
          domains={whitelistedDomains}
          invitedSeatUsers={invitedSeatUsers}
          addedDomain={storedDomain}
        />
      )}
    </>
  );
};

export default AddWhitelistedDomainForm;
