import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import DateTimeLabel from '../../../../../components/DateTimeLabel';
import Table, { TableCell, TableRow } from '../../../../../components/Table';
import Api from '../../../../../utils/Api';
import User from '../../../../../utils/User';
import './styles.css';
import AddWhitelistedDomainForm from '../../../AddWhitelistedDomainForm';
import DeleteLastWhitelistedDomainModal from './DeleteLastWhitelistedDomainModal';
import DeleteWhitelistedDomainModal from './DeleteWhitelistedDomainModal';

const EmailDomainWhitelistPanel = () => {
  const [ list, setList ] = useState([]);
  const [ fetchingList, setFetchingList ] = useState(true);
  const [ whitelistingEnabled, setWhitelistingEnabled ] = useState(false);
  const [ fetchingWhitelistingEnabled, setFetchingWhitelistingEnabled ] = useState(true);
  const [ storedDomain, setStoredDomain ] = useState('');
  const [ modal, setModal ] = useState('');

  function fetch() {
    Api.get('/account', ({ domainWhitelistingEnabled }) => {
      setWhitelistingEnabled(domainWhitelistingEnabled);
      setFetchingWhitelistingEnabled(false);
    });

    Api.get('/whitelisted-domains', ({ list }) => {
      setList(list);
      setFetchingList(false);
    });
  }

  useEffect(() => {
    fetch();
  }, []);

  const onAddDomainButtonPress = () => {
    setModal('addWhitelistedDomainForm');
  };

  const closeModal = () => {
    setModal('');
  };

  const closeModalAndRefetch = () => {
    closeModal();
    fetch();
  };

  const deleteDomainAndCloseModal = domain => {
    Api.del('/whitelisted-domains', { domain }, closeModalAndRefetch);
  };

  const onDeleteButtonPress = ({ currentTarget }) => {
    if (whitelistingEnabled && list.length === 1) {
      setModal('deleteLastDomainWhitelistingWarning');
      setStoredDomain(currentTarget.getAttribute('data-domain'));
    }
    else if (whitelistingEnabled) {
      setModal('deleteDomainWhitelistingWarning');
      setStoredDomain(currentTarget.getAttribute('data-domain'));
    }
  };

  function sortedDomains() {
    return _(list)
      .sortBy('createdAt')
      .reverse()
      .value();
  }

  return (
    <>
      <div className='EmailDomainWhitelistSettings_add_domain'>
        <div className='EmailDomainWhitelistSettings_details'>
          <b>Email domain whitelist</b>
          <p>Allow users from an additional domain to become part of your organisation</p>
        </div>
        {User.isAccountAdmin() && <Button type='primary' onClick={onAddDomainButtonPress}>Add domain</Button>}
      </div>
      {modal === 'addWhitelistedDomainForm' && (
        <AddWhitelistedDomainForm
          onCancel={closeModal}
          onSave={closeModalAndRefetch}
          domains={list}
        />
      )}
      {modal === 'deleteLastDomainWhitelistingWarning' && (
        <DeleteLastWhitelistedDomainModal onSuccess={() => deleteDomainAndCloseModal(storedDomain)} onCancel={closeModal} domain={storedDomain} />
      )}
      {modal === 'deleteDomainWhitelistingWarning' && (
        <DeleteWhitelistedDomainModal
          onSuccess={() => deleteDomainAndCloseModal(storedDomain)}
          onCancel={closeModal}
          selectedDomain={storedDomain}
          remainingDomains={list.filter(({ domain }) => domain !== storedDomain)}
        />
      )}
      <Card>
        <Table
          header={(
            <TableRow>
              <th className='EmailDomainWhitelist_domain-column'>Domain</th>
              <th className='EmailDomainWhitelist_created-column'>Added</th>
              <th className='EmailDomainWhitelist_actions-column' />
            </TableRow>
          )}
          body={
            <>
              {fetchingList && (
                <TableRow className='EmailDomainWhitelist_fetching'>
                  <TableCell colSpan={3}>Fetching data...</TableCell>
                </TableRow>
              )}
              {list.length === 0 && !fetchingList && !fetchingWhitelistingEnabled && (
                <TableRow className='EmailDomainWhitelist_no-results'>
                  <TableCell colSpan={3}>
                    <div className='EmailDomainWhitelist_no-results-message'>
                      <div className='EmailDomainWhitelist_no-results-image' />
                      <div className='EmailDomainWhitelist_no-results-text'>
                        <h2>No domains added yet</h2>
                        {User.canEdit() && <div>Click Add domain to add some</div>}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {list.length > 0 && !fetchingList && !fetchingWhitelistingEnabled && sortedDomains().map(({ domain, createdAt, createdBy }) => {
                return (
                  <TableRow key={domain}>
                    <TableCell className='EmailDomainWhitelist_domain'>{domain}</TableCell>
                    <TableCell>
                      <DateTimeLabel timestamp={createdAt} />
                      <span className='EmailDomainWhitelist_created-by'> by {createdBy}</span>
                    </TableCell>
                    <TableCell>
                      {User.canEdit() && (
                        <Button type='secondary' className='EmailDomainWhitelist_delete-button' data-domain={domain} onClick={onDeleteButtonPress}>
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          }
        />
      </Card>
    </>
  );
};

export default EmailDomainWhitelistPanel;
