import _ from 'lodash';
import React, { useState } from 'react';
import { ActionsButton } from '../../../components/ActionsButton';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import { DropdownMenuButton } from '../../../components/Dropdown';
import InfoTooltip from '../../../components/InfoTooltip';
import ProductTypeLabel from '../../../components/ProductTypeLabel';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Tooltip from '../../../components/Tooltip';
import { sortByProductType } from '../../../enums/ProductType';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import CopyTextButton from '../../TeamTokenPage/CopyTextButton';
import CornerNotificationPopup from '../CornerNotificationPopup';
import SeatUserInviteForm from '../SeatUserInviteForm';
import TeamForm from '../TeamForm';
import './styles.css';

export default function TeamTable({ onUpdateTeams, teams, fetching, origin, favourites = [], onUpdateFavourite = null, selectedTeams = null, setSelectedTeams = () => {} }) {

  const [ modal, setModal ] = useState({ active: null, team: null });

  function closeModal() {
    setModal({ active: null, team: null });
  }

  function fetch() {
    onUpdateTeams();
  }

  function isAllSelectedTeams() {
    return selectedTeams !== null && selectedTeams.length > 0 && selectedTeams.length === teams.length;
  }

  function isFavouriteTeam(token) {
    return Array.isArray(favourites) && favourites.find(value => value === token);
  }

  function isSelectedTeam(token) {
    return selectedTeams !== null && selectedTeams.includes(token);
  }

  function onChangeCheckboxHeader(event) {
    if (selectedTeams === null) {
      return;
    }
    if (event.target.checked) {
      setSelectedTeams(teams.map(({ token }) => token));
    }
    else {
      setSelectedTeams([]);
    }
  }

  function onChangeCheckboxRow(event, token) {
    if (selectedTeams === null) {
      return;
    }
    if (event.target.checked) {
      setSelectedTeams(prevSelectedTeams => {
        return [ ...prevSelectedTeams, token ];
      });
    }
    else {
      setSelectedTeams(prevSelectedTeams => {
        return prevSelectedTeams.filter(item => item !== token);
      });
    }
  }

  function onEditButtonPress({ currentTarget }) {
    openModalForRow('teamForm', currentTarget.getAttribute('data-token'));
  }

  function onFavouriteTeamButtonPress({ currentTarget }) {
    if (typeof onUpdateFavourite === 'function') {
      const token = currentTarget.getAttribute('data-token');
      onUpdateFavourite(token);
    }
  }

  function onModalSave() {
    closeModal();
    fetch();
  }

  function onModalSaveShowCornerNotification() {
    showCornerNotification();
    fetch();
  }

  function onSeatUserInviteButtonPress({ currentTarget }) {
    const token = currentTarget.getAttribute('data-token');
    openModalForRow('seatUserInviteForm', token);
    Api.post(`/teams/${token}/events/press/users-invite`, { eventUiOrigin: origin, userActionOrigin: 'GUI' });
  }

  function onTeamCopyTeamUrlPress(token) {
    return () => {
      Api.post(`/teams/${token}/events/copy/team-url`, { origin }, () => {
        showCornerNotification();
      });
    };
  }

  function openModalForRow(id, rowToken) {
    setModal({ active: id, team: _(teams).find(({ token }) => token === rowToken) });
  }

  function renderCellCheckbox(token) {
    if (selectedTeams === null) {
      return <></>;
    }
    return (
      <TableCell className='TeamTable_checkbox-cell BulkAction_checkbox-row'>
        <input type='checkbox' checked={isSelectedTeam(token)} onChange={e => onChangeCheckboxRow(e, token)} />
      </TableCell>
    );
  }

  function renderHeaderCheckbox() {
    if (selectedTeams === null) {
      return <></>;
    }
    return (
      <th className='TeamTable_checkbox-column'>
        <input type='checkbox' checked={isAllSelectedTeams()} onChange={e => onChangeCheckboxHeader(e)} />
      </th>
    );
  }

  function renderNoResults() {
    return (
      <div className='TeamTable_no-results_message'>
        <a className='TeamTable_no-results_image' />
        <div className='TeamTable_no-results_text'>
          <h2>No teams added yet</h2>
          <p>Add teams to start distributing your licenses</p>
        </div>
      </div>
    );
  }

  function showCornerNotification() {
    setModal(prevModal => {
      return { ...prevModal, active: 'cornerNotification' };
    });
  }

  return (
    <>
      {modal.active === 'teamForm' && (
        <TeamForm editedTeam={modal.team} onSave={onModalSave} onCancel={closeModal} />
      )}
      {modal.active === 'seatUserInviteForm' && (
        <SeatUserInviteForm team={modal.team} onSave={onModalSaveShowCornerNotification} onCancel={showCornerNotification} />
      )}
      {User.isNPSNotificationEnabled() && modal.active === 'cornerNotification' && (
        <CornerNotificationPopup type='SHOW_FEEDBACK_FORM' origin='TEAM_TABLE' onSave={onModalSave} onCancel={closeModal} />
      )}
      <Card>
        <Table
          className='TeamTable'
          header={
            <TableRow>
              {renderHeaderCheckbox()}
              <th className='TeamTable_name-column'>Team name / URL</th>
              <th className='TeamTable_lead-column'>Team lead</th>
              <th className='TeamTable_product-column'>Products</th>
              <th className='TeamTable_usage-column'>
                <span>Usage</span>
                {teams.length > 0 && teams.some(team => team.products.some(product => product.enabled && product.limit)) && (
                  <InfoTooltip text='Seats in use / Seat limit (if set)' />
                )}
              </th>
              <th className='TeamTable_created-column'>Created</th>
              <th className='TeamTable_actions-column' />
            </TableRow>
          }
          body={teams.length === 0 ?
            <TableRow className='TeamTable_no-results'>
              <TableCell colSpan='7'>
                {fetching ? 'Fetching data...' : renderNoResults()}
              </TableCell>
            </TableRow> :
            teams.map(({ products, token, name, teamLead, createdAt, createdBy }) => {
              return (
                <TableRow key={token} data-token={token}>
                  {renderCellCheckbox(token)}
                  <TableCell className={isFavouriteTeam(token) ? 'favourite' : ''}>
                    <div>
                      {User.canEdit() && (
                        <button type='button' className='TeamTable_team-name-button' data-token={token} onClick={onEditButtonPress}>
                          {name}
                        </button>
                      )}
                      {!User.canEdit() && (
                        <span className='TeamTable_team-name-label'>{name}</span>
                      )}
                    </div>
                    <div>
                      <CopyTextButton
                        component='button'
                        text={`${window.location.origin}/${token}`}
                        copiedTooltip={{ offset: [ 0, 10 ] }}
                        hoverTooltip={{ text: 'Click to copy your Team URL', offset: [ 0, -10 ] }}
                        className='TeamTable_team-url-button text-code'
                        onPress={onTeamCopyTeamUrlPress(token)}
                      >
                        {`${window.location.origin}/${token}`}
                      </CopyTextButton>
                    </div>
                    <i className='icon icon-favourite' aria-hidden='true' />
                  </TableCell>
                  <TableCell>
                    {!User.isTeamLead() && (
                      <span className='TeamTable_team-lead-label'>{teamLead}</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {sortByProductType(products).filter(({ enabled }) => enabled).map(({ productType }) => {
                      return <div key={productType}><ProductTypeLabel id={productType} /></div>;
                    })}
                  </TableCell>
                  <TableCell>
                    {sortByProductType(products).filter(({ enabled }) => enabled).map(({ productType, usage, limit }) => {
                      if (limit) {
                        return <div key={productType}>{usage} / {limit}</div>;
                      }
                      return <div key={productType}>{usage}</div>;
                    })}
                  </TableCell>
                  <TableCell>
                    <Tooltip offset={[ -10, -10 ]} text={<>Created at <DateTimeLabel timestamp={createdAt} displayAsTimestamp /><br /> by {createdBy}</>}>
                      <div>
                        <DateTimeLabel timestamp={createdAt} displayAsFromNow withJustNow />
                        <div className='TeamTable_created-label'>{`by ${createdBy}`}</div>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <ActionsButton>
                      {User.canEdit() && (
                        <DropdownMenuButton data-token={token} onPress={onEditButtonPress}>
                          Edit team
                        </DropdownMenuButton>
                      )}
                      <DropdownMenuButton routeTo={{ path: '/users', parameters: { teamName: name }, state: { category: 'TEAM' } }}>
                        View users
                      </DropdownMenuButton>
                      {User.canEdit() && (
                        <DropdownMenuButton data-token={token} onPress={onSeatUserInviteButtonPress}>
                          Invite users
                        </DropdownMenuButton>
                      )}
                      <DropdownMenuButton data-token={token} onPress={onFavouriteTeamButtonPress}>
                        {isFavouriteTeam(token) ? 'Remove' : 'Set'} as favourite
                      </DropdownMenuButton>
                    </ActionsButton>
                  </TableCell>
                </TableRow>
              );
            })
          }
        />
      </Card>
    </>
  );
}
