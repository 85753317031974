import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal, { ModalButton } from '../../../../../components/Modal';
import './styles.css';

const DeleteLastWhitelistedDomainModal = ({ onCancel, onSuccess, domain }) => {
  const [ saving, setSaving ] = useState(false);

  const onDeleteButtonPress = () => {
    setSaving(true);

    onSuccess();
  };

  return (
    <Modal
      title='Delete domain & turn off whitelist'
      size='m'
      buttons={<div>
        <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
        <ModalButton type='danger' disabled={saving} onClick={onDeleteButtonPress}>Delete domain & turn off whitelist</ModalButton>
      </div>}
      onClose={onCancel}
    >
      <p>
        Deleting <strong>{domain}</strong> domain will also turn off the whitelist.<br />
        Activation will be allowed with all email addresses.
      </p>
      <p className='DeleteLastWhitelistedDomainModal_confirmation-text'>Are you sure?</p>
    </Modal>
  );
};

DeleteLastWhitelistedDomainModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired
};
export default DeleteLastWhitelistedDomainModal;
