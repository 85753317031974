import Card from '../../../../components/Card';
import Api from '../../../../utils/Api';
import _ from 'lodash';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import React, { Fragment, useEffect, useState } from 'react';
import User from '../../../../utils/User';
import { notificationNameLabels, reminderNameLabels } from '../../labels';
import Toggle from '../../../../components/Toggle';
import Button from '../../../../components/Button';
import './styles.css';

function getNotificationAlertText(notificationName, enabled) {
  return enabled ?
    `We will send you an email on "${notificationNameLabels[notificationName] || notificationName}".` :
    `We will not email you about "${notificationNameLabels[notificationName] || notificationName}" anymore.`;
}

function getReminderAlertText(reminderName, enabled) { // TODO alert text emphasis/boldness
  return enabled ?
    `We will send you an email on "${reminderNameLabels[reminderName] || reminderName}".` :
    `We will not email you about "${reminderNameLabels[reminderName] || reminderName}" anymore.`;
}

const UserSettingsTab = ({ setPageAlert, setPageModal }) => {
  /* const { origin } = useParams(); // TODO does it need to be defined in Route path as well? */
  const [ accountStatus, setAccountStatus ] = useState();
  const [ fetchingAccountStatus, setFetchingAccountStatus ] = useState(true);
  const [ notificationSettings, setNotificationSettings ] = useState([]); // TODO would be nice to have typing defined for the lists.
  const [ fetchingNotificationSettings, setFetchingNotificationSettings ] = useState(true);
  const [ reminderSettings, setReminderSettings ] = useState([]); // ugly ahh double up with notifications, can we do something about it?
  const [ fetchingReminderSettings, setFetchingReminderSettings ] = useState(true);
  const [ alert, setAlert ] = useState('');

  function fetch() {
    if (User.canEdit()) {
      Api.get('/account', ({ status }) => {
        setAccountStatus(status);
        setFetchingAccountStatus(false);
      });
      Api.get('/admin-users/email-notification-settings', ({ list }) => {
        setNotificationSettings(_(list).sortBy('notificationName').value());
        setFetchingNotificationSettings(false);
      });
      Api.get('/admin-users/email-reminder-settings', ({ list }) => {
        setReminderSettings(_(list).sortBy('reminderName').value());
        setFetchingReminderSettings(false);
      });
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setPageAlert(alert);
  }, [ alert, setPageAlert ]);

  /* useEffect(() => {
    Api.post('/admin-users/events/open/email-notification-settings', { origin: origin || 'GUI' });
  }, []); */

  const toggleSingleNotification = (notificationName, enabled) => {
    Api.put('/admin-users/email-notification-settings', { list: [ { notificationName, enabled } ], origin: 'GUI' }, () => {
      fetch();
      setAlert(getNotificationAlertText(notificationName, enabled));
    });
  };

  const toggleSingleReminder = (reminderName, enabled) => {
    Api.put('/admin-users/email-reminder-settings', { list: [ { reminderName, enabled } ], origin: 'GUI' }, () => {
      fetch();
      setAlert(getReminderAlertText(reminderName, enabled));
    });
  };

  const anySettingsDisabled = () => {
    return (notificationSettings && reminderSettings) &&
      (notificationSettings.some(({ enabled }) => !enabled) || reminderSettings.some(({ enabled }) => !enabled));
  };

  const toggleAll = () => {
    const anyDisabled = anySettingsDisabled();
    const notificationSettingsChanged = _(notificationSettings)
      .map(({ notificationName }) => ({ notificationName, enabled: anyDisabled }))
      .value();
    const reminderSettingsChanged = _(reminderSettings)
      .map(({ reminderName }) => ({ reminderName, enabled: anyDisabled }))
      .value();

    Api.put('/admin-users/email-notification-settings', { list: notificationSettingsChanged, origin: 'GUI' }, () => {
      Api.put('/admin-users/email-reminder-settings', { list: reminderSettingsChanged, origin: 'GUI' }, () => {
        fetch();
        setAlert(anyDisabled ? 'All email notifications enabled.' : 'All notifications disabled. We will not send you emails about any notifications.');
      });
    });
  };

  function renderEmailNotificationTable() {
    return (
      <Card>
        <Table
          header={
            <TableRow>
              <th className='AdminUserSettings_event-name'>Event</th>
              <th className='AdminUserSettings_toggle_all'>
                <Toggle
                  enabled={!anySettingsDisabled()}
                  onChange={toggleAll}
                  className='pull-right'
                />
              </th>
            </TableRow>
          }
          body={fetchingNotificationSettings || fetchingReminderSettings || fetchingAccountStatus ?

            <TableRow className='AdminUserSettings_fetching'>
              <TableCell colSpan='2'>
                Fetching data...
              </TableCell>
            </TableRow> :
            <Fragment>
              {reminderSettings
                .filter(reminder => reminder.reminderName !== 'ACTIVATE_ACCOUNT')
                .filter(({ reminderName }) => !User.isTeamLead() || reminderName === 'INVITE_USERS')
                .map(({ reminderName, enabled }) => {
                  return (
                    <TableRow key={reminderName}>
                      <TableCell>
                        {reminderNameLabels[reminderName] || reminderName}
                      </TableCell>
                      <TableCell>
                        <Toggle
                          enabled={enabled}
                          onChange={enabled => toggleSingleReminder(reminderName, enabled)}
                          className='pull-right'
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              {User.isAccountAdmin() && notificationSettings
                .filter(({ notificationName }) => accountStatus !== 'EVALUATION_ONGOING' || notificationName !== 'INVALID_SEAT_USER_DOMAIN')
                .filter(({ notificationName }) => accountStatus !== 'EVALUATION_ENDED' || notificationName !== 'INVALID_SEAT_USER_DOMAIN')
                .map(({ notificationName, enabled }) => {
                  return (
                    <TableRow key={notificationName}>
                      <TableCell>
                        {notificationNameLabels[notificationName] || notificationName}
                      </TableCell>
                      <TableCell>
                        <Toggle
                          enabled={enabled}
                          onChange={enabled => toggleSingleNotification(notificationName, enabled)}
                          className='pull-right'
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </Fragment>
          }
        />
      </Card>
    );
  }

  return (
    <div className='AdminUserSettingsTab'>
      {!User.isTeamLead() && <div className='AdminUserResetPassword'>
        <div className='AdminUserResetPassword_details'>
          <b>Reset password</b>
          <p>Reset your Rebel Licenses password</p>
        </div>
        <Button type='primary' onClick={() => setPageModal('reset')}>Reset password</Button>
      </div>}
      {/* <div className='DefaultTeamsView'>
        <div className='DefaultTeamsView_details'>
          <b>Default teams view</b>
          <p>Select the default view for the teams section on the dashboard</p>
        </div>
        <NewSelectField // TODO functionality CLS-820
          value={{ value: 'Latest teams', label: 'Latest teams' }}
          options={[
            { value: 'Latest teams', label: 'Latest teams' },
            { value: 'Favourite teams', label: 'Favourite teams' },
            { value: 'Largest teams', label: 'Largest teams' } ]}
        />
      </div> */}
      {User.canEdit() && <div className='AdminUserEmailNotificationSettings'>
        <div className='AdminUserEmailNotificationSettings_details'>
          <b>Email Notifications</b>
          <p>Select which JRebel updates would you like notification for</p>
        </div>
        {renderEmailNotificationTable()}
      </div>
      }
    </div>
  );
};

export default UserSettingsTab;
