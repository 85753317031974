import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Alert from '../../../../components/Alert';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import { InputField } from '../../../../components/InputField';
import LicenseTypeLabel from '../../../../components/LicenseTypeLabel';
import Modal, { ModalButton } from '../../../../components/Modal';
import SelectField from '../../../../components/SelectField';
import UnitLabel from '../../../../components/UnitLabel';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import { getAvailableSeatCount, isInTheFuture } from '../selectors';
import { filterAdminsAllowedToExport, getSeatCountErrorMessage, isFormValid } from './selectors';
import './styles.css';

export default function ExportManagedForm({ license, onSave, onCancel }) {

  const [ activeModal, setActiveModal ] = useState('form');
  const [ adminUsers, setAdminUsers ] = useState({ list: [], fetching: true });
  const [ seatUsage, setSeatUsage ] = useState({ items: [], fetching: true });
  const [ form, setForm ] = useState({ exportedTo: '', firstName: '', lastName: '', seatCount: '' });
  const [ saving, setSaving ] = useState(false);

  useEffect(() => {
    Api.get('/admin-users', ({ list }) => {
      setAdminUsers({ list, fetching: false });
      setForm(prevForm => {
        return { ...prevForm, exportedTo: getDefaultExportedTo(list) };
      });
    });

    Api.get('/statistics', ({ seatUsage }) => {
      setSeatUsage({ items: seatUsage, fetching: false });
      setForm(prevForm => {
        return { ...prevForm, seatCount: getAvailableSeatCount(seatUsage, license) };
      });
    });
  }, [ license ]);

  function getDefaultExportedTo(list) {
    const adminUsernamesAllowedToExport = filterAdminsAllowedToExport(list).map(({ username }) => username);

    if (adminUsernamesAllowedToExport.includes(User.getUsername())) {
      return User.getUsername();
    }

    if (adminUsernamesAllowedToExport.length > 0) {
      return adminUsernamesAllowedToExport[0];
    }

    return '';
  }

  function onConfirmButtonPress() {
    setSaving(true);

    Api.post(
      '/licenses/exported/managed',
      { exportedTo: { email: form.exportedTo, firstName: form.firstName, lastName: form.lastName }, seatCount: form.seatCount, orderItemId: license.orderItemId },
      () => onSave(form.exportedTo)
    );
  }

  function onFormSubmit(e) {
    e.preventDefault();

    setActiveModal('confirmation');
  }

  function onFieldChange({ currentTarget: { name, value } }) {
    setForm(prevForm => {
      return { ...prevForm, [name]: isNaN(parseInt(value, 10)) ? value : parseInt(value, 10) };
    });
  }

  function renderTitle() {
    return (
      <span>
        <span className='ExportManagedForm_light-title'>Export </span>
        <LicenseTypeLabel id={license.type} />
        <span className='ExportManagedForm_light-title'> licenses<br />for On-Premise (License Server)</span>
      </span>
    );
  }

  return (
    <div className='ExportManagedForm'>
      {activeModal === 'confirmation' && (
        <Modal
          title={renderTitle()}
          size='m'
          buttons={
            <ModalButton type='primary' disabled={saving} className='pull-right' onClick={onConfirmButtonPress}>Export</ModalButton>
          }
          onClose={onCancel}
        >
          <div className='ExportManagedForm_text'>
            Exported licenses will be sent to <strong>{form.exportedTo}</strong>.
            <br />
            <br />
            If this is correct, press Export.
          </div>
        </Modal>
      )}

      {activeModal === 'form' && !seatUsage.fetching && !adminUsers.fetching && (
        <Modal
          title={renderTitle()}
          size='l'
          buttons={
            /* TODO CLS-789 Add cancel button in issue CLS-831 */
            <ModalButton type='primary' disabled={!isFormValid(form, seatUsage.items, license)} submit>Export</ModalButton>
          }
          onSubmit={onFormSubmit}
          onClose={onCancel}
          confirmClose={true}
        >
          <p>
            You are about to export licenses for Rebel Licenses On-Premise (License Server).
            A bulk license file will be sent to the administrator email specified.
            These licenses are usable only during the license validity period.
          </p>
          <Alert type='info'>
            This action can only be reverted by contacting <a href='mailto:sales@perforce.com'>sales@perforce.com</a>.
          </Alert>
          <div className='ExportManagedForm_available-seats'>
            <UnitLabel valueClass='ExportManagedForm_available-seats_number' value={getAvailableSeatCount(seatUsage.items, license)} unit='seat' />
            <span> available for </span>
            <LicenseTypeLabel id={license.type} />
            {isInTheFuture(license.startDate) && <span> from <DateTimeLabel timestamp={license.startDate} /></span>}
            <span> until </span><DateTimeLabel timestamp={license.endDate} />
          </div>
          <div className={classNames('ExportManagedForm_fields', { hide: getAvailableSeatCount(seatUsage.items, license) === 0 })}>
            <SelectField // TODO CLS-789 Update select field in issue CLS-831
              label='Select administrator who will receive an email with the license file'
              name='exportedTo'
              value={form.exportedTo}
              onChange={onFieldChange}
            >
              {filterAdminsAllowedToExport(adminUsers.list).map(({ username }, i) => <option key={i} value={username}>{username}</option>)}
            </SelectField>
            <InputField
              type='text'
              label='First name'
              name='firstName'
              value={form.firstName}
              onChange={onFieldChange}
              autoFocus
            />
            <InputField
              type='text'
              label='Last name'
              name='lastName'
              value={form.lastName}
              onChange={onFieldChange}
            />
            <InputField
              type='number'
              label='Number of seats'
              name='seatCount'
              value={form.seatCount}
              min={1}
              max={getAvailableSeatCount(seatUsage.items, license)}
              step={1}
              onChange={onFieldChange}
              errorMessage={getSeatCountErrorMessage(form.seatCount, seatUsage.items, license)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
