import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Page from '../../components/Page';
import ProductTypeLabel from '../../components/ProductTypeLabel';
import SelectField from '../../components/SelectField';
import { sortProductTypes } from '../../enums/ProductType';
import Api from '../../utils/Api';
import { formatAsHumanReadableList } from '../selectors';
import CopyTextButton from './CopyTextButton';
import ExpandableAlert from './ExpandableAlert';
import './styles.css';

const ides = {
  JREBEL: [
    { id: 'eclipse', name: 'Eclipse' },
    { id: 'intellij', name: 'IntelliJ IDEA' },
    { id: 'netbeans', name: 'Netbeans' },
    { id: 'myeclipse', name: 'MyEclipse' },
    { id: 'ibm-rad', name: 'Rational Application Developer' },
    { id: 'oracle-jdeveloper', name: 'Oracle JDeveloper' },
    { id: 'jrebel-standalone', name: 'JRebel Standalone' }
  ],
  XREBEL: [
    { id: 'standalone', name: 'Standalone', quickStartKey: 'xrebel-standalone' },
    { id: 'eclipse', name: 'Eclipse', quickStartKey: 'eclipse' }
  ]
};

export default class TeamTokenPage extends Component {

  static propTypes = {
    token: PropTypes.string.isRequired
  };

  state = {
    licensedProducts: [],
    fetching: true,
    selectedTabProductType: null,
    selectedIde: null
  };

  componentDidMount() {
    Api.post(`/teams/${this.props.token}/events/visit/token-page`);

    Api.get(`/teams/${this.props.token}/licensed-products`, ({ list }) => {
      const sortedProducts = sortProductTypes(list);

      this.setState({
        licensedProducts: sortedProducts,
        fetching: false,
        selectedTabProductType: sortedProducts[0],
        selectedIde: ides[sortedProducts[0]][0]
      });
    });
  }

  onQuickStartLinkPress = () => {
    Api.post(`/teams/${this.props.token}/events/press/quick-start`, { product: this.state.selectedTabProductType });
  };

  onTabPress = ({ currentTarget }) => {
    const selectedTabProductType = currentTarget.getAttribute('data-product-type');

    this.setState({ selectedTabProductType, selectedIde: ides[selectedTabProductType][0] });
  };

  onEnvironmentChange = ({ currentTarget: { value } }) => {
    this.setState(({ selectedTabProductType }) => ({ selectedIde: ides[selectedTabProductType].find(({ id }) => id === value) }));
  };

  onTeamCopyTeamUrlPress = origin => {
    return () => {
      Api.post(`/teams/${this.props.token}/events/copy/team-url`, { origin });
    };
  };

  renderCopyUrlBox = () => {
    return (
      <CopyTextButton
        component='div'
        text={`${window.location.origin}/${this.props.token}`}
        copiedTooltip={{ offset: [ 258, 12 ] }}
        className='TeamTokenPage_tab-content_team-url'
        onPress={this.onTeamCopyTeamUrlPress('TOKEN_PAGE')}
      >
        <Fragment>
          <div className='TeamTokenPage_tab-content_team-url_link text-code'>
            {`${window.location.origin}/${this.props.token}`}
          </div>
          <Button type='primary' className='TeamTokenPage_tab-content_team-url_button'>Copy URL</Button>
        </Fragment>
      </CopyTextButton>
    );
  };

  renderCopyTeamUrlLink = () => {
    return (
      <CopyTextButton
        component='button'
        text={`${window.location.origin}/${this.props.token}`}
        copiedTooltip={{ offset: [ 0, 10 ] }}
        hoverTooltip={{ text: 'Click to copy your Team URL', offset: [ 0, -5 ] }}
        className='TeamTokenPage_tab-content_copyable-url Button link'
        onPress={this.onTeamCopyTeamUrlPress('TOKEN_PAGE_ACTIVATION_INSTRUCTIONS')}
      >
        Team URL
      </CopyTextButton>
    );
  };

  renderCopyLink = (url, label) => {
    return (
      <CopyTextButton
        component='a'
        text={url}
        copiedTooltip={{ offset: [ 0, 10 ] }}
        hoverTooltip={{ text: 'Click to copy URL', offset: [ 0, -5 ] }}
        className='TeamTokenPage_tab-content_copyable-url Button link'
      >
        {label}
      </CopyTextButton>
    );
  };

  renderQuickStartLink = () => {
    let url;

    if (this.state.selectedTabProductType === 'JREBEL') {
      url = `https://jrebel.com/products/jrebel/quickstart/${this.state.selectedIde.id}/`;
    }
    else {
      url = `https://jrebel.com/products/xrebel/quickstart/${this.state.selectedIde.quickStartKey}/`;
    }

    return (
      <a
        href={url}
        rel='noopener noreferrer'
        target='_blank'
        onClick={this.onQuickStartLinkPress}
      >
        {ProductTypeLabel.asString(this.state.selectedTabProductType)} Quick Start
      </a>
    );
  };

  renderInstallationSteps = () => {
    const { selectedTabProductType, selectedIde: { id, name } } = this.state;

    if (selectedTabProductType === 'JREBEL') {
      switch (id) {
      case 'eclipse':
        return (
          <ol>
            <li>Open <b>Help &gt; Eclipse Marketplace</b>.</li>
            <li>Search for <b>JRebel</b>. Found it? Press <b>Install</b>. <b>{name}</b> will restart.</li>
            <li><b>JRebel</b> installation success notification will be shown. <b>JRebel</b> menu will appear under <b>Help</b>.</li>
          </ol>
        );
      case 'intellij':
        return (
          <ol>
            <li>Access <b>Settings</b> (<b>Preferences</b> on <b>Mac OS</b>). Select <b>Plugins</b>.</li>
            <li>Press <b>Browse Repositories</b>.</li>
            <li>Find <b>JRebel</b>. Press <b>Install plugin</b>. <b>{name}</b> will restart.</li>
            <li><b>JRebel</b> installation success notification will be shown. <b>JRebel</b> menu will appear under <b>Help</b>.</li>
          </ol>
        );
      case 'netbeans':
        return (
          <ol>
            <li>Open <b>Tools &gt; Plugins</b>.</li>
            <li>Select the <b>Available Plugins</b> tab.</li>
            <li>Find <b>JRebel</b> and press <b>Install</b>. <b>{name}</b> will restart.</li>
            <li><b>JRebel</b> installation success notification will be shown. <b>JRebel</b> menu will appear under <b>Help</b>.</li>
          </ol>
        );
      case 'myeclipse':
      case 'ibm-rad':
        return (
          <ol>
            <li>
              Follow the instructions on{' '}
              <a href='http://update.zeroturnaround.com/update-site/' rel='noopener noreferrer' target='_blank'>update.zeroturnaround.com/update-site</a>.
            </li>
            <li><b>{name}</b> will restart.</li>
            <li><b>JRebel</b> installation success notification will be shown. <b>JRebel</b> menu will appear under <b>Help</b>.</li>
          </ol>
        );
      case 'oracle-jdeveloper':
        return (
          <ol>
            <li>Start <b>JDeveloper</b> in <b>Studio Developer (All Features)</b> role (whenever you use JRebel).</li>
            <li>
              Download the latest <b>JRebel plugin archive</b> from{' '}
              <a href='https://dl.zeroturnaround.com/jrebel/jdeveloper/' rel='noopener noreferrer' target='_blank'>dl.zeroturnaround.com/jrebel/jdeveloper</a>.
              <Alert type='info' className='TeamTokenPage_tab-content_instruction-list_inline-alert'>
                <div className='TeamTokenPage_tab-content_alert-title'>Pick the right version</div>
                <ul>
                  <li><b>jr-ide-jdeveloper-*-11g-R2-12c.zip</b> if you're using <b>11g-R2 or newer</b>.</li>
                  <li><b>jr-ide-jdeveloper-*-11g-R1.zip</b> if you're using <b>11g-R1</b>.</li>
                </ul>
              </Alert>
            </li>
            <li>Open <b>Help &gt; Check for Updates</b> and select <b>Install From Local File</b>.</li>
            <li>Browse to the downloaded ZIP and finalize the installation. <b>{name}</b> will restart.</li>
          </ol>
        );
      case 'jrebel-standalone':
        return (
          <ol>
            <li>Download the <a href='https://jrebel.com/products/jrebel/download#i-have-a-license' rel='noopener noreferrer' target='_blank'>JRebel Standalone ZIP</a>.</li>
            <li>
              Unpack the archive to a folder of your choice.
              <Alert type='info' className='TeamTokenPage_tab-content_instruction-list_inline-alert'><b>Choose a path that doesn't contain spaces.</b></Alert>
            </li>
          </ol>
        );
      default:
        return null;
      }
    }

    if (id === 'standalone') {
      return (
        <ol>
          <li>
            <a href='https://jrebel.com/products/xrebel/download/' rel='noopener noreferrer' target='_blank'>Download XRebel</a> and unpack the archive to a folder of your choice.
          </li>
          <li>
            Add <b>XRebel</b> to your startup parameters:<br />
            <span className='TeamTokenPage_monospace'>-javaagent:[/path/to/]xrebel.jar</span>
          </li>
          <li>Start your server!</li>
          <li>
            A <b>console banner</b> is shown for a successful launch:
            <div className='TeamTokenPage_monospace'>
              XRebel {'{'}version number{'}'}<br />
              © 2025 Perforce Software, Inc.<br />
              For questions and support, contact support-rebel@perforce.com
            </div>
          </li>
          <li>
            Go to the URL of your web app, you should see the{' '}
            <a
              href='https://manuals.jrebel.com/xrebel/use/'
              rel='noopener noreferrer'
              target='_blank'
            >
              XRebel toolbar
            </a>{' '}
            in the bottom left corner.
          </li>
        </ol>
      );
    }

    return (
      <ol>
        <li>Open <b>Help &gt; Eclipse Marketplace</b>.</li>
        <li>Search for <b>XRebel</b>. Found it? Press <b>Install</b>. <b>{name}</b> will restart.</li>
        <li><b>XRebel</b> welcome pane will be shown on the right in your IDE.</li>
        <li><b>XRebel for Eclipse</b> is <b>automatically enabled</b> for all supported application servers.</li>
        <li>
          A <b>console banner</b> is shown for a successful launch:
          <div className='TeamTokenPage_monospace'>
            XRebel {'{'}version number{'}'}<br />
            © 2025 Perforce Software, Inc.<br />
            For questions and support, contact support-rebel@perforce.com
          </div>
        </li>
        <li>
          Go to the URL of your web app, you should see the{' '}
          <a
            href='https://manuals.jrebel.com/xrebel/use/'
            rel='noopener noreferrer'
            target='_blank'
          >
            XRebel toolbar
          </a>{' '}
          in the bottom left corner.
        </li>
      </ol>
    );
  };

  renderActivationSteps = () => {
    const { selectedTabProductType, selectedIde: { id } } = this.state;

    if (selectedTabProductType === 'JREBEL') {
      switch (id) {
      case 'eclipse':
      case 'intellij':
      case 'netbeans':
      case 'myeclipse':
      case 'ibm-rad':
        return (
          <ol>
            <li>Open <b>Help &gt; JRebel &gt; Activation</b>.</li>
            <li>Select <b>Team eval or commercial license</b> tab.</li>
            <li>Pick the <b>Team URL</b> option.</li>
            <li>Enter your {this.renderCopyTeamUrlLink()} and your <b>email</b>.</li>
            <li>Press <b>Activate JRebel</b>.</li>
            <li>JRebel activation success notification will be shown. See your license status from <b>Help &gt; JRebel &gt; Activation</b>.</li>
          </ol>
        );
      case 'oracle-jdeveloper':
        return (
          <ol>
            <li>Open <b>Tools &gt; Preferences</b> (<b>JDeveloper &gt; Preferences</b> on <b>macOS</b>).</li>
            <li>Find <b>JRebel</b> and press <b>Load Extension</b>.</li>
            <li>Press <b>Activate now</b>.</li>
            <li>Enter your {this.renderCopyTeamUrlLink()} and your <b>email</b>.</li>
            <li>Press <b>Activate</b>.</li>
            <li>JRebel activation success notification will be shown.</li>
          </ol>
        );
      case 'jrebel-standalone':
        return (
          <ol>
            <li>
              Find the <span className='TeamTokenPage_monospace'>jrebel-activation.jar</span> file in
              the <span className='TeamTokenPage_monospace'>bin</span> folder, right where you extracted <b>JRebel</b>.
            </li>
            <li><b>Run it</b> with <span className='TeamTokenPage_monospace'>java -jar jrebel-activation.jar</span>.</li>
            <li>Select <b>Team eval or commercial license</b> tab.</li>
            <li>Pick the <b>Team URL</b> option.</li>
            <li>Enter your {this.renderCopyTeamUrlLink()} and your <b>email</b>.</li>
            <li>Press <b>Activate JRebel</b>.</li>
          </ol>
        );
      default:
        return null;
      }
    }

    return (
      <ol>
        <li>The activation dialog pops up automatically over your web app after you have completed <b>XRebel</b> Install & Start up.</li>
        <li>Press <b>I have a license</b>.</li>
        <li>Pick the <b>Team URL</b> option.</li>
        <li>Enter your {this.renderCopyTeamUrlLink()} and your <b>email</b>.</li>
        <li>Press <b>Activate license</b>.</li>
      </ol>
    );
  };

  renderGoodToKnowAlert = () => {
    const { selectedTabProductType, selectedIde: { id } } = this.state;

    if (selectedTabProductType === 'JREBEL' && (id === 'myeclipse' || id === 'ibm-rad')) {
      return (
        <Alert type='info' className='TeamTokenPage_tab-content_good-to-know-alert'>
          <div className='TeamTokenPage_tab-content_alert-title'>Good to know</div>
          Avoid installing the JRebel plugin using the marketplace as this may produce a non-functioning installation.
        </Alert>
      );
    }

    if (selectedTabProductType === 'XREBEL') {
      if (id === 'standalone') {
        return (
          <Alert type='info' className='TeamTokenPage_tab-content_good-to-know-alert'>
            <div className='TeamTokenPage_tab-content_alert-title'>Good to know</div>
            <ul>
              <li>
                Don't know how to configure your server parameters?{' '}
                <a
                  href='https://manuals.jrebel.com/xrebel/install/#adding-xrebel-to-your-server'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Follow these instructions
                </a>.
              </li>
              <li>
                Want to know whether XRebel supports your browser, server or database? Check out all{' '}
                <a
                  href='https://manuals.jrebel.com/xrebel/support/#xrebel-support'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  supported environments
                </a>.
              </li>
            </ul>
          </Alert>
        );
      }

      return (
        <Alert type='info' className='TeamTokenPage_tab-content_good-to-know-alert'>
          <div className='TeamTokenPage_tab-content_alert-title'>Good to know</div>
          To review Startup configuration, open <b>Window &gt; Show View &gt; Servers</b>. Locate your desired <b>Run Configuration</b> and check the <b>XRebel integration</b> tab.
        </Alert>
      );
    }

    return null;
  };

  renderDidNotWorkAlert = () => {
    const { selectedTabProductType, selectedIde: { id } } = this.state;

    if (selectedTabProductType === 'JREBEL') {
      switch (id) {
      case 'eclipse':
        return (
          <ExpandableAlert title="Didn't work?" className='TeamTokenPage_tab-content_did-not-work-alert'>
            <p>Don't worry, you can also install <b>JRebel</b> using:</p>
            <ul>
              <li>
                <b>Update site URL</b>. Open <b>Help &gt; Install New Software</b> and enter the URL for our update site:{' '}
                {this.renderCopyLink('http://update.zeroturnaround.com/update-site/', 'update.zeroturnaround.com/update-site')}.
                We recommend you uncheck the option for <b>Contact all update sites during install to find required software</b>. Follow the prompts
                to complete the installation.
              </li>
              <li>
                <b>Download ZIP</b>. The Eclipse plugin update can also be downloaded as ZIP archive from{' '}
                <a
                  href='https://update.zeroturnaround.com/update-site/update-site.zip'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  update.zeroturnaround.com/update-site/update-site.zip
                </a>.
                Once downloaded, open <b>Help &gt; Install New Software</b> and press <b>Add</b>. Select <b>Archive</b> to point to the ZIP archive
                of the update site. Press <b>OK</b> to proceed.
              </li>
            </ul>
            When you do not have m2eclipse installed, uncheck the <b>JRebel m2eclipse plugin</b> during installation.
          </ExpandableAlert>
        );
      case 'intellij':
        return (
          <ExpandableAlert title="Didn't work?" className='TeamTokenPage_tab-content_did-not-work-alert'>
            You can also get the <b>JRebel</b> plugin from{' '}
            <a
              href='https://plugins.jetbrains.com/plugin/4441-jrebel-for-intellij'
              rel='noopener noreferrer'
              target='_blank'
            >
              plugins.jetbrains.com/plugin/4441-jrebel-for-intellij
            </a>.
            Once downloaded, open <b>Plugins</b> and press <b>Install plugin from disk</b>. Browse to the downloaded file and follow the prompts.
          </ExpandableAlert>
        );
      case 'netbeans':
        return (
          <ExpandableAlert title="Didn't work?" className='TeamTokenPage_tab-content_did-not-work-alert'>
            You can also get the <b>JRebel</b> plugin from{' '}
            <a href='http://plugins.netbeans.org/plugin/22254/' rel='noopener noreferrer' target='_blank'>plugins.netbeans.org/plugin/22254</a>.
            Once downloaded, open <b>Tools &gt; Plugins</b> and find the <b>Downloaded</b> tab. Press <b>Add Plugins</b>, browse to the downloaded file and press <b>Install</b>.
          </ExpandableAlert>
        );
      case 'myeclipse':
      case 'ibm-rad':
        return (
          <ExpandableAlert title="Didn't work?" className='TeamTokenPage_tab-content_did-not-work-alert'>
            You can also get the <b>JRebel</b> plugin by <b>downloading the ZIP archive</b> from{' '}
            <a
              href='https://update.zeroturnaround.com/update-site/update-site.zip'
              rel='noopener noreferrer'
              target='_blank'
            >
              update.zeroturnaround.com/update-site/update-site.zip
            </a>.
            Once downloaded, open <b>Help &gt; Install New Software</b> and press <b>Add</b>. Select <b>Archive</b> to point to the ZIP archive of the update site.
            Press <b>OK</b> to proceed.
          </ExpandableAlert>
        );
      default:
        return null;
      }
    }

    if (id === 'eclipse') {
      return (
        <ExpandableAlert title="Didn't work?" className='TeamTokenPage_tab-content_did-not-work-alert'>
          <p>Don't worry, you can also install <b>XRebel for Eclipse</b> using:</p>
          <ul>
            <li>
              <b>Update site URL</b>. Open <b>Help &gt; Install New Software</b> and enter the URL for our update site:{' '}
              {this.renderCopyLink('http://update.zeroturnaround.com/xrebel/update-site/', 'update.zeroturnaround.com/xrebel/update-site')}.
              Follow the prompts to complete the installation.
            </li>
            <li>
              <b>Download ZIP</b>. The plugin can also be downloaded as a ZIP archive from{' '}
              <a
                href='http://update.zeroturnaround.com/xrebel/update-site/update-site.zip'
                rel='noopener noreferrer'
                target='_blank'
              >
                update.zeroturnaround.com/xrebel/update-site/update-site.zip
              </a>.
              Once downloaded, open <b>Help &gt; Install New Software</b> and press <b>Add</b>. Select <b>Archive</b> to point to the ZIP archive
              of the update site. Press <b>OK</b> to proceed.
            </li>
          </ul>
        </ExpandableAlert>
      );
    }

    return null;
  };

  render() {
    if (this.state.fetching) {
      return null;
    }

    return (
      <Page className='TeamTokenPage' title={`Start using ${formatAsHumanReadableList(this.state.licensedProducts.map(ProductTypeLabel.asString), '&')}`}>
        <div className='TeamTokenPage_content'>
          <div className='TeamTokenPage_instructions-panel'>
            <div className='TeamTokenPage_instructions-panel_gradient' />
            {this.state.licensedProducts.map(productType => (
              <div
                key={productType}
                className={classNames('TeamTokenPage_tab', { 'selected': productType === this.state.selectedTabProductType })}
                data-product-type={productType}
                onClick={this.onTabPress}
              >
                <span className='TeamTokenPage_tab-text'>
                  <ProductTypeLabel id={productType} styled />
                </span>
              </div>
            ))}

            <div className='TeamTokenPage_tab-content'>
              <div className='TeamTokenPage_tab-content_row'>
                <div className='TeamTokenPage_tab-content_cell'>
                  <SelectField
                    label='Select environment'
                    className='TeamTokenPage_tab-content_environment-select'
                    onChange={this.onEnvironmentChange}
                    value={this.state.selectedIde.id}
                    disabled={ides[this.state.selectedTabProductType].length === 1}
                  >
                    {ides[this.state.selectedTabProductType].map(({ id, name }) => <option key={id} value={id}>{name}</option>)}
                  </SelectField>
                </div>
                <div className='TeamTokenPage_tab-content_cell'>
                  <div className='TeamTokenPage_tab-content_component-title-row'>
                    <div className='TeamTokenPage_tab-content_component-title-row_title'>Your team URL</div>
                    <div className='TeamTokenPage_tab-content_component-title-row_more-info'>
                      <a
                        className='TeamTokenPage_tab-content_component-title-row_more-info_url'
                        href='https://manuals.jrebel.com/zt-license-management/activation/#how-does-the-team-url-work'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        How does the team URL work?
                      </a>
                    </div>
                  </div>
                  {this.renderCopyUrlBox()}
                </div>
              </div>
              <div className='TeamTokenPage_tab-content_row'>
                <div className='TeamTokenPage_tab-content_cell'>
                  <h1>{this.state.selectedTabProductType === 'XREBEL' ? 'Install & Start up' : 'Install'}</h1>
                  <div className='TeamTokenPage_tab-content_instruction-list'>
                    {this.renderInstallationSteps()}
                  </div>
                  {this.renderGoodToKnowAlert()}
                  <Fragment key={`${this.state.selectedTabProductType}-${this.state.selectedIde.id}`}>
                    {this.renderDidNotWorkAlert()}
                  </Fragment>
                </div>
                <div className='TeamTokenPage_tab-content_cell'>
                  <h1>Activate</h1>
                  <div className='TeamTokenPage_tab-content_instruction-list'>
                    {this.renderActivationSteps()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='TokenPage_footer'>
            For more help, please see {this.renderQuickStartLink()} or contact <a href='mailto:support-rebel@perforce.com'>support-rebel@perforce.com</a>
          </div>
        </div>
      </Page>
    );
  }
}
