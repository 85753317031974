import classNames from 'classnames';
import React from 'react';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import './styles.css';

type OptionType = {
  value: string,
  label: string
}

interface NewSelectFieldProps {
  label?: string,
  name: string,
  className?: string,
  idKey?: string,
  value: OptionType | null,
  onChange: any,
  disabled?: boolean,
  options: OptionType[]
  defaultValue?: OptionType | null
}

export default function NewSelectField({ label, name, className, idKey, ...otherProps }: NewSelectFieldProps) {
  const inputFieldId = `${name}${idKey ? `-${idKey}` : ''}`;
  return (
    <>
      {label && <label className='NewSelectField_label' htmlFor={inputFieldId} id={`${inputFieldId}-label`}>{label}</label>}
      <Select
        aria-labelledby={`${inputFieldId}-label`}
        className={classNames('NewSelectField_container', className)}
        classNamePrefix='NewSelectField'
        components={{
          // @ts-ignore
          DropdownIndicator,
          IndicatorSeparator: () => null
        }}
        id={inputFieldId}
        isMulti={false}
        isSearchable={false}
        maxMenuHeight={332}
        name={name}
        {...otherProps}
      />
    </>
  );
}

function DropdownIndicator(props: DropdownIndicatorProps) {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator { ...props }>
        <i className={`icon ${props.selectProps.menuIsOpen ? 'icon-chevron-up' : 'icon-chevron-down'}`} />
      </components.DropdownIndicator>
    )
  );
}
