import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import Page from '../../../components/Page';
import RouterComponent from '../../../components/RouterComponent';
import TabBar from '../../../components/TabBar';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { withRouter } from '../../../utils/withRouter';
import CornerNotificationPopup from '../CornerNotificationPopup';
import TeamRedeployTimeModal from '../TeamRedeployTimeModal';
import RoiCalculatorTab from './RoiCalculatorTab';
import './styles.css';

export default withRouter(class AnalyticsPage extends RouterComponent {

  state = {
    activeModal: null,

    teams: [],
    fetchingTeams: true
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    Api.get('/teams?with_products=true', ({ list }) => {
      this.setState({ teams: list, fetchingTeams: false });
    });
  }

  onConfigureButtonPress = () => {
    this.setState({ activeModal: 'teamRedeployTime' });
  };

  onTeamRedeployTimeModalSave = () => {
    this.closeModal();
    this.fetch();
  };

  closeModal = () => {
    this.setState({ activeModal: null });
  };

  render() {/* JR-12687 TODO: Better fix */
    return (
      <Page
        className='AnalyticsPage'
        alert={null}
        title='Analytics'
      >
        {this.state.activeModal === 'teamRedeployTime' && (
          <TeamRedeployTimeModal origin='ROI_CALCULATOR_ALERT' onSave={this.onTeamRedeployTimeModalSave} onCancel={this.closeModal} />
        )}

        <TabBar>
          <NavLink end to=''>
            ROI calculator
          </NavLink>
          <></>
        </TabBar>

        <Routes>
          <Route path='*' element={<RoiCalculatorTab />} />
        </Routes>

        {User.isNPSNotificationEnabled() && (
          <CornerNotificationPopup
            type='SHOW_FEEDBACK_FORM'
            origin='ANALYTICS_PAGE'
            onSave={() => {}}
            onCancel={() => {}}
          />
        )}
      </Page>
    );
  }
});
