import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button';
import NewSelectField from '../../../../components/NewSelectField';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import TeamForm from '../../TeamForm';
import TeamTable from '../../TeamTable';

export default function TeamsOverview({ teams, onPressAnyButton, onRef, onUpdateTeams }) {

  const navigate = useNavigate();
  const [ favouriteTeams, setFavouriteTeams ] = useState([]);
  const [ fetchingFavourites, setFetchingFavourites ] = useState(true);
  const [ filterTeams, setFilterTeams ] = useState(getFilterTeams()[0]);
  const [ modal, setModal ] = useState({ active: null, team: null });
  const [ sortBy, setSortBy ] = useState(getFilterTeams()[0].value);
  const [ sortByFavourites, setSortByFavourites ] = useState(false);

  const fetchData = useCallback(() => {
    if (teams.length > 0) {
      Api.get('/teams/favourites', list => {
        setFavouriteTeams(list);
        setFetchingFavourites(false);
      });
    }
  }, [ teams.length ]);

  useEffect(() => {
    fetchData();
  }, [ fetchData ]);

  function getFilterTeams() {
    return [
      { value: 'latest', label: 'Latest teams' },
      { value: 'favourite', label: 'Favourite teams' },
      { value: 'largest', label: 'Largest teams' }
    ];
  }

  function isFavouriteTeam(token) {
    return !fetchingFavourites && favouriteTeams.find(value => value === token);
  }

  function onClickAddTeam() {
    setModal({ active: 'addTeam', team: null });
    onPressAnyButton();
  }

  function onUpdateFavouriteTeam(token) {
    if (fetchingFavourites) {
      return;
    }
    if (favouriteTeams.find(value => value === token)) {
      Api.del('/teams/favourite', { token }, () => {
        setFavouriteTeams(prevFavouriteTeams => {
          return [ ...prevFavouriteTeams.filter(value => value !== token) ];
        });
      });
    }
    else {
      Api.post('/teams/favourite', { token }, () => {
        setFavouriteTeams(prevFavouriteTeams => {
          return [ ...prevFavouriteTeams, token ];
        });
      });
    }
  }

  function onCancelModal() {
    setModal({ active: null, team: null });
  }

  function onSaveModal() {
    onCancelModal();
    onUpdateTeams();
  }

  function onChangeTeams(newValue) {
    setFilterTeams(newValue);
    if (newValue.value === 'favourite') {
      setSortByFavourites(true);
    }
    else {
      setSortBy(newValue.value);
      setSortByFavourites(false);
    }
  }

  function onClickViewAll() {
    navigate('/teams');
  }

  function sortedTeams() {
    let sorted;
    if (sortBy === 'largest') {
      sorted = _(teams).orderBy('seatUserCount.yearly', 'desc').value();
    }
    else {
      sorted = _(teams).orderBy('createdAt', 'desc').value();
    }
    if (sortByFavourites) {
      const teams0 = sorted;
      const teams1 = teams0.filter(({ token }) => isFavouriteTeam(token));
      const teams2 = teams0.filter(({ token }) => !isFavouriteTeam(token));
      sorted = [ ...teams1, ...teams2 ];
    }
    if (User.isTeamLead()) {
      return sorted;
    }
    return sorted.slice(0, Math.min(3, sorted.length));
  }

  return (
    <div className='TeamsOverview Dashboard_teams' ref={onRef}>
      {modal.active === 'addTeam' && (
        <TeamForm editedTeam={modal.team} onSave={onSaveModal} onCancel={onCancelModal} />
      )}
      <div className='Dashboard_teams_top'>
        <div className='Dashboard_teams_top_left'>
          <h5>Teams overview</h5>
          <div className='Dashboard_teams_top_filter'>
            <NewSelectField name='filterTeams' onChange={onChangeTeams} options={getFilterTeams()} value={filterTeams} />
          </div>
        </div>
        <div className='Dashboard_teams_top_buttons'>
          {!User.isTeamLead() && (
            <Button type='secondary' onClick={onClickViewAll}>
              View all
            </Button>
          )}
          {User.isAccountAdmin() && (
            <Button type='primary' onClick={onClickAddTeam}>
              Add team
            </Button>
          )}
        </div>
      </div>
      <TeamTable
        favourites={favouriteTeams}
        fetching={fetchingFavourites}
        onUpdateFavourite={onUpdateFavouriteTeam}
        onUpdateTeams={onUpdateTeams}
        origin='DASHBOARD'
        teams={sortedTeams()}
      />
    </div>
  );
}
