export const AdminRole = {
  __proto__: null,
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  VIEW_ONLY_ADMIN: 'VIEW_ONLY_ADMIN',
  TEAM_LEAD: 'TEAM_LEAD'
};

export const AdminStatus = {
  __proto__: null,
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED'
};

export const UserCategory = {
  __proto__: null,
  SEATS: 'SEATS',
  INVITED: 'INVITED',
  BLOCKED: 'BLOCKED',
  FORGOTTEN: 'FORGOTTEN',
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM'
};

export const UserStatus = {
  __proto__: null,
  ACTIVE: 'ACTIVE',
  INVITED: 'INVITED',
  BLOCKED: 'BLOCKED',
  FORGOTTEN: 'FORGOTTEN',
  INDIVIDUAL: 'INDIVIDUAL'
};

export const AdminRoleLabels = {
  __proto__: null,
  ACCOUNT_ADMIN: 'Administrator',
  VIEW_ONLY_ADMIN: 'View only',
  TEAM_LEAD: 'Team lead'
};

export const AdminStatusLabels = {
  __proto__: null,
  PENDING: 'Invite pending',
  ACTIVE: 'Active',
  BLOCKED: 'Blocked'
};

export const ProductTypeLabels = {
  __proto__: null,
  JREBEL: 'JRebel',
  XREBEL: 'XRebel'
};

export const TimeSavedLabels = {
  __proto__: null,
  total: 'All time',
  weekly: 'Last 7 days',
  monthly: 'Last 30 days',
  quarterly: 'Last 90 days',
  yearly: 'Last 365 days'
};

export const UserCategoryLabels = {
  __proto__: null,
  SEATS: 'Users with seats',
  INVITED: 'Invited users',
  BLOCKED: 'Blocked users',
  FORGOTTEN: 'Forgotten users',
  INDIVIDUAL: 'Individual users'
};
