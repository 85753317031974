import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import Page from '../../../components/Page';
import ProductTypeLabel from '../../../components/ProductTypeLabel';
import TabBar from '../../../components/TabBar';
import Table, { TableCell, TableRow } from '../../../components/Table';
import Api from '../../../utils/Api';
import EventEmitter from '../../../utils/EventEmitter';
import AddWhitelistedDomainForm from '../AddWhitelistedDomainForm';
import { notificationNameLabels } from '../labels';
import TeamForm from '../TeamForm';
import './styles.css';

const descriptionTemplates = {
  TEAM_LIMIT_EXCEEDED(seatUserEmail, teamName, productType) {
    return `${seatUserEmail} tried to take a ${ProductTypeLabel.asString(productType)} seat from ${teamName}, but team seat limit is reached.`;
  },
  ACCOUNT_OUT_OF_LICENSES(seatUserEmail, teamName, productType) {
    return `${seatUserEmail} tried to take a ${ProductTypeLabel.asString(productType)} seat from ${teamName}, but your account is out of licenses.`;
  },
  INVALID_SEAT_USER_DOMAIN(seatUserEmail, teamName, productType) {
    return `${seatUserEmail} tried to take a ${ProductTypeLabel.asString(productType)} seat from ${teamName}, but this email domain is not allowed.`;
  }
};

// This was lifted out of the class because the withRouter wrapper seemed to cause visibility issues.
export const NotificationUpdatedEventId = 'NotificationPage.updated';

export default function NotificationPage() {

  const [ notifications, setNotifications ] = useState([]);
  const [ teams, setTeams ] = useState([]);
  const [ whitelistedDomains, setWhitelistedDomains ] = useState([]);
  const [ fetching, setFetching ] = useState(true);
  const [ hoveredNotification, setHoveredNotification ] = useState(null);
  const [ alert, setAlert ] = useState(null);
  const params = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const closeModal = useCallback(() => {
    searchParams.delete('teamName');
    searchParams.delete('name');
    searchParams.delete('seatUserEmail');
    searchParams.delete('productType');
    searchParams.delete('origin');
    setSearchParams(searchParams);
    // Cannot depend on searchParams
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tryFindNotificationByUrl = useCallback(notifications => notifications
    .filter(({ name }) => name === searchParams.get('name'))
    .filter(({ teamName }) => teamName === searchParams.get('teamName'))
    .filter(({ seatUserEmail }) => seatUserEmail === searchParams.get('seatUserEmail'))
    .filter(({ productType }) => productType === searchParams.get('productType'))
    .find(({ status }) => status !== 'ARCHIVED'),
  // Cannot depend on searchParams
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const fetch = useCallback(() => {
    (async () => {
      return {
        notifications: await getListPromise('/notifications'),
        teams: await getListPromise('/teams?with_products=true'),
        whitelistedDomains: await getListPromise('/whitelisted-domains')
      };
    })().then(({ notifications, teams, whitelistedDomains }) => {
      updateHeader(notifications);
      updateNotificationStatusNewToActive(notifications);

      let alert = null;

      if (searchParams.get('name') && !tryFindNotificationByUrl(notifications)) {
        closeModal();
        alert = 'Could not find the notification you we\'re looking for. Here are all the notifications.';
      }

      setNotifications(notifications);
      setTeams(teams);
      setWhitelistedDomains(whitelistedDomains);
      setFetching(false);
      setAlert(alert);
    });
  },
  // Cannot depend on searchParams
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ closeModal, tryFindNotificationByUrl ]);

  useEffect(() => {
    fetch();
  }, [ fetch ]);

  useEffect(() => {
    if (!fetching && searchParams.get('name') && searchParams.get('origin') === 'EMAIL') {
      Api.post('/notifications/events/press/call-to-action', { name: searchParams.get('name'), origin: 'EMAIL' });
    }
  }, [ fetching, searchParams ]);

  function updateHeader(notifications) {
    const list = notifications.map(notification => ({ ...notification, status: notification.status === 'NEW' ? 'ACTIVE' : notification.status }));
    EventEmitter.emit(NotificationUpdatedEventId, list);
  }

  function updateNotificationStatusNewToActive(list) {
    const newNotifications = _.filter(list, { status: 'NEW' });

    if (newNotifications.length > 0) {
      Api.put('/notifications', { list: newNotifications.map(notification => ({ ...notification, status: 'ACTIVE' })) });
    }
  }

  function getListPromise(url) {
    return new Promise(resolve => Api.get(url, ({ list }) => resolve(list)));
  }

  function getSortedNotifications() {
    const archived = params.rk === 'archive';

    return _(notifications)
      .filter(({ status }) => (status === 'ARCHIVED') === archived)
      .sortBy([ ({ createdAt }) => createdAt ])
      .reverse()
      .value();
  }

  function archiveAndSendEvent(notification) {
    archive(notification);
    Api.post('/notifications/events/press/archive', { name: notification.name });
  }

  function archive(notification) {
    Api.put('/notifications', { list: [ { ...notification, status: 'ARCHIVED' } ] }, fetch);
  }

  function closeModalAndArchiveNotification(notification) {
    closeModal();
    archive(notification);
  }

  function onTableRowMouseOver({ currentTarget }) {
    setHoveredNotification(findNotificationByMouseEvent(notifications, { currentTarget }));
  }

  function findNotificationByMouseEvent(notifications, { currentTarget }) {
    const searchedId = parseInt(currentTarget.getAttribute('data-id'), 10);

    return notifications.find(({ id }) => id === searchedId);
  }

  function onTableRowMouseLeave() {
    setHoveredNotification(null);
  }

  function onActionButtonPress(event) {
    const { name, teamName, seatUserEmail, productType } = findNotificationByMouseEvent(notifications, event);

    searchParams.set('name', name);
    searchParams.set('teamName', teamName);
    searchParams.set('seatUserEmail', seatUserEmail);
    searchParams.set('productType', productType);
    setSearchParams(searchParams);

    Api.post('/notifications/events/press/call-to-action', { name, origin: 'GUI' });
  }

  function onContactSalesButtonPress(event) {
    const notification = findNotificationByMouseEvent(notifications, event);

    archive(notification);
    Api.post('/notifications/events/press/call-to-action', { name: notification.name, origin: 'GUI' });
  }

  function getActionButton({ id, name }) {
    const active = hoveredNotification && id === hoveredNotification.id;
    const classes = classNames('NotificationPage_table_action-button', { 'Button secondary': !active, 'Button primary': active });
    const type = active ? 'primary' : 'secondary';

    switch (name) {
    case 'TEAM_LIMIT_EXCEEDED':
      return <Button type={type} className='NotificationPage_table_action-button' data-id={id} onClick={onActionButtonPress}>Increase limit</Button>;
    case 'ACCOUNT_OUT_OF_LICENSES':
      return <a className={classes} href='mailto:support-rebel@perforce.com' data-id={id} onClick={onContactSalesButtonPress}>Contact sales</a>;
    case 'INVALID_SEAT_USER_DOMAIN':
      return (<Button type={type} className='NotificationPage_table_action-button Add_to_whitelist-button' data-id={id} onClick={onActionButtonPress}>
        Add domain to whitelist
      </Button>);
    default:
      return null;
    }
  }

  function renderTeamForm() {
    const notification = tryFindNotificationByUrl(notifications);

    return notification && (
      <TeamForm
        editedTeam={teams.find(({ name }) => name === notification.teamName)}
        onSave={() => closeModalAndArchiveNotification(notification)}
        onCancel={() => closeModalAndArchiveNotification(notification)}
      />
    );
  }

  function renderAddWhitelistedDomainForm() {
    const notification = tryFindNotificationByUrl(notifications);

    return notification && (
      <AddWhitelistedDomainForm
        domains={whitelistedDomains}
        addedDomain={notification.seatUserEmail.split('@')[1]}
        domainWhitelistingEnabled={true}
        onSave={() => closeModalAndArchiveNotification(notification)}
        onCancel={() => closeModalAndArchiveNotification(notification)}
      />
    );
  }

  function renderDescriptionCell({ name, seatUserEmail, teamName, productType }) {
    return <TableCell className='NotificationPage_table_cell'>{descriptionTemplates[name](seatUserEmail, teamName, productType)}</TableCell>;
  }

  function renderNoResults() {
    return (<div className='NotificationPage_no-results-message'>
      <a className='NotificationPage_no-results-image' />
      <div className='NotificationPage_no-results-text'>
        <h2>No notifications yet</h2>
        {/* <p>Add administrators to start distributing your licenses</p> */}
      </div>
    </div>);
  }

  return (
    <Page
      className='NotificationPage'
      title='Notification feed'
      alert={alert && <Alert type='warn'>{alert}</Alert>}
      withHeading
    >
      {!fetching && searchParams.get('name') === 'TEAM_LIMIT_EXCEEDED' && renderTeamForm()}
      {!fetching && searchParams.get('name') === 'INVALID_SEAT_USER_DOMAIN' && renderAddWhitelistedDomainForm()}

      <TabBar>
        <NavLink end to=''>
          Active
        </NavLink>
        <NavLink to='archive'>
          Archived
        </NavLink>
      </TabBar>
      <Card>
        <Table
          className='NotificationPage_table'
          header={
            <Fragment>
              <TableRow>
                <th className='NotificationPage_table_name-column'>What happened</th>
                <th className='NotificationPage_table_description-column' />
                <th className='NotificationPage_table_date-column'>Date</th>
                {params.rk !== 'archive' && (
                  <Fragment>
                    <th className='NotificationPage_table_action-column' />
                    <th className='NotificationPage_table_archive-column' />
                  </Fragment>
                )}
              </TableRow>
            </Fragment>
          }
          body={
            <Fragment>
              {fetching && (
                <TableRow className='NotificationPage_no-results'>
                  <TableCell colSpan='5'>
                    Fetching data...
                  </TableCell>
                </TableRow>
              )}
              {!fetching && getSortedNotifications().length === 0 && (

                <TableRow className='NotificationPage_no-results'>
                  <TableCell colSpan='5'>
                    {renderNoResults()}
                  </TableCell>
                </TableRow>
              )}

              {!fetching && getSortedNotifications().map(notification => {
                const { id, name, createdAt, status } = notification;
                return (
                  <TableRow key={id} data-id={id} onMouseOver={onTableRowMouseOver} onMouseLeave={onTableRowMouseLeave}>
                    <TableCell>
                      <div className={classNames('NotificationPage_table_name', { 'new': status === 'NEW' })}>{notificationNameLabels[name] || name}</div>
                    </TableCell>
                    {renderDescriptionCell(notification)}
                    <TableCell className='NotificationPage_table_cell'><DateTimeLabel timestamp={createdAt} /></TableCell>
                    {params.rk !== 'archive' && (
                      <Fragment>
                        <TableCell>{getActionButton(notification)}</TableCell>
                        <TableCell>
                          <Button className='NotificationPage_table_archive-button' onClick={() => archiveAndSendEvent(notification)}>Archive</Button>
                        </TableCell>
                      </Fragment>
                    )}
                  </TableRow>
                );
              })}
            </Fragment>
          }
        />
      </Card>
    </Page>
  );
}
