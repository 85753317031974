import Alert, { AlertEmphasis } from '../../../../components/Alert';

export default function Alerts({ alert: { active, username } }) {

  function render(message) {
    return (
      <Alert type='ok' onIconClick={() => {}}>
        {message}
      </Alert>
    );
  }

  switch (active) {
  case 'create':
    return render(
      <span>Invitation email sent successfully to <AlertEmphasis>{username}</AlertEmphasis>.</span>
    );
  case 'edit':
    return render(
      <span><AlertEmphasis>{username}</AlertEmphasis> role changed successfully.</span>
    );
  case 'reset':
    return render(
      <span>Password reset email sent successfully to <AlertEmphasis>{username}</AlertEmphasis>.</span>
    );
  case 'resend':
    return render(
      <span>Invitation email sent successfully to <AlertEmphasis>{username}</AlertEmphasis>.</span>
    );
  case 'block':
    return render(
      <span><AlertEmphasis>{username}</AlertEmphasis> has been blocked successfully.</span>
    );
  case 'unblock':
    return render(
      <span><AlertEmphasis>{username}</AlertEmphasis> has been unblocked successfully.</span>
    );
  case 'delete':
    return render(
      <span><AlertEmphasis>{username}</AlertEmphasis> has been deleted successfully.</span>
    );
  default:
    return <></>;
  }
}
