import { useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';

export default function DeleteModal({ user, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onOkButtonPress() {
    setSaving(true);
    Api.del('/seat-users', { email: user.email }, () => {
      onSuccess(user.email);
    });
  }

  return (
    <Modal
      title='Delete user'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='danger' disabled={saving} onClick={onOkButtonPress}>
            Delete
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <div>
        <p>You are about to delete <strong>{user.email}</strong>.</p>
        <p>This action cannot be reversed. Past time savings for the user will still contribute to team and account statistics.</p>
        <p>Are you sure you want to continue?</p>
      </div>
    </Modal>
  );
}
