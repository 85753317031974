import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ActionsButton } from '../../../components/ActionsButton';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateTimeLabel from '../../../components/DateTimeLabel';
import { DropdownMenuButton } from '../../../components/Dropdown';
import InfoTooltip from '../../../components/InfoTooltip';
import LicenseTypeLabel from '../../../components/LicenseTypeLabel';
import Page from '../../../components/Page';
import TabBar from '../../../components/TabBar';
import Table, { TableCell, TableRow } from '../../../components/Table';
import UnitLabel from '../../../components/UnitLabel';
import { isStandalone } from '../../../enums/LicenseType';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import Alerts from './Alerts';
import ExportDedicatedForm from './ExportDedicatedForm';
import ExportManagedForm from './ExportManagedForm';
import { filterOrders, getAvailableSeatCount, groupByOrders, hasExpired, sortLicenses, toEndOfDay } from './selectors';
import './styles.css';

const supportLevelLabels = {
  PROFESSIONAL: 'RebelCare Professional',
  ENTERPRISE: 'RebelCare Enterprise'
};

export default function BillingPage() {

  const [ licenses, setLicenses ] = useState([]);
  const [ alert, setAlert ] = useState({ active: null, email: null });
  const [ modal, setModal ] = useState({ active: null, license: null });
  const [ seatUsage, setSeatUsage ] = useState([]);
  const [ fetchingSeatUsage, setFetchingSeatUsage ] = useState(User.canEdit());
  const [ fetchingLicenses, setFetchingLicenses ] = useState(true);
  const [ managedExportEnabled, setManagedExportEnabled ] = useState(false);
  const params = useParams();

  useEffect(() => {
    fetch();
  }, []);

  function openModalForRow({ currentTarget }) {
    setAlert({ active: null, email: null });
    setModal({
      active: currentTarget.getAttribute('data-modal-id'),
      license: licenses
        .filter(({ orderItemId }) => orderItemId === currentTarget.getAttribute('data-order-item-id'))
        .find(({ purpose }) => purpose === currentTarget.getAttribute('data-purpose'))
    });
  }

  function closeModal() {
    setModal({ active: null, license: null });
  }

  function onExportManagedModalSave(exportedTo) {
    closeModal();
    fetch();
    setAlert({ active: 'export', email: exportedTo });
  }

  function fetch() {
    Api.get('/licenses', ({ list }) => {
      setLicenses(list);
      setFetchingLicenses(false);
    });

    Api.get('/account', ({ managedExportEnabled }) => {
      setManagedExportEnabled(managedExportEnabled);
    });

    if (User.canEdit()) {
      Api.get('/statistics', ({ seatUsage }) => {
        setSeatUsage(seatUsage);
        setFetchingSeatUsage(false);
      });
    }
  }

  function getOrders() {
    return groupByOrders(sortLicenses(licenses));
  }

  function getFilteredOrders(tab) {
    return filterOrders(getOrders(), tab);
  }

  function isTabBarVisible() {
    return !fetchingLicenses && getFilteredOrders().length > 0 && getFilteredOrders('history').length > 0;
  }

  return (
    <Page
      className='BillingPage'
      alert={<Alerts alert={alert} />}
      title='Billing'
    >
      {modal.active === 'exportDedicatedForm' && (
        <ExportDedicatedForm license={modal.license} onCancel={closeModal} />
      )}
      {modal.active === 'exportManagedForm' && (
        <ExportManagedForm license={modal.license} onSave={onExportManagedModalSave} onCancel={closeModal} />
      )}
      {isTabBarVisible() && (
        <TabBar>
          <NavLink end to=''>Licenses</NavLink>
          <NavLink to='history'>Invoice history</NavLink>
        </TabBar>
      )}
      {(fetchingLicenses || fetchingSeatUsage) ?

        <div>{licenses.length === 0 ? 'No licenses' : 'Fetching data...'}</div> :

        (isTabBarVisible() ? getFilteredOrders(params.rk) : getOrders()).map(({ groupOrderId, licenses }, i) => {
          return (
            <Card key={i} className='BillingPage_order-panel'>
              <Table
                header={
                  <Fragment>
                    <TableRow>
                      <th className='BillingPage_order-number' colSpan='6'>{groupOrderId === 'EVALUATION' ? 'Evaluation' : `Order #${groupOrderId}`}</th>
                    </TableRow>
                    <TableRow>
                      <th className='BillingPage_product-column'>Product</th>
                      <th className='BillingPage_total-seats-column'>Total seats</th>
                      <th className='BillingPage_start-date-column'>Start date</th>
                      <th className='BillingPage_end-date-column'>End date</th>
                      <th className='BillingPage_export-column' />
                      <th className='BillingPage_actions-column' />
                    </TableRow>
                  </Fragment>
                }
                body={licenses
                  .map((license, i) => {
                    const {
                      orderId, orderItemId, type, seatCount, startDate, endDate, effectiveEndDate, purpose, supportLevel, exportedSeatCounts: { MANAGED, DEDICATED }
                    } = license;
                    const canExport = User.canEdit() && purpose !== 'COMMERCIAL_MIGRATION' && !hasExpired(endDate);
                    const hasExportedDedicated = DEDICATED > 0;
                    const shouldLabelAsExpired = hasExpired(endDate) && (params.rk !== 'history' || !isTabBarVisible());
                    const shouldShowEffectiveEndDate = hasExpired(endDate) && !hasExpired(effectiveEndDate) && (params.rk !== 'history' || !isTabBarVisible());
                    const canExportManaged = canExport && getAvailableSeatCount(seatUsage, license) > 0;

                    return (
                      <Fragment key={`${orderItemId} ${purpose}`}>
                        <TableRow className={classNames('BillingPage_row', { 'de-emphasized': shouldLabelAsExpired })}>
                          <TableCell className='no-overflow'>
                            <LicenseTypeLabel id={type} />{purpose === 'COMMERCIAL_MIGRATION' && ' (migration)'}{shouldLabelAsExpired && ' (expired)'}
                          </TableCell>
                          <TableCell>
                            {MANAGED === 0 ?

                              seatCount :

                              <span>
                                <span>{seatCount - MANAGED} / {seatCount}</span>
                                <InfoTooltip size='s' text={<span><UnitLabel value={MANAGED} unit='seat' /> have been exported for your Rebel Licenses On-Premise.</span>} />
                              </span>}
                          </TableCell>
                          <TableCell>
                            <DateTimeLabel timestamp={startDate} />
                          </TableCell>
                          <TableCell>
                            {!shouldShowEffectiveEndDate && <DateTimeLabel timestamp={endDate} />}
                            {shouldShowEffectiveEndDate && (
                              <span>
                                <strike><DateTimeLabel timestamp={endDate} /></strike>
                                <br />
                                <DateTimeLabel timestamp={effectiveEndDate} />
                                <InfoTooltip size='l' text='This license has been granted a grace period. Hosted seats are useable until the end of the grace period.' />
                              </span>
                            )}
                            {!hasExpired(endDate) && (
                              <div className='BillingPage_expires-in-label'>
                                <DateTimeLabel timestamp={toEndOfDay(endDate)} displayAsFromNow />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <div className='BillingPage_export-managed'>
                              {canExport && purpose !== 'EVALUATION' && managedExportEnabled && (
                                <Button
                                  type='secondary'
                                  className={classNames('BillingPage_export-managed-button', { disabled: !canExportManaged })}
                                  data-modal-id='exportManagedForm'
                                  disabled={!canExportManaged}
                                  data-order-item-id={orderItemId}
                                  data-purpose={purpose}
                                  onClick={openModalForRow}
                                >
                                  Export for On-Premise
                                </Button>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='BillingPage_export-dedicated'>
                              {(canExport || hasExportedDedicated) && isStandalone(type) && (
                                <ActionsButton>
                                  {canExport && (
                                    <DropdownMenuButton
                                      data-modal-id='exportDedicatedForm'
                                      data-order-item-id={orderItemId}
                                      data-purpose={purpose}
                                      disabled={seatCount - MANAGED - DEDICATED === 0}
                                      onPress={openModalForRow}
                                    >
                                      Export individual licenses
                                    </DropdownMenuButton>
                                  )}
                                  {hasExportedDedicated && (
                                    <DropdownMenuButton routeTo={{ path: '/users', parameters: { orderId }, state: { category: 'INDIVIDUAL' } }}>
                                      Users with individual licenses
                                    </DropdownMenuButton>
                                  )}
                                </ActionsButton>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                        {i === licenses.length - 1 && supportLevel && supportLevel !== 'STANDARD' && (
                          <TableRow>
                            <TableCell colSpan='6'>
                              {supportLevelLabels[supportLevel]} (<a href='mailto:support@jrebel.com'>support@jrebel.com</a>)
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })}
              />
            </Card>
          );
        })}
    </Page>
  );
}
