import './styles.css';

type BulkActionItemProps = {
  enabled: boolean,
  value: string,
  onClick: any
};

export default function BulkActionItem({ enabled, value, onClick }: BulkActionItemProps) {

  if (!enabled) {
    return (
      <div className='BulkActionItem disabled'>
        <b>{value}</b>
      </div>
    );
  }

  return (
    <div className='BulkActionItem' onClick={onClick}>
      <b>{value}</b>
    </div>
  );
}
