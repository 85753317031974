import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import Modal, { ModalButton } from '../../../../../components/Modal';
import Api from '../../../../../utils/Api';
import './styles.css';
import { isBlacklisted } from '../../../selectors';
import Card, { CardBody } from '../../../../../components/Card';

const DeleteWhitelistedDomainModal = ({ onSuccess, onCancel, selectedDomain, remainingDomains }) => {
  const [ productInstallations, setProductInstallations ] = useState([]);
  const [ saving, setSaving ] = useState(false);
  const [ fetching, setFetching ] = useState(true);

  function fetch() {
    Api.get('/product-installations', ({ list }) => {
      setProductInstallations(list);
      setFetching(false);
    });
  }

  useEffect(() => {
    fetch();
  }, []);

  function getSeatUserEmailsMatchingDeletedDomainWithUnconfirmedProductInstallations() {
    return _(productInstallations)
      .filter(({ confirmed }) => !confirmed)
      .map(({ seatUserEmail }) => seatUserEmail)
      .filter(email => !isBlacklisted(email, selectedDomain))
      .filter(email => remainingDomains.every(({ domain }) => isBlacklisted(email, domain)))
      .uniq()
      .sort()
      .value();
  }

  const onDeleteButtonPress = () => {
    setSaving(true);

    onSuccess();
  };

  return !fetching && (
    <Modal
      title='Delete domain from whitelist'
      size='m'
      buttons={<div>
        <ModalButton type='secondary' disabled={saving} onClick={onCancel}>Cancel</ModalButton>
        <ModalButton type='danger' disabled={saving} onClick={onDeleteButtonPress}>Delete domain</ModalButton>
      </div>}
      onClose={onCancel}
    >
      <p>
        Activation with <strong>{selectedDomain}</strong> email addresses will not be allowed.<br />
        Users who have already activated will not be affected, use{' '}
        <a href='https://manuals.jrebel.com/zt-license-management/users/#blocking-users' rel='noopener noreferrer' target='_blank'>Blocking</a>{' '}
        if needed.
      </p>
      {getSeatUserEmailsMatchingDeletedDomainWithUnconfirmedProductInstallations().length > 0 && (
        <Fragment>
          <p>Following users with pending invites will not be able to activate:</p>
          <Card>
            <CardBody className='DeleteWhitelistedDomainModal_product-installation-list pre-scrollable'>
              {getSeatUserEmailsMatchingDeletedDomainWithUnconfirmedProductInstallations().map(email => <div key={email}>{email}</div>)}
            </CardBody>
          </Card>
        </Fragment>
      )}
      <p className='DeleteWhitelistedDomainModal_confirmation-text'>Are you sure?</p>
    </Modal>
  );
};

DeleteWhitelistedDomainModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedDomain: PropTypes.string.isRequired,
  remainingDomains: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string.isRequired
    })
  ).isRequired
};
export default DeleteWhitelistedDomainModal;
