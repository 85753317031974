import React, { useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';

export default function Disable2FAModal({ username, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onDisableTwoStepAuthenticationButtonPress() {
    setSaving(true);
    Api.put('/account/two-step-authentication-enabled', { twoStepAuthenticationEnabled: false }, () => {
      onSuccess(username);
    });
  }

  return (
    <Modal
      title='Turn off 2-step verification'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onDisableTwoStepAuthenticationButtonPress}>
            Confirm
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <p>Are you sure you want to turn off 2-step verification for your account?</p>
    </Modal>
  );
}
