import emailValidator from 'email-validator';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert, { AlertEmphasis } from '../../../../components/Alert';
import Bubble from '../../../../components/Bubble';
import { InputField } from '../../../../components/InputField';
import Modal, { ModalButton } from '../../../../components/Modal';
import NewSelectField from '../../../../components/NewSelectField';
import Api from '../../../../utils/Api';
import { getTeamActiveSeatUsers, isDomainBlacklisted } from '../../selectors';

export default function AddUsersModal({ seatUsers, teams, enableDomainWhitelisting, whitelistedDomains, teamName = null, onSuccess, onCancel }) {

  const [ email, setEmail ] = useState('');
  const [ emails, setEmails ] = useState([]);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ modal, setModal ] = useState('form');
  const [ saving, setSaving ] = useState(false);
  const [ team, setTeam ] = useState(null);
  const [ warningDomain, setWarningDomain ] = useState(false);

  useEffect(() => {
    if (teamName) {
      setTeam(teams.find(team => team.name === teamName));
    }
  }, [ teamName, teams ]);

  function getTeam() {
    const value = teamName || team?.name;
    return getTeams().find(option => option.value === value);
  }

  function getTeams() {
    return [
      ..._(teams).sortBy('name')
        .map(({ name }) => ({ value: name, label: name })).value()
    ];
  }

  function onClickInvite() {
    setSaving(true);
    Api.post(`/teams/${team.token}/seat-users/invited`, { emails }, () => {
      setModal('success');
    });
  }

  function onChangeEmail(value) {
    setEmail(value);
    setErrorMessage(null);
  }

  function onChangeTeam(newValue) {
    setTeam(teams.find(team => team.name === newValue.value));
  }

  function onDeleteEmail({ currentTarget }) {
    setEmails(prevEmails => {
      prevEmails.splice(prevEmails.indexOf(currentTarget.getAttribute('data-email')), 1);
      return [ ...prevEmails ];
    });
  }

  function onSubmitForm(e) {
    e.preventDefault();
    const inputEmails = email.trim().split(/[ ,]+/g);
    const validEmails = [];
    const invalidEmails = [];
    const blacklistEmails = [];
    const duplicateEmails = [];
    const errorMessages = [];
    for (const inputEmail of inputEmails) {
      let isInvalid = false;
      if (!emailValidator.validate(inputEmail)) {
        invalidEmails.push(inputEmail);
        isInvalid = true;
      }
      else if (enableDomainWhitelisting && isDomainBlacklisted(inputEmail, whitelistedDomains)) {
        blacklistEmails.push(inputEmail);
        isInvalid = true;
      }
      else if (getTeamActiveSeatUsers(seatUsers, team).some(({ email }) => email === inputEmail)) {
        duplicateEmails.push(inputEmail);
        isInvalid = true;
      }
      if (isInvalid && enableDomainWhitelisting && isDomainBlacklisted(inputEmail, whitelistedDomains)) {
        setWarningDomain(true);
      }
      if (!isInvalid) {
        validEmails.push(inputEmail);
      }
    }
    if (invalidEmails.length > 0) {
      errorMessages.push(`Please enter a valid email address: ${invalidEmails.join(', ')}.`);
    }
    if (blacklistEmails.length > 0) {
      errorMessages.push(`This email domain is not allowed by your licensing administrator: ${blacklistEmails.join(', ')}.`);
    }
    if (duplicateEmails.length > 0) {
      errorMessages.push(`A user with this email has already activated a product in this team: ${duplicateEmails.join(', ')}.`);
    }
    if (errorMessages.length > 0) {
      setErrorMessage(errorMessages.join(' '));
    }
    if (validEmails.length > 0) {
      setEmails(prevEmails => {
        for (const validEmail of validEmails) {
          if (!prevEmails.includes(validEmail)) {
            prevEmails.push(validEmail);
          }
        }
        return [ ...prevEmails ];
      });
    }
    setEmail('');
  }

  return (
    <div className='AddUsersModal'>
      {modal === 'success' && (
        <Modal
          title='Invites sent'
          size='m'
          buttons={
            <ModalButton type='primary' onClick={onSuccess}>
              Got it!
            </ModalButton>
          }
          onClose={onSuccess}
        >
          <p>
            Invitation emails with installation and activation instructions have been sent to your team members.
            You can see their usage statistics once they activate.
            You can go back to being awesome now.
          </p>
        </Modal>
      )}
      {modal === 'form' && (
        <Modal
          title='Add users'
          size='m'
          buttons={
            <div>
              <div className='AddUsersModal_new-team'>
                <b><Link to='/teams?modal=teamForm'>Create a new team</Link></b>
              </div>
              <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
                Cancel
              </ModalButton>
              <ModalButton type='primary' disabled={saving || emails.length === 0 || !team} onClick={onClickInvite}>
                Send invite
              </ModalButton>
            </div>
          }
          onSubmit={onSubmitForm}
          onClose={onCancel}
        >
          <p>
            Add emails to distribute your licenses.
            They can use the team URL to activate JRebel & XRebel.
          </p>
          {warningDomain && (
            <Alert type='warn' onIconClick={() => {}}>
              <AlertEmphasis>Only following email domains are allowed: </AlertEmphasis>
              {whitelistedDomains.map(({ domain }) => domain).join(', ')}
            </Alert>
          )}
          <div className='AddUsersModal_email'>
            <div className='AddUsersModal_email_input'>
              <InputField
                type='text'
                label='User emails'
                errorMessage={errorMessage}
                placeholder='Add emails, separated by comma or space'
                name='email'
                value={email}
                onChange={e => onChangeEmail(e.currentTarget.value)}
                autoFocus
                tooltip={{ text: 'Add emails, separated by comma or space', offset: [ 0, -10 ] }}
              />
            </div>
            <ModalButton type='primary' className='AddUsersModal_email_add-button' submit>
              Add
            </ModalButton>
          </div>
          <div className='AddUsersModal_bubbles'>
            {emails.map(email => <Bubble key={email} data-email={email} text={email} onRemove={onDeleteEmail} />)}
          </div>
          <div className='AddUsersModal_team'>
            <div className='AddUsersModal_team_select'>
              <NewSelectField
                isDisabled={teamName}
                label='Team'
                name='team'
                noOptionsMessage={() => ('No teams found.')}
                onChange={onChangeTeam}
                options={getTeams()}
                placeholder='Select a team'
                value={getTeam()}
              />
            </div>
            <p className='AddUsersModal_team_text'>
              Team gives users access to specific licenses and spaces.
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}
