import { useState } from 'react';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { UserStatus } from '../labels';
import { getFarthestOfflineUntil, hasOfflineProducts } from '../selectors';

export default function BlockModal({ user, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onOkButtonPress() {
    setSaving(true);
    if (user.status === UserStatus.INVITED) {
      Api.post(`/teams/${user.teamToken}/seat-users/invited/blocked`, { email: user.email }, () => {
        onSuccess(user.email);
      });
    }
    else {
      Api.put('/seat-users/blocked', { email: user.email, blocked: true }, () => {
        onSuccess(user.email);
      });
    }
  }

  function isPendingBlock() {
    return hasOfflineProducts(user);
  }

  return (
    <Modal
      title={isPendingBlock() ? 'Block offline user' : 'Block user'}
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='danger' disabled={saving} onClick={onOkButtonPress}>
            Block
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      {isPendingBlock() ?
        <div>
          <p>
            <span>User <strong>{user.email}</strong> will be blocked. </span>
            <span>Any offline seats assigned to this user will be made available on <strong><DateTimeLabel timestamp={getFarthestOfflineUntil(user)} /></strong>.</span>
          </p>
          <p>Are you sure you want block this user?</p>
        </div> :
        <div>
          <p>You are about to block user <strong>{user.email}</strong>.</p>
          <p>Doing this will automatically release all seats assigned to the user. The user will not be able to activate new seats.</p>
          <p>Are you sure?</p>
        </div>
      }
    </Modal>
  );
}
