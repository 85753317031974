import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Page from '../../../components/Page';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import BulkAction from '../BulkAction';
import BulkActionItem from '../BulkAction/BulkActionItem';
import BulkDeleteModal from '../BulkAction/BulkDeleteModal';
import { TeamBulkAction } from '../BulkAction/labels';
import TeamForm from '../TeamForm';
import TeamTable from '../TeamTable';

export default function TeamPage() {

  const location = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const [ bulkModal, setBulkModal ] = useState('');
  const [ editedTeam, setEditedTeam ] = useState(null);
  const [ favouriteTeams, setFavouriteTeams ] = useState([]);
  const [ fetching, setFetching ] = useState(true);
  const [ selectedTeams, setSelectedTeams ] = useState(null);
  const [ teams, setTeams ] = useState([]);

  const modal = searchParams.get('modal');
  const origin = searchParams.get('origin');

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (modal === 'teamForm' && origin === 'EMAIL') {
      Api.post('/admin-users/events/press/call-to-action', { name: 'ADD_TEAMS', origin: 'EMAIL' });
    }
  }, [ fetching, modal, origin ]);

  function bulkAddFavourite() {
    if (!isSelectedTeams()) {
      return;
    }
    Api.post('/teams/favourite/bulk', { tokens: selectedTeams }, () => {
      setFavouriteTeams(prevFavouriteTeams => {
        return [ ...prevFavouriteTeams, ...selectedTeams ];
      });
      setSelectedTeams([]);
    });
  }

  function bulkRemoveFavourite() {
    if (!isSelectedTeams()) {
      return;
    }
    Api.del('/teams/favourite/bulk', { tokens: selectedTeams }, () => {
      setFavouriteTeams(prevFavouriteTeams => {
        return [ ...prevFavouriteTeams.filter(value => !selectedTeams.includes(value)) ];
      });
      setSelectedTeams([]);
    });
  }

  function canActionTeams(action) {
    if (!isSelectedTeams()) {
      return false;
    }
    for (const team of selectedTeams) {
      if (!canActionTeam(team, action)) {
        return false;
      }
    }
    return true;
  }

  function canActionTeam(token, action) {
    switch (action) {
    case TeamBulkAction.ADD_FAVOURITE:
      return !isFavouriteTeam(token);
    case TeamBulkAction.DELETE:
      return User.canEdit() && !User.isTeamLead();
    case TeamBulkAction.REMOVE_FAVOURITE:
      return isFavouriteTeam(token);
    default:
      return false;
    }
  }

  function closeModal() {
    setEditedTeam(null);
    searchParams.delete('modal');
    searchParams.delete('origin');
    setSearchParams(searchParams);
  }

  function fetch() {
    Api.get('/teams?with_products=true', ({ list }) => {
      setTeams(list);
      if (list.length > 0) {
        Api.get('/teams/favourites', list2 => {
          setFavouriteTeams(list2);
          setFetching(false);
        });
      }
      else {
        setFetching(false);
      }
      setSelectedTeams([]);
    });
  }

  function getBulkActions() {
    if (User.canEdit() && !User.isTeamLead()) {
      return [
        <BulkActionItem key='1' onClick={() => openBulkModal(TeamBulkAction.DELETE)} enabled={true} value='Delete' />,
        <BulkActionItem key='2' onClick={bulkAddFavourite} enabled={canActionTeams(TeamBulkAction.ADD_FAVOURITE)} value='Set as favourite' />,
        <BulkActionItem key='3' onClick={bulkRemoveFavourite} enabled={canActionTeams(TeamBulkAction.REMOVE_FAVOURITE)} value='Remove as favourite' />
      ];
    }
    return [
      <BulkActionItem key='1' onClick={bulkAddFavourite} enabled={canActionTeams(TeamBulkAction.ADD_FAVOURITE)} value='Set as favourite' />,
      <BulkActionItem key='2' onClick={bulkRemoveFavourite} enabled={canActionTeams(TeamBulkAction.REMOVE_FAVOURITE)} value='Remove as favourite' />
    ];
  }

  function isFavouriteTeam(token) {
    return Array.isArray(favouriteTeams) && favouriteTeams.find(value => value === token);
  }

  function isSelectedTeams() {
    return selectedTeams !== null && selectedTeams.length > 0;
  }

  function onCancelBulkAction() {
    setSelectedTeams([]);
  }

  function onCancelBulkModal() {
    setBulkModal('');
  }

  function onConfirmBulkDelete() {
    if (!isSelectedTeams()) {
      return;
    }
    Api.del('/teams/bulk', { tokens: selectedTeams }, () => {
      onCancelBulkModal();
      fetch();
    });
  }

  function onCreateButtonPress() {
    searchParams.set('modal', 'teamForm');
    searchParams.set('origin', 'GUI');
    setSearchParams(searchParams);
  }

  function onModalSave() {
    closeModal();
    fetch();
  }

  function onFavouriteUpdate(token) {
    if (!Array.isArray(favouriteTeams)) {
      return;
    }
    if (favouriteTeams.find(value => value === token)) {
      Api.del('/teams/favourite', { token }, () => {
        setFavouriteTeams(prevFavouriteTeams => {
          return [ ...prevFavouriteTeams.filter(value => value !== token) ];
        });
      });
    }
    else {
      Api.post('/teams/favourite', { token }, () => {
        setFavouriteTeams(prevFavouriteTeams => {
          return [ ...prevFavouriteTeams, token ];
        });
      });
    }
  }

  function openBulkModal(action) {
    if (isSelectedTeams()) {
      setBulkModal(action);
    }
  }

  function sortedTeams() {
    return _(teams).sortBy([ ({ token }) => isFavouriteTeam(token), 'name' ]).value();
  }

  return (
    <Page
      className='TeamPage'
      title='Teams'
      alert={location.state?.redirectCause === 'teamNotFound' && (
        <Alert type='warn' onIconClick={() => {}}>Could not find the team you were looking for. Here are all the teams.</Alert>
      )}
      buttons={User.isAccountAdmin() && (
        <Button type='primary' onClick={onCreateButtonPress}>Add team</Button>
      )}
    >
      {modal === 'teamForm' && (
        <TeamForm
          editedTeam={editedTeam}
          onSave={onModalSave}
          onCancel={closeModal}
        />
      )}
      {bulkModal === TeamBulkAction.DELETE && selectedTeams !== null && (
        <BulkDeleteModal item='team' selected={selectedTeams.length} onConfirm={onConfirmBulkDelete} onCancel={onCancelBulkModal} />
      )}
      <TeamTable
        onUpdateTeams={fetch}
        teams={sortedTeams()}
        fetching={fetching}
        origin='TEAMS_PAGE'
        favourites={favouriteTeams}
        onUpdateFavourite={onFavouriteUpdate}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
      />
      {selectedTeams !== null && (
        <BulkAction selected={selectedTeams.length} actions={getBulkActions()} onCancel={onCancelBulkAction} />
      )}
    </Page>
  );
}
