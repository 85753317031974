import React, { Fragment, useEffect, useState } from 'react';
import Alert, { AlertEmphasis } from '../../../../components/Alert';
import Button from '../../../../components/Button';
import DateTimeLabel from '../../../../components/DateTimeLabel';
import NavButton from '../../../../components/NavButton';
import ProductTypeLabel from '../../../../components/ProductTypeLabel';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import TeamRedeployTimeModal from '../../TeamRedeployTimeModal';
import { getMaxEndDate, getMinStartDateOfFutureLicenses, hasFutureLicenses, hasNoSeatsReserved, shouldConfigureTeamRedeployTime } from './selectors';
import { SeatUsage } from '../../../../sharedTypes';

// eslint-disable-next-line no-unused-vars
type AlertProps = { seatUsage: SeatUsage[], teams: any, onChangeRedeployTime: () => void, onRenderedAlerts: (_: boolean) => void };

export default function Alerts({ seatUsage, teams, onChangeRedeployTime, onRenderedAlerts }: AlertProps) {

  const [ accountExtendedLicense, setAccountExtendedLicense ] = useState(null);
  const [ accountStatus, setAccountStatus ] = useState(null);
  const [ activeModal, setActiveModal ] = useState<string | null>(null);
  const [ fetchingLicenseDates, setFetchingLicenseDates ] = useState(true);
  const [ fetchingLicenseProducts, setFetchingLicenseProducts ] = useState(true);
  const [ licenseDates, setLicenseDates ] = useState([]);
  const [ licenseProducts, setLicenseProducts ] = useState([]);

  useEffect(() => {
    Api.get('/account', ({ status, extendedLicense }) => {
      setAccountStatus(status);
      setAccountExtendedLicense(extendedLicense);
      if (status === 'COMMERCIAL') {
        setFetchingLicenseProducts(false);
        setFetchingLicenseDates(false);
      }
      else {
        Api.get('/account/licensed-products', ({ list }) => {
          setLicenseProducts(list);
          setFetchingLicenseProducts(false);
        });
        Api.get('/licenses/dates', ({ list }) => {
          setLicenseDates(list);
          setFetchingLicenseDates(false);
        });
      }
    });
  }, []);

  function isFetching() {
    return fetchingLicenseDates || fetchingLicenseProducts;
  }

  function onPressConfigureButton() {
    setActiveModal('teamRedeployTime');
  }

  function onSaveModal() {
    closeModal();
    onChangeRedeployTime();
  }

  function closeModal() {
    setActiveModal(null);
  }

  function renderAlert(type: string, message: React.JSX.Element | React.ReactNode) {
    onRenderedAlerts(true);
    return (
      <Alert type={type} onIconClick={() => {}}>
        {message}
      </Alert>
    );
  }

  function renderAlerts() {
    if (isFetching()) {
      return <></>;
    }
    if (User.isTeamLead() && teams.length === 0) {
      return renderAlert('error',
        <>
          <AlertEmphasis>You have no teams.</AlertEmphasis>
          <span> Contact your licensing administrator.</span>
        </>
      );
    }
    if (User.canEdit() && accountStatus === 'COMMERCIAL' && hasNoSeatsReserved(seatUsage)) {
      return renderAlert('warn',
        <>
          <AlertEmphasis>No activated seats yet.</AlertEmphasis>
          <span> {User.isTeamLead() ? 'Invite users to activate products.' : 'Add teams and invite users to activate products.'}</span>
          {User.isAccountAdmin() && (
            <NavButton to='/teams' type='primary' className='Alert_button pull-right'>
              Open teams
            </NavButton>
          )}
        </>
      );
    }
    if (accountStatus === 'EVALUATION_ONGOING') {
      return renderAlert('info',
        <>
          <AlertEmphasis>{`You are currently evaluating ${licenseProducts.map(ProductTypeLabel.asString).join(' and ')}.`}</AlertEmphasis>
          <span> Evaluation licenses expire on <DateTimeLabel timestamp={getMaxEndDate(licenseDates)} />.</span>
        </>
      );
    }
    if (accountStatus === 'UNLICENSED' && hasFutureLicenses(licenseDates)) {
      return renderAlert('info',
        <>
          <AlertEmphasis>Licenses not started yet.</AlertEmphasis>
          <span> Your new licenses will start from <DateTimeLabel timestamp={getMinStartDateOfFutureLicenses(licenseDates)} />.</span>
        </>
      );
    }
    if (accountStatus === 'EVALUATION_NOT_STARTED') {
      return renderAlert('info',
        <>
          <AlertEmphasis>Evaluation not started yet.</AlertEmphasis>
          <span> Your evaluation will start from <DateTimeLabel timestamp={getMinStartDateOfFutureLicenses(licenseDates)} />.</span>
        </>
      );
    }
    if ((accountStatus === 'UNLICENSED' && !hasFutureLicenses(licenseDates)) || accountStatus === 'EVALUATION_ENDED') {
      if (accountExtendedLicense) {
        return renderAlert('error',
          <>
            <AlertEmphasis>Licenses expired.</AlertEmphasis>
            {!User.isAccountAdmin() && (
              <span> Your licenses have expired but a grace period is in effect. Contact account admin for more details.</span>
            )}
            {User.isAccountAdmin() && (
              <>
                <span> Your licenses have expired but a grace period is in effect.</span>
                <NavButton to='/billing' type='primary' className='Alert_button pull-right'>
                  Open details
                </NavButton>
              </>
            )}
          </>
        );
      }
      else {
        return renderAlert('error',
          <>
            <AlertEmphasis>Licenses expired.</AlertEmphasis>
            <span> Your licenses have expired. Seats can no longer be acquired. Activated products cannot be used.</span>
          </>
        );
      }
    }
    if (User.isTeamLead() && shouldConfigureTeamRedeployTime(teams)) {
      return renderAlert('info',
        <>
          <AlertEmphasis>Please configure the default redeploy time for your teams.</AlertEmphasis>
          <span> This will help show accurate metrics for your company.</span>
          <Button type='primary' className='Alert_button' onClick={onPressConfigureButton}>
            Open configuration
          </Button>
        </>
      );
    }
    onRenderedAlerts(true);
    return <></>;
  }

  return (
    <>
      {activeModal === 'teamRedeployTime' && (
        <TeamRedeployTimeModal origin='DASHBOARD_ALERT' onSave={onSaveModal} onCancel={closeModal} />
      )}
      {renderAlerts()}
    </>
  );
}
