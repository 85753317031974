import _ from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { formatAsHumanReadableList } from '../../../selectors';
import { AdminRole, AdminStatus } from '../labels';

export default function BlockModal({ username, role, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);
  const [ teams, setTeams ] = useState([]);

  useEffect(() => {
    if (role !== AdminRole.VIEW_ONLY_ADMIN) {
      Api.get(`/teams?${queryString.stringify({ team_lead_username: username })}`, ({ list }) => {
        setTeams(list);
      });
    }
  }, [ role, username ]);

  function onBlockButtonPress() {
    setSaving(true);
    Api.put('/admin-users/status', { username, status: AdminStatus.BLOCKED }, () => {
      onSuccess(username);
    });
  }

  return (
    <Modal
      title={`Block ${role === AdminRole.TEAM_LEAD ? 'team lead' : 'administrator'}`}
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='danger' disabled={saving} onClick={onBlockButtonPress}>
            Block
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <p>You are about to block <strong>{username}</strong>.</p>
      {teams.length > 0 ?
        <p>
          <strong>{username}</strong> is the team lead of {formatAsHumanReadableList(_.map(teams, 'name'))}.
          <br />
          <span>{teams.length > 1 ? 'Those teams' : 'This team'}</span>
          <span> will have no team lead after blocking and <strong>{username}</strong></span>
          <span> will not be able to sign in to Rebel Licenses any longer.</span>
        </p> :
        <p>This user will not be able to sign in to Rebel Licenses any longer.</p>
      }
      <p>Are you sure?</p>
    </Modal>
  );
}
