import { useState } from 'react';
import Modal, { ModalButton } from '../../../components/Modal';
import NewSelectField from '../../../components/NewSelectField';
import { AdminRole, AdminRoleLabels } from '../UsersPage/labels';

type BulkChangeRoleModalProps = {
  item: string,
  selected: number,
  onCancel: any,
  onConfirm: any
};

export default function BulkChangeRoleModal({ item, selected, onCancel, onConfirm }: BulkChangeRoleModalProps) {

  const [ role, setRole ] = useState<{ value: string, label: string } | null>(null);
  const [ saving, setSaving ] = useState(false);

  function getRoles() {
    return [
      // @ts-ignore
      ...Object.keys(AdminRoleLabels).filter(key => key !== AdminRole.TEAM_LEAD).map(key => ({ value: key, label: AdminRoleLabels[key] }))
    ];
  }

  function onChangeRole(newValue: { value: string }) {
    // @ts-ignore
    setRole({ value: newValue.value, label: AdminRoleLabels[newValue.value] });
  }

  function onClickChange() {
    if (role === null) {
      return;
    }
    setSaving(true);
    onConfirm(role.value);
  }

  return (
    <Modal
      title='Bulk change role'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onClickChange}>
            Change
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <NewSelectField
        label='Role'
        name='role'
        value={role}
        onChange={onChangeRole}
        idKey='BulkChangeRoleModal.role'
        disabled={saving}
        options={getRoles()}
        defaultValue={null}
      />
      <div>
        <p><br /></p>
        <p>You are about to change roles of <b>{selected}</b> {item}{selected > 1 ? 's' : ''}.</p>
        <p>Are you sure?</p>
      </div>
    </Modal>
  );
}
