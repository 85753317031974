import emailValidator from 'email-validator';
import _ from 'lodash';
import queryString from 'query-string';
import { useState } from 'react';
import { InputField } from '../../../../components/InputField';
import NewSelectField from '../../../../components/NewSelectField';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';
import { formatAsHumanReadableList } from '../../../selectors';
import { AdminRole, AdminRoleLabels } from '../labels';

export default function AdminUserForm({ username, role, onSuccess, onCancel }) {

  const [ currentUsername, setCurrentUsername ] = useState({ value: username || '', errorMessage: null });
  const [ currentRole, setCurrentRole ] = useState({ value: role || AdminRole.ACCOUNT_ADMIN, label: AdminRoleLabels[role || AdminRole.ACCOUNT_ADMIN] });
  const [ activeModal, setActiveModal ] = useState('form');
  const [ editMode ] = useState(username !== undefined);
  const [ saving, setSaving ] = useState(false);
  const [ teams, setTeams ] = useState([]);

  function getRoles() {
    return [
      ...Object.keys(AdminRoleLabels).filter(key => role === key || key !== AdminRole.TEAM_LEAD).map(key => ({ value: key, label: AdminRoleLabels[key] }))
    ];
  }

  function onFormSubmit(e) {
    e.preventDefault();
    if (editMode) {
      saveEdit();
    }
    else {
      saveCreate();
    }
  }

  function saveEdit() {
    if (currentRole.value === AdminRole.VIEW_ONLY_ADMIN) {
      setSaving(true);
      Api.get(`/teams?${queryString.stringify({ team_lead_username: username })}`, ({ list }) => {
        if (list.length > 0) {
          setTeams(list);
          setSaving(false);
          setActiveModal('warning');
        }
        else {
          saveEditAndCloseModal();
        }
      });
    }
    else {
      saveEditAndCloseModal();
    }
  }

  function saveCreate() {
    if (!emailValidator.validate(currentUsername.value)) {
      setCurrentUsername(prevCurrentUsername => {
        return { ...prevCurrentUsername, errorMessage: 'Please enter a valid email address' };
      });
      return;
    }
    setSaving(true);
    Api.post('/admin-users', { username: currentUsername.value, role: currentRole.value }, ({ error }) => {
      if (error === 'USERNAME_EXISTS') {
        setSaving(false);
        setCurrentUsername(prevCurrentUsername => {
          return { ...prevCurrentUsername, errorMessage: 'Email address already in use, try another one' };
        });
      }
      else {
        onSuccess(currentUsername.value);
      }
    });
  }

  function saveEditAndCloseModal() {
    setSaving(true);
    Api.put('/admin-users/role', { username, role: currentRole.value }, () => {
      onSuccess(username);
    });
  }

  function onRoleChange(newValue) {
    setCurrentRole({ value: newValue.value, label: AdminRoleLabels[newValue.value] });
  }

  function onUsernameChange({ currentTarget: { value } }) {
    setCurrentUsername({ value, errorMessage: null });
  }

  return (
    <>
      {activeModal === 'form' && (
        <Modal
          title={editMode ? 'Change administrator role' : 'Add administrator'}
          size='m'
          buttons={
            <div>
              <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
                Cancel
              </ModalButton>
              <ModalButton type='primary' disabled={saving || currentRole.value === role} onClick={onFormSubmit}>
                {editMode ? 'Save' : 'Send invite'}
              </ModalButton>
            </div>}
          onClose={onCancel}
        >
          <InputField
            type='text'
            name='username'
            label='Email'
            value={currentUsername.value}
            errorMessage={currentUsername.errorMessage}
            onChange={onUsernameChange}
            disabled={editMode}
            autoFocus
          />
          <NewSelectField
            label='Role'
            name='exportedTo'
            value={currentRole}
            onChange={onRoleChange}
            id='AdminUserForm.role'
            disabled={saving}
            options={getRoles()}
            defaultValue={getRoles()[0]}
          />
        </Modal>
      )}
      {activeModal === 'warning' && (
        <Modal
          title='Edit administrator role'
          size='m'
          buttons={
            <div>
              <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
                Cancel
              </ModalButton>
              <ModalButton type='primary' disabled={saving} onClick={saveEditAndCloseModal}>
                Save
              </ModalButton>
            </div>
          }
          onClose={onCancel}
        >
          <p>
            <span><strong>{username}</strong> will be unassigned from the role of team lead of {formatAsHumanReadableList(_.map(teams, 'name'))} </span>
            <span>after changing role to <strong>{AdminRoleLabels[currentRole.value]}</strong>.</span>
          </p>
        </Modal>
      )}
    </>
  );
}
