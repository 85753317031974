import PropTypes from 'prop-types';
import React, { MouseEventHandler, ReactNode, useState } from 'react';
import classNames from 'classnames';
import Trigger from 'rc-trigger';
import Button from '../Button';
import NavButton from '../NavButton';
import queryString from 'query-string';
import './styles.css';

DropdownMenuButton.propTypes = {
  children: PropTypes.node.isRequired,
  routeTo: PropTypes.shape({
    path: PropTypes.string.isRequired,
    parameters: PropTypes.object
  }),
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

interface DropdownMenuButtonProps {
  children: ReactNode,
  routeTo?: { path: string, parameters?: Object, state?: Object },
  onPress?: MouseEventHandler<HTMLElement>,
  disabled?: boolean,
  className?: string
}

export function DropdownMenuButton({ children, routeTo, onPress, disabled = false, className, ...otherProps }: DropdownMenuButtonProps) {
  const classNameForComponent = classNames('DropdownMenu_item', className, { 'disabled': disabled });

  if (disabled) {
    return <Button className={classNameForComponent} disabled {...otherProps}>{children}</Button>;
  }

  if (routeTo) {
    return (
      <NavButton
        to={`${routeTo.path}${routeTo.parameters ? `?${queryString.stringify(routeTo.parameters)}` : ''}`}
        options={{ state: routeTo.state }}
        className={classNameForComponent}
        otherProps={otherProps}
      >
        {children}
      </NavButton>
    );
  }

  return <Button onClick={onPress} className={classNameForComponent} {...otherProps}>{children}</Button>;
}

DropdownMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  topSeparator: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string
};

interface DropdownMenuItemProps {
  children: ReactNode,
  topSeparator?: boolean,
  className?: string,
  href?: string,
  target?: string,
  rel?: string
}

export function DropdownMenuItem({ children, topSeparator = false, className, href, target, rel }: DropdownMenuItemProps) {
  const classNameForComponent = classNames('DropdownMenu_item', className, topSeparator ? 'topSeparator' : '');

  if (href) {
    return (<a className={classNameForComponent} href={href} target={target} rel={rel}>{children}</a>);
  }

  return (<div className={classNameForComponent}>{children}</div>);
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  popupOffset: PropTypes.arrayOf(PropTypes.number).isRequired,
  popupPosition: PropTypes.string
};

interface DropdownProps {
  children: ReactNode,
  className?: string,
  icon: ReactNode,
  popupOffset: number[],
  popupPosition?: string
}

export function Dropdown({ children, className, icon, popupOffset, popupPosition }: DropdownProps) {
  const [ visible, setVisible ] = useState(false);
  const classNameForComponent = classNames('Dropdown', className, { pressed: visible });

  return (
    <Trigger
      action='click'
      destroyPopupOnHide
      popupAlign={{ points: [ 'tr', 'br' ], offset: popupOffset }}
      onPopupVisibleChange={setVisible}
      popupVisible={visible}
      popupStyle={{
        opacity: 1, // Workaround for tests.
        // @ts-ignore
        position: popupPosition
      }}
      popup={
        <div className='Dropdown_menu' onClick={() => setVisible(false)}>
          {children}
        </div>
      }
    >
      <Button className={classNameForComponent}>
        {icon}
      </Button>
    </Trigger>
  );
}
