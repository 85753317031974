import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import InfoTooltip from '../../../../components/InfoTooltip';
import { InputField } from '../../../../components/InputField';
import ProductTypeLabel from '../../../../components/ProductTypeLabel';
import Table, { TableCell, TableRow } from '../../../../components/Table';
import ValidationTooltip from '../../../../components/ValidationTooltip';
import { sortByProductType } from '../../../../enums/ProductType';
import Api from '../../../../utils/Api';
import User from '../../../../utils/User';
import { getRedeployTimeValidationMessage } from '../../selectors';
import {
  getAvailableSeatCountForLicenseType,
  getProductEnabledValidationMessage,
  getSeatLimitValidationMessage,
  getSeatsLeftForProduct,
  hasRebelSuiteSeatsForProduct
} from './selectors';
import './styles.css';

export default function ProductsPanel({ products, onProductsChange, onRedeployTimeChange, redeployTimeSeconds }) {

  const inputElements = {};
  const [ seatUsage, setSeatUsage ] = useState([]);

  useEffect(() => {
    if (!User.isTeamLead()) {
      Api.get('/statistics', ({ seatUsage }) => {
        setSeatUsage(seatUsage);
      });
    }
  }, []);

  function onFieldChange({ currentTarget }) {
    const prods = _.cloneDeep(products);
    const value = currentTarget.type === 'checkbox' ? currentTarget.checked : currentTarget.value;
    const targetProductType = currentTarget.getAttribute('data-product-type');

    _(prods).find(({ productType }) => productType === targetProductType)[currentTarget.name] = value;

    if (currentTarget.name === 'enabled') {
      prods.forEach(product => product.lastTouched = product.productType === targetProductType);
    }

    onProductsChange(prods);
  }

  function onInputRef(element) {
    if (element) {
      inputElements[`${element.getAttribute('data-product-type')}.${element.name}`] = element;
    }
  }

  function getSeatsLeftTooltipText() {
    const seats = getAvailableSeatCountForLicenseType(seatUsage, 'REBEL_SUITE');
    return `Includes ${seats} RebelSuite seat${seats === 1 ? '' : 's'}, that allow${seats === 1 ? 's' : ''} to activate both JRebel and XRebel by a single person.`;
  }

  function productsToShow() {
    return products.filter(({ productType }) => (!User.isTeamLead() && seatUsage.length > 0) || (User.isTeamLead() && productType === 'JREBEL'));
  }

  return productsToShow().length > 0 && (
    <Table
      className='TeamFormProductsPanel'
      header={
        <TableRow>
          <th>Product</th>
          {!User.isTeamLead() && (
            <Fragment>
              <th>Enable</th>
              <th>
                <span>Seat limit</span>
                <InfoTooltip size='l' text='Limits how many seats can be used by the team. Leave empty for unlimited usage.' />
              </th>
              <th>Seats left</th>
            </Fragment>
          )}
          <th>
            <span>Default redeploy time</span>
            <InfoTooltip size='l' text='Default redeploy time to be used for analytics and ROI calculations for developers who do not configure a custom redeploy time.' />
          </th>
        </TableRow>
      }
      body={sortByProductType(productsToShow()).map(product => {
        const { productType, limit, enabled } = product;

        return (
          <TableRow key={productType} className='TeamFormProductsPanel_product'>
            <TableCell className='TeamFormProductsPanel_product_name'>
              <ProductTypeLabel id={productType} />
            </TableCell>

            {!User.isTeamLead() && (
              <Fragment>
                <TableCell className='TeamFormProductsPanel_product_enabled'>
                  <label>
                    <input
                      type='checkbox'
                      name='enabled'
                      checked={enabled}
                      data-product-type={productType}
                      onChange={onFieldChange}
                      ref={onInputRef}
                    />
                    <ValidationTooltip target={inputElements[`${productType}.enabled`]} text={getProductEnabledValidationMessage(products, product)} />
                  </label>
                </TableCell>
                <TableCell className='TeamFormProductsPanel_product_seat-limit'>
                  <InputField
                    type='text'
                    name='limit'
                    value={limit || ''}
                    disabled={!enabled}
                    data-product-type={productType}
                    idKey={productType}
                    onChange={onFieldChange}
                    hasError={getSeatLimitValidationMessage(products, product)}
                    ref={onInputRef}
                  />
                  <ValidationTooltip target={inputElements[`${productType}.limit`]} text={getSeatLimitValidationMessage(products, product)} />
                </TableCell>
                <TableCell className='TeamFormProductsPanel_product_seats-left'>
                  {getSeatsLeftForProduct(seatUsage, productType)}
                  {hasRebelSuiteSeatsForProduct(seatUsage, productType) && <InfoTooltip size='l' text={getSeatsLeftTooltipText()} />}
                </TableCell>
              </Fragment>
            )}

            <TableCell className='TeamFormProductsPanel_product_redeploy_time'>
              {productType === 'JREBEL' && (
                <Fragment>
                  <InputField
                    type='text'
                    name='redeployTime'
                    value={redeployTimeSeconds || ''}
                    disabled={!enabled}
                    data-product-type={productType}
                    idKey={productType}
                    onChange={onRedeployTimeChange}
                    hasError={enabled && getRedeployTimeValidationMessage(redeployTimeSeconds)}
                    ref={onInputRef}
                  />
                  <span> seconds</span>
                  <ValidationTooltip
                    target={inputElements[`${productType}.redeployTime`]}
                    text={enabled ? getRedeployTimeValidationMessage(redeployTimeSeconds) : null}
                  />
                </Fragment>
              )}
            </TableCell>

          </TableRow>
        );
      })}
    />
  );
}
