import { useState } from 'react';
import Modal, { ModalButton } from '../../../../components/Modal';
import Api from '../../../../utils/Api';

export default function ResendModal({ username, onSuccess, onCancel }) {

  const [ saving, setSaving ] = useState(false);

  function onResendButtonPress() {
    setSaving(true);
    Api.post('/admin-users/invites', { username }, () => {
      onSuccess(username);
    });
  }

  return (
    <Modal
      title='Resend invite'
      size='m'
      buttons={
        <div>
          <ModalButton type='secondary' disabled={saving} onClick={onCancel}>
            Cancel
          </ModalButton>
          <ModalButton type='primary' disabled={saving} onClick={onResendButtonPress}>
            Resend
          </ModalButton>
        </div>
      }
      onClose={onCancel}
    >
      <p>You are about to resend the invite to <strong>{username}</strong>.</p>
      <p>Are you sure?</p>
    </Modal>
  );
}
