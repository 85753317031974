import React, { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import User from '../../utils/User';
import UnsubscribePage from '../UnsubscribePage';
import AnalyticsPage from './AnalyticsPage';
import Dashboard from './Dashboard';
import BillingPage from './BillingPage';
import NotificationPage from './NotificationPage';
import TeamPage from './TeamPage';
import SettingsPage from './SettingsPage';
import UsersPage from './UsersPage';
import SeatUsersTeamRedirect from './UsersPage/support/SeatUsersTeamRedirect';

const AdminPages = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getRedirectState = useCallback((redirectCause: string) => {
    return { redirectCause, lastRoute: location.pathname, lastSearch: location.search };
  }, [ location.pathname, location.search ]);

  useEffect(() => {
    const sessionEndedListenerToken = User.addSessionEndedListener(() => {
      navigate('/login', { state: getRedirectState('sessionEnded') });
    });

    return () => sessionEndedListenerToken.remove();
  }, [ getRedirectState, navigate ]);

  if (!User.isAuthenticated()) {
    return <Navigate replace key='2' to='/login' state={getRedirectState('unauthenticated')} />;
  }

  return (
    <Routes>
      <Route path='/unsubscribe' element={<UnsubscribePage />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/users/*' element={<UsersPage />}>
        <Route path='' element={<></>} />
        {!User.isTeamLead() && (<Route path='admins' element={<></>} />)}
      </Route>
      {!User.isTeamLead() && [
        <Route key='1' path='/billing/' element={<BillingPage />}>
          <Route path='' element={<></>} />
          <Route path=':rk' element={<></>} />
        </Route>,
        <Route key='2' path='/teams' element={<TeamPage />} />,
        <Route key='3' path='/analytics/*' element={<AnalyticsPage />} />
      ]}
      {User.isAccountAdmin() && <Route path='/notifications/*' element={<NotificationPage />}>
        <Route path='' element={<></>} />
        <Route path=':rk' element={<></>} />
      </Route>}
      <Route key='5' path='/user-settings/*' element={<SettingsPage />} />
      {User.shouldBeOnboarded() && <Route key='5' element={<Navigate replace key='5' to='/dashboard' />} />}
      <Route path='/seat-users/team' element={<SeatUsersTeamRedirect />} />
      <Route path='*' element={<Navigate replace to='/dashboard' />} />
    </Routes>
  );
};

export default AdminPages;
