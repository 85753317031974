import _ from 'lodash';
import moment from 'moment';
import { hasJRebelEnabled } from '../../selectors';
import { SeatUsage, StartAndEnd, TeamWithProducts } from '../../../../sharedTypes';

export function shouldConfigureTeamRedeployTime(teams: TeamWithProducts[]): boolean {
  return teams.filter(team => hasJRebelEnabled(team.products)).some(({ redeployTimeSeconds }) => !redeployTimeSeconds);
}

export function hasNoSeatsReserved(seatUsage: SeatUsage[]): boolean {
  return seatUsage.every(({ reserved }) => reserved === 0);
}

export function getMaxEndDate(licenses: StartAndEnd[]): string | undefined {
  return _(licenses).map(({ endDate }) => endDate).max();
}

export function getMinStartDateOfFutureLicenses(licenses: StartAndEnd[]): string | undefined {
  return _(licenses)
    .filter(({ startDate }) => moment.utc().isBefore(startDate))
    .map(({ startDate }) => startDate)
    .min();
}

export function hasFutureLicenses(licenses: StartAndEnd[]): boolean {
  return getMinStartDateOfFutureLicenses(licenses) !== undefined;
}
