import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ComponentPropsWithoutRef } from 'react';
import './styles.css';

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  submit: PropTypes.bool,
  type: PropTypes.string
};

export interface ButtonProps extends Omit<ComponentPropsWithoutRef<'button'>, 'type'> {
  submit?: boolean,
  disabled?: boolean,
  // We override the meaning of type.
  type?: string
}

export default function Button({ children, disabled = false, submit = false, type, className, ...otherProps }: ButtonProps) {
  return (

    <button
      type={submit ? 'submit' : 'button'}
      className={classNames('Button', className, type)} {...otherProps}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
