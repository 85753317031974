import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import Alert from '../../../components/Alert';
import Page from '../../../components/Page';
import RouterComponent from '../../../components/RouterComponent';
import TabBar from '../../../components/TabBar';
import Api from '../../../utils/Api';
import User from '../../../utils/User';
import { withRouter } from '../../../utils/withRouter';
import ResetModal from './UserSettingsTab/ResetModal';
import OrganizationSettingsTab from './OrganizationSettingsTab';
import UserSettingsTab from './UserSettingsTab';
import './styles.css';

export default withRouter(class SettingsPage extends RouterComponent {

  state = {
    modal: '',
    alert: ''
  };

  componentDidMount() { // TODO move to User Tab?
    if (User.canEdit()) {
      Api.post('/admin-users/events/open/email-notification-settings', { origin: this.getRouteParameter('origin') || 'GUI' });
    }
  }

  setModal = modal => {
    this.setState({ modal });
  };

  setAlert = alert => {
    this.setState({ alert });
  };

  closeModal = () => {
    this.setState({ modal: '' });
  };

  onResetSuccess = username => {
    this.closeModal();
    this.setAlert(`Password reset email sent successfully to ${username}.`);
  };

  render() {
    return (
      <Page
        className='SettingsPage'
        title='Settings' // TODO better system for alerts? to emphasize correct parts as per design
        alert={this.state.alert && <Alert type='ok'>{this.state.alert}</Alert>}
      >
        {!User.isTeamLead() && this.state.modal === 'reset' &&
          <ResetModal username={User.getUsername()} onSuccess={this.onResetSuccess} onCancel={this.closeModal} />
        }
        <TabBar>
          <NavLink end to=''>
            User Settings
          </NavLink>
          {!User.isTeamLead() && <NavLink to='organization-settings'>
            Organization Settings
          </NavLink>}
        </TabBar>

        <Routes>
          {!User.isTeamLead() && <Route
            path='organization-settings'
            element={
              <OrganizationSettingsTab setPageAlert={this.setAlert} />
            }
          />}
          <Route
            path='*' element={
              <UserSettingsTab setPageAlert={this.setAlert} setPageModal={this.setModal} />
            }
          />
        </Routes>
      </Page>
    );
  }
});
