import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminsTab from './AdminsTab';
import UsersTab from './UsersTab';
import './styles.css';

export default function UsersPage() {

  return (
    <Routes>
      <Route path='' element={<UsersTab />} />
      <Route path='admins' element={<AdminsTab />} />
    </Routes>
  );
}
