import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, createHashRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import Container from '../components/Container';
import AdminPages from './AdminPages';
import { ErrorBoundary } from './errorBoundary';
import Footer from './Footer';
import ForgotPasswordPage from './ForgotPasswordPage';
import Header from './Header';
import LoginPage from './LoginPage';
import PasswordResetPage from './PasswordResetPage';
import ProductInstallationConfirmationPage from './ProductInstallationConfirmationPage';
import './styles.css';
import SupportLoginPage from './SupportLoginPage';
import SsoLoginPage from './SsoLoginPage';
import TeamTokenPage from './TeamTokenPage';
import TwoStepAuthenticationPage from './TwoStepAuthenticationPage';
import SsoRedirectPage from './SsoRedirectPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TrialEmailConfirmationPage from './TrialEmailConfirmationPage';

const queryClient = new QueryClient();

const AppLayout = ({ staticPage }: { staticPage: boolean }) => {
  const hiddenMenuPages = [
    '/reset/:token',
    '/support-login/:token',
    '/login/sso-redirect',
    '/login/sso',
    '/forgot-password',
    '/reset/:token',
    '/activation/:token',
    '/confirmation/:token',
    '/trial-confirmation/:token'
  ];

  return (
    <HelmetProvider>
      <div className='App'>
        <Helmet defaultTitle='Rebel Licenses' titleTemplate='Rebel Licenses | %s' />

        <Header staticPage={staticPage} hiddenMenuPages={hiddenMenuPages} />
        <Container className='App_container'>
          <Outlet />
        </Container>
        <div className='App_footer-filler' />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

const RootComponent = () => {
  const getToken = () => window.location.pathname.replace('/', '');
  const isTeamTokenInUrl = () => (/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i).test(getToken());

  const isSsoUrl = () => (/^sso\/.*$/i).test(getToken());

  const browserRouter = createBrowserRouter(createRoutesFromElements((
    <Route element={<AppLayout staticPage={true} />}>
      <Route path='*' element={<TeamTokenPage token={getToken()} />} />
    </Route>
  )));

  const hashRouter = createHashRouter(createRoutesFromElements((
    <Route element={<AppLayout staticPage={false} />}>
      <Route path='/login' element={<LoginPage />} />
      <Route path='/login/:token' element={<TwoStepAuthenticationPage />} />
      <Route path='/login/sso' element={<SsoLoginPage />} />
      <Route path='/login/sso-redirect' element={<SsoRedirectPage isLogin={true} />} />
      <Route path='/support-login/:token' element={<SupportLoginPage />} />
      <Route path='/forgot-password' element={<ForgotPasswordPage />} />
      <Route path='/reset/:token' element={<PasswordResetPage />} />
      <Route path='/activation/:token' element={<PasswordResetPage />} />
      <Route path='/confirmation/:token' element={<ProductInstallationConfirmationPage />} />
      <Route path='/trial-confirmation/:token' element={<TrialEmailConfirmationPage />} />
      <Route path='/sso/verifed' element={<SsoRedirectPage isVerifyCallback={true} />} />
      <Route path='/sso/*' element={<SsoRedirectPage />} />
      <Route path='*' element={<AdminPages />} />
    </Route>
  )));

  const router = isTeamTokenInUrl() || isSsoUrl() ? browserRouter : hashRouter;
  return (
    <RouterProvider router={router} />
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <RootComponent />
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
export default App;
