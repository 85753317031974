import { ReactNode } from 'react';
import './styles.css';

type BulkActionsProps = {
  selected: number,
  actions: ReactNode[],
  onCancel: any
};

export default function BulkAction({ selected, actions, onCancel }: BulkActionsProps) {

  if (selected < 1 || actions.length < 1) {
    return <></>;
  }

  return (
    <div className='BulkAction'>
      <div className='BulkAction_selected'>
        <b>{selected} selected</b>
      </div>
      <div className='BulkAction_items'>
        {actions.map((action, i) => (
          <div key={i} className='BulkAction_item'>
            {action}
          </div>
        ))}
      </div>
      <div className='BulkAction_cancel'>
        <i className='BulkAction_cancel-icon icon icon-close' onClick={onCancel} />
      </div>
    </div>
  );
}
